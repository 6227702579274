import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { FiInfo, FiMenu } from "react-icons/fi";
import { missingDetails } from "./helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const MenuContainer = ({ children, homeRoute, subMenu }) => {
  const { pathname } = useLocation();

  const match = (path) => {
    return matchPath(pathname, { path, exact: true });
  };

  return (
    <>
      {!match(ROUTES.HOME + ROUTES.ACCOUNT) && (
        <button
          className={
            "menu-container-collapse d-flex d-lg-none flex-row justify-content-center align-items-center"
          }
          data-toggle="collapse"
          data-target="#menuContainer"
        >
          <FiMenu />
          Menu
        </button>
      )}
      <div
        className="menu-container d-lg-flex flex-column collapse"
        id={"menuContainer"}
      >
        <div className={"menu-list"}>{children}</div>
        {subMenu && <div className={"sub-menu "}>{subMenu()}</div>}
      </div>
    </>
  );
};
