import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  updateAccountForm,
  saveAccountInfo,
  getAccountInfo,
  logOutUser,
  logoutSession,
  testSessionAuth,
} from "../../actions";
import { Dropdown, Spinner } from "react-bootstrap";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../services/firebase";
import { Link } from "react-router-dom";
import {
  clearApplicationContext,
  setApplicationContext,
  setContextReturnURL,
} from "../../actions/ApplicationContextActions";
import {
  FIREBASE_AUTH,
  SAVE_ACCOUNT_INFO_FAILURE,
  SESSION_AUTH,
} from "../../../constants/types";
import { FiAlertCircle } from "react-icons/fi";
import COUNTRYCODES from "../../../assets/country-codes.json";

const AccountInfoForm = (props) => {
  const [nameError, setNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessWebsiteError, setBusinessWebsiteError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [monthlyError, setMonthlyError] = useState("");
  const [errors, setErrors] = useState({});

  const [countryCode, setCountryCode] = useState({
    name: "United States",
    dial_code: "+1",
    code: "US",
  });

  useEffect(() => {
    props
      .testSessionAuth()
      .then((res) => {
        const {
          full_name,
          phone_number,
          business_name,
          business_website,
          monthly_avg_shipment,
        } = props.info;
        props.updateAccountForm({
          full_name,
          phone_number,
          business_name,
          business_website,
          monthly_avg_shipment,
        });
      })
      .catch((err) => {
        console.log(err);
        history.replace(ROUTES.SIGN_IN);
      });
  }, []);

  useEffect(() => {
    if (
      props.errors instanceof Object &&
      Object.keys(props.errors).length > 0
    ) {
      const errorData = { ...errors };
      Object.keys(props.errors).forEach((field) => {
        if (Object.keys(props.form).includes(field)) {
          errorData[field] = props.errors[field];
        } else if (field === "first_name" || field === "last_name") {
          errorData.full_name = props.errors[field];
        }
      });
      setErrors(errorData);
    } else {
      setErrors({});
    }
  }, [props.errors]);

  const validateInputs = (form) => {
    let valid = true;
    const errorData = { ...errors };

    const full_name = form.full_name ? form.full_name.trim() : "";

    if (full_name === "") {
      errorData.full_name = "Full Name cannot be empty";
      valid = false;
    }

    if (full_name.indexOf(" ") === -1) {
      errorData.full_name = "Please use your full name";
      valid = false;
    }

    if (!form.phone_number || form.phone_number.toString().trim() === "") {
      errorData.phone_number = "Phone Number cannot be empty";
      valid = false;
    }

    Object.values(COUNTRYCODES).forEach((item) => {
      if (
        item.dial_code === props.form.phone_number ||
        item.dial_code === `+${props.form.phone_number}`
      ) {
        errorData.phone_number = "Phone Number cannot be empty";
        valid = false;
      }
    });

    if (!form.business_name || form.business_name.toString().trim() === "") {
      errorData.business_name = "Business Name cannot be empty";
      valid = false;
    }
    if (
      !form.business_website ||
      form.business_website.toString().trim() === ""
    ) {
      errorData.business_website = "Business Website cannot be empty";
      valid = false;
    }
    if (
      !form.monthly_avg_shipment ||
      form.monthly_avg_shipment.toString().trim() === ""
    ) {
      errorData.monthly_avg_shipment =
        "Monthly Average Shipments cannot be empty";
      valid = false;
    }

    setErrors(errorData);
    return valid;
  };

  const handleSubmit = () => {
    const { form } = props;

    if (validateInputs(form)) {
      props.saveAccountInfo(form, () => {
        if (props.context.actionType !== "edit") {
          history.replace(ROUTES.BANK_ACCOUNT_DETAILS);
          return;
        }

        if (props.returnURL && props.returnURL.trim() !== "") {
          history.replace(props.returnURL);
          props.clearApplicationContext();
          return;
        }
        history.replace(ROUTES.HOME);
      });
    }
  };

  const renderMonthlyAvg = () => {
    const AVERGAGE_SHIPMENTS = [
      "1-50",
      "50-200",
      "200-500",
      "500-1000",
      "1000+",
    ];

    const list = AVERGAGE_SHIPMENTS.map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => {
            setErrors({});
            props.updateAccountForm({ monthly_avg_shipment: item });
          }}
        >
          {AVERGAGE_SHIPMENTS[idx]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown className="">
        <Dropdown.Toggle className="col-12 p-0 d-flex justify-content-between align-items-center px-3">
          {props.form.monthly_avg_shipment ? (
            props.form.monthly_avg_shipment
          ) : (
            <p className={"placeholder"}>Select an Option</p>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleEnter = (event) => {
    const form = event.target.parentNode.parentNode.childNodes;
    const index = [...form].indexOf(event.target.parentNode);
    if (
      event.key.toLowerCase() === "enter" ||
      event.key.toLowerCase() === "arrowdown"
    ) {
      if (index !== 2) form[index + 1].focus();
      else {
        form[index + 1].querySelector("input").focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "arrowup") {
      if (index !== 4) form[index - 1].focus();
      else {
        form[index - 1].querySelector("input").focus();
      }
      event.preventDefault();
    }
  };

  return (
    <>
      <h3>Profile Details</h3>

      {props.errors && !(props.errors instanceof Object) && (
        <div className={"error-block"}>
          <FiAlertCircle />
          <p>{props.errors}</p>
        </div>
      )}
      <div
        className={
          "form col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <label
          className={`col-12 p-0 d-flex flex-column ${errors.full_name ? "error" : ""}`}
        >
          Full Name
          <input
            placeholder={"Enter your full name"}
            value={props.form.full_name}
            onChange={(e) => {
              setErrors({});
              props.updateAccountForm({ full_name: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          <p className={"error"}>{errors.full_name}</p>
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${errors.business_name ? "error" : ""}`}
        >
          Business Name
          <input
            placeholder={"Enter your business name"}
            value={props.form.business_name}
            onChange={(e) => {
              setErrors({});
              props.updateAccountForm({ business_name: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          <p className={"error"}>{errors.business_name}</p>
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${errors.business_website ? "error" : ""}`}
        >
          Business Website
          <input
            placeholder={"Enter your business website"}
            value={props.form.business_website}
            onChange={(e) => {
              setErrors({});
              props.updateAccountForm({ business_website: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          <p className={"error"}>{errors.business_website}</p>
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${errors.phone_number ? "error" : ""}`}
        >
          Phone Number
          <PhoneInput
            country={"us"}
            className={""}
            value={props.form.phone_number}
            onChange={(phone) => {
              setErrors({});
              props.updateAccountForm({ phone_number: phone });
            }}
            placeholder={"Enter your phone number"}
            enableTerritories
          />
          <p className={"error"}>{errors.phone_number}</p>
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${errors.monthly_avg_shipment ? "error" : ""}`}
        >
          Monthly Average Shipments
          {renderMonthlyAvg()}
          <p className={"error"}>{errors.monthly_avg_shipment}</p>
        </label>
      </div>
      <div
        className={
          "buttons col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <button onClick={handleSubmit} disabled={props.loading}>
          {props.loading ? (
            <Spinner animation={"border"} variant={"light"} />
          ) : (
            "Continue"
          )}
        </button>
        {props.context.actionType === "edit" ? (
          <a
            className={"logoutButton"}
            onClick={() => {
              history.replace(props.returnURL);
              props.clearApplicationContext();
            }}
          >
            Cancel
          </a>
        ) : (
          <a
            onClick={() => {
              if (props.context.auth === FIREBASE_AUTH) props.logOutUser();
              else props.logoutSession();
            }}
            className={"logoutButton"}
          >
            Log out
          </a>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ userDetails, accountForm, applicationContext }) => ({
  form: accountForm,
  loading: accountForm.loading,
  errors: accountForm.errors,
  info: userDetails.info,
  returnURL: applicationContext.context.returnURL,
  context: applicationContext.context,
});

export default connect(mapStateToProps, {
  updateAccountForm,
  saveAccountInfo,
  getAccountInfo,
  logOutUser,
  setContextReturnURL,
  setApplicationContext,
  clearApplicationContext,
  logoutSession,
  testSessionAuth,
})(AccountInfoForm);
