import React from "react";

const LandingPage = () => (
  <div className="App">
    {/*// <!-- Masthead -->*/}
    <header className="masthead text-left">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-12 col-md-7 mx-auto">
            <h2>
              No subscription. <br />
              Cheapest rates. <br />
              All from your phone.
            </h2>
            <p>
              ShipShap gives you the cheapest shipping rates & best options. All
              accessible on mobile, wherever you're working on your next package
              from.
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.shipshap">
              <img src="/img/google-store-icon.png" class="img-fluid" />
            </a>
            <a href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1">
              <img src="/img/apple-store-icon.png" class="img-fluid" />
            </a>
          </div>
          <div className="col-lg-7 col-xl-pull-6 col-lg-pull-5 col-sm-pull-4 col-sm-12 col-md-5">
            <img src="/img/blobbly-background.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </header>
  </div>
);
export default LandingPage;
