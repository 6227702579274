import React from "react";
import { connect } from "react-redux";
import {
  CARD_LIST,
  CARD_PAY,
  PAYSTACK,
  STRIPE,
  SAFARICOM,
} from "../../../constants/types";
import { Anchor, Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import StripeCardListItem from "./StripeCardListItem";
import PaystackCardListItem from "./PaystackCardListItem";
import {
  getUserDetails,
  removeUserCard,
  selectCard,
  setDefaultCard,
} from "../../actions";
import { FiTrash2 } from "react-icons/fi";

const CardListItem = (props) => {
  const selectCard = (card) => {
    props.selectCard(card, props.type);
  };
  const deleteCard = (card) => {
    props.selectCard(card, props.type, () => {
      props.showOverlay(true);
    });
  };

  const cardProps = {
    selectCard: (args) => {
      selectCard(args);
    },
    deleteCard: (args) => {
      deleteCard(args);
    },
    type: props.type || CARD_LIST,
    setDefaultCard: () => {
      props.setDefaultCard(props.card, () => {
        props.getUserDetails();
      });
    },
    card: props.card,
    select: props.select || false,
    cardActions: () => {
      return props.select ? null : props.type !== CARD_PAY ? (
        <div className="settings-actions col-4 p-0 d-flex flex-row justify-content-end align-items-center">
          {props.card.is_default ? (
            <button className="" disabled>
              Default Payment Method
            </button>
          ) : (
            <button onClick={() => props.setDefaultCard(props.card)}>
              {props.loading && props.card.id === props.selectedCard.id ? (
                <Spinner animation={"border"} />
              ) : (
                "Set Default"
              )}
            </button>
          )}
          <a onClick={() => props.deleteCard(props.card)}>
            <FiTrash2 size={35} />
          </a>
        </div>
      ) : props.loading ? (
        <Spinner animation={"border"} />
      ) : (
        <div className="col-5 p-0 d-flex flex-column justify-content-center align-content-end">
          <a onClick={() => props.onCreate()}>Create Payment Method</a>
          <Dropdown.Toggle
            as={Anchor}
            className="d-flex flex-row align-items-center p-3"
          >
            <p className="mb-0" onClick={() => props.onToggle()}>
              Select Payment Method
            </p>
          </Dropdown.Toggle>
        </div>
      );
    },
  };

  if (props.card) {
    switch (props.card.provider) {
      case STRIPE:
        return <StripeCardListItem {...cardProps} />;
      case PAYSTACK:
      case SAFARICOM:
        return <PaystackCardListItem {...cardProps} />;
      default:
        return (
          <div className={"card-list-item card-section"}>
            <p className={"text-danger"}>Payment provider not known</p>
          </div>
        );
    }
  }

  if (props.type === CARD_PAY) {
    return (
      <div
        className={`card-list-item card-section ${props.select ? "selectable" : ""}`}
        onClick={props.select ? () => selectCard(props.card) : null}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div></div>
          {props.loading ? (
            <Spinner animation={"border"} />
          ) : (
            <div className="d-flex flex-row justify-content-around">
              <a onClick={() => props.onCreate()}>Create Payment Method</a>
              <Dropdown.Toggle
                as={Anchor}
                className="d-flex flex-row align-items-center p-3"
              >
                <p className="mb-0" onClick={() => props.onToggle()}>
                  Select Payment Method
                </p>
              </Dropdown.Toggle>
            </div>
          )}
        </div>
        <div>
          <div className="card-number">
            Please Create or Select a Payment Method
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ userDetails }) => ({
  selectedCard: userDetails.selectedCard,
  loading: userDetails.defaultLoading,
});

export default connect(mapStateToProps, {
  removeUserCard,
  getUserDetails,
  setDefaultCard,
  selectCard,
})(CardListItem);
