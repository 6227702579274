import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import { connect } from "react-redux";
import { updatePassword } from "./actions/AuthActions";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo-green.svg";
import { ReactComponent as BoxLogoWhite } from "../assets/box-in-logo.svg";
import { ReactComponent as GlobeVisual } from "../assets/auth-globe.svg";
import history from "../History";
import { Spinner } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";

const UpdatePasswordPage = () => (
  <div className="auth login d-flex flex-row">
    <div
      className={
        "col-12 col-lg-5 d-none d-lg-flex flex-column align-items-center"
      }
    >
      <Link
        className="header d-flex flex-row align-items-center"
        to={ROUTES.LANDING}
      >
        <div>
          <BoxLogo />
        </div>
        <h2 className="mb-0">ShipShap</h2>
      </Link>

      <div className={"intro d-flex flex-column align-items-center"}>
        <div className={"visual d-flex flex-row align-items-center"}>
          <GlobeVisual />
        </div>

        <div className={"text-center"}>
          <p className={"info"}>
            We connect you with the best shipping rates and streamline the order
            fulfillment process for you, from importing your orders, to printing
            a label, to tracking: all from the convenience of your device.
            Shipping from Africa and beyond has never been easier
          </p>
        </div>
      </div>
    </div>
    <div className="patch col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-left justify-content-around">
      <Link
        className="header d-flex d-lg-none flex-row align-items-center"
        to={ROUTES.LANDING}
      >
        <div>
          <BoxLogoWhite />
        </div>
        <h2 className="mb-0">ShipShap</h2>
      </Link>
      <div className="box container col-10 col-lg-6 d-flex flex-column justify-content-center align-items-start text-left">
        <h3 className="signlabel text-center "> Update Password</h3>
        <UpdatePasswordForm form="" frmElt="" />
        <div className={"switchForm"}>
          <a
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
  showCurrent: false,
  showNew: false,
  showConfirm: false,
  error: null,
};

class UpdatePasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.currentPassword === "") {
      this.setState({
        error: { message: "Please enter your current password" },
      });
      return;
    }

    if (this.state.newPassword === "") {
      this.setState({
        error: { message: "Please enter your new password" },
      });
      return;
    }

    if (this.state.confirmNewPassword === "") {
      this.setState({
        error: { message: "Please confirm your new password" },
      });
      return;
    }

    if (this.state.confirmNewPassword === this.state.currentPassword) {
      this.setState({
        error: {
          message: "Current password and new password cannot be the same",
        },
      });
      return;
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        error: { message: "Passwords do not match" },
      });
      return;
    }

    this.props.updatePassword(
      this.state.currentPassword,
      this.state.newPassword,
      () => {
        history.goBack();
      },
    );
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  render() {
    const { email, error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        {error && (
          <p className={"text-danger w-100 text-center"}>{error.message}</p>
        )}
        {this.props.error && (
          <p className={"text-danger w-100 text-center"}>{this.props.error}</p>
        )}
        <div className={"password-field"}>
          <input
            name="currentPassword"
            value={email}
            onChange={this.onChange}
            type={this.state.showCurrent ? "text" : "password"}
            placeholder="Current Password"
          />
          <div
            className={"show-pass-button"}
            onClick={() => {
              this.setState({ showCurrent: !this.state.showCurrent });
            }}
          >
            {this.state.showCurrent ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>
        <div className={"password-field"}>
          <input
            name="newPassword"
            value={email}
            onChange={this.onChange}
            type={this.state.showNew ? "text" : "password"}
            placeholder="New Password"
          />
          <div
            className={"show-pass-button"}
            onClick={() => {
              this.setState({ showNew: !this.state.showNew });
            }}
          >
            {this.state.showNew ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>
        <div className={"password-field"}>
          <input
            name="confirmNewPassword"
            value={email}
            onChange={this.onChange}
            type={this.state.showConfirm ? "text" : "password"}
            placeholder="Confirm New Password"
          />
          <div
            className={"show-pass-button"}
            onClick={() => {
              this.setState({ showConfirm: !this.state.showConfirm });
            }}
          >
            {this.state.showConfirm ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>
        <button disabled={this.props.loading} type="submit">
          {this.props.loading ? (
            <Spinner animation={"border"} role={"status"} className={"m-0"} />
          ) : (
            "Update Password"
          )}
        </button>
      </form>
    );
  }
}

export default UpdatePasswordPage;

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});

const UpdatePasswordForm = connect(mapStateToProps, { updatePassword })(
  UpdatePasswordFormBase,
);

export { UpdatePasswordForm };
