import React, { useEffect, useState } from "react";
import contact_image from "../../assets/contact-image.png";
import "../../scss/contact.scss";
import { connect } from "react-redux";
import {
  updateContactFormField,
  submitContactForm,
  clearContactForm,
} from "../actions";
import { Spinner } from "react-bootstrap";
import { ReactComponent as SuccessIcon } from "../../assets/contact-from-success.svg";

const Contact = (props) => {
  const [errors, setErrors] = useState({
    fName: "",
    lName: "",
    email: "",
    subject: "",
    description: "",
  });

  const handleSubmit = () => {
    const errorsUpdate = { ...errors };
    if (!props.contactForm.fName) {
      errorsUpdate.fName = "*First Name can't be empty";
    }
    if (!props.contactForm.lName) {
      errorsUpdate.lName = "*Last Name can't be empty";
    }
    if (!props.contactForm.email) {
      errorsUpdate.email = "*Email can't be empty";
    } else if (
      !props.contactForm.email ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        props.contactForm.email.toString().toLowerCase(),
      )
    ) {
      errorsUpdate.email = "*Please enter a valid email";
    }
    if (!props.contactForm.subject) {
      errorsUpdate.subject = "*Subject can't be empty";
    }
    if (!props.contactForm.description) {
      errorsUpdate.description = "*Description can't be empty";
    }

    setErrors(errorsUpdate);

    if (
      !errorsUpdate.fName &&
      !errorsUpdate.lName &&
      !errorsUpdate.email &&
      !errorsUpdate.subject &&
      !errorsUpdate.description
    ) {
      props.submitContactForm(props.contactForm);
    }
  };

  return (
    <div className="landing contact-page d-flex flex-row justify-content-end">
      {props.contactForm.success ? (
        <div
          className={
            "success col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center"
          }
        >
          <SuccessIcon />
          <h4>We've received your message</h4>
          <p className={"info"}>
            Thanks for reaching out! Our team will reach out to you soon.
          </p>
          <button onClick={() => props.clearContactForm()}>
            Send another message
          </button>
        </div>
      ) : (
        <div className="content col-12 col-lg-6 justify-content-center align-items-center">
          <h1>Contact Us</h1>
          <p>Our most helpful team would love to hear from You!</p>
          {props.errors && <p className={"text-danger"}>{props.errors}</p>}
          <div className="contact-form">
            <div className="names d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <div
                className={` d-flex flex-column ${errors.fName ? "error" : ""}`}
              >
                <input
                  placeholder="First Name"
                  value={props.contactForm.fName}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      fName: "",
                    });
                    props.updateContactFormField("fName", e.target.value);
                  }}
                />
                <p className={`${errors.fName ? "show" : ""}`}>
                  {errors.fName || "&nbsp;"}
                </p>
              </div>
              <div
                className={` d-flex flex-column ${errors.lName ? "error" : ""}`}
              >
                <input
                  placeholder="Last Name"
                  value={props.contactForm.lName}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      lName: "",
                    });
                    props.updateContactFormField("lName", e.target.value);
                  }}
                />
                <p className={`${errors.lName ? "show" : ""}`}>
                  {errors.lName || "&nbsp;"}
                </p>
              </div>
            </div>
            <div className="email-phone d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <div
                className={` d-flex flex-column ${errors.email ? "error" : ""}`}
              >
                <input
                  placeholder="Email"
                  value={props.contactForm.email}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      email: "",
                    });
                    props.updateContactFormField("email", e.target.value);
                  }}
                />
                <p className={`${errors.email ? "show" : ""}`}>
                  {errors.email || "&nbsp;"}
                </p>
              </div>
              <div className={` d-flex flex-column `}>
                <input
                  placeholder="Phone Number"
                  value={props.contactForm.phone}
                  onChange={(e) =>
                    props.updateContactFormField("phone", e.target.value)
                  }
                />
                <p>&nbsp;</p>
              </div>
            </div>

            <div
              className={`d-flex flex-column ${errors.subject ? "error" : ""}`}
            >
              <input
                className={"w-100"}
                placeholder="How can we help?"
                value={props.contactForm.subject}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    subject: "",
                  });
                  props.updateContactFormField("subject", e.target.value);
                }}
              />

              <p className={`${errors.subject ? "show" : ""}`}>
                {errors.subject || "&nbsp;"}
              </p>
            </div>

            <div
              className={`d-flex flex-column ${errors.description ? "error" : ""}`}
            >
              <textarea
                placeholder="Message"
                value={props.contactForm.description}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    description: "",
                  });
                  props.updateContactFormField("description", e.target.value);
                }}
              ></textarea>

              <p className={`${errors.description ? "show" : ""}`}>
                {errors.description || "&nbsp;"}
              </p>
            </div>
            <button className="col-12 p-0" onClick={() => handleSubmit()}>
              {props.loading ? (
                <Spinner animation="border" role="status" />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      )}
      <div className="image d-none d-lg-flex col-12 col-lg-6 p-0">
        <img src={contact_image} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ contactForm }) => ({
  contactForm,
  loading: contactForm.loading,
  errors: contactForm.errors,
});

export default connect(mapStateToProps, {
  updateContactFormField,
  submitContactForm,
  clearContactForm,
})(Contact);
