import React, { Component } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";
import UNITS from "../../../assets/mass-units.json";
import {
  selectParcel,
  updateParcelDimension,
  updateTemplateUse,
  updatePackageType,
  updateParcelWeight,
  updateParcelUnits,
  clearParcelForm,
  editParcelItem,
  deleteParcelItem,
  addParcelItem,
  selectParcelItem,
} from "../../actions/ParcelActions";
import { connect } from "react-redux";
import {
  CUSTOM_PACKAGE_I,
  FLAT_RATE_PACKAGE_I,
} from "../../../constants/types";
import {
  FiEdit2,
  FiX,
  FiAlertCircle,
  FiInfo,
  FiMinus,
  FiPlus,
} from "react-icons/fi";
import {
  checkTemplateIntro,
  clearSelectedTemplate,
  editTemplate,
} from "../../actions";
import ReactDOM from "react-dom";
import PackageDetailItem from "./PackageDetailItem";
import {
  convertToWeightUnit,
  getQuantity,
  validatePackage,
} from "../common/helpers";
import { setApplicationContext } from "../../actions/ApplicationContextActions";

class PackageDetails extends Component {
  constructor(props) {
    super(props);
    this.templateActions = React.createRef();
    this.templateHelp = React.createRef();
  }

  state = {
    showTemplateHelp: false,
    showPackageForm: false,
    showMultiPieceWarning: false,
    errors: { valid: true },
  };

  componentDidMount() {
    if (!this.props.labelTemplate.intro) {
      this.setState({ showTemplateHelp: true });
    } else {
      this.setState({ showTemplateHelp: false });
    }
    window.addEventListener("resize", () => {
      if (this.templateHelp?.current) {
        const helpActions = this.templateHelp.current.childNodes[0];
        this.templateHelp.current.style.display = "flex";
        helpActions.style.top =
          this.templateActions.current.getBoundingClientRect().top - 20 + "px";
        helpActions.style.left =
          this.templateActions.current.getBoundingClientRect().left - 15 + "px";
        helpActions.style.height =
          this.templateActions.current.getBoundingClientRect().height + "px";
      }
    });

    if (
      this.props.packageType === FLAT_RATE_PACKAGE_I &&
      !this.props.selectedParcel?.template
    ) {
      this.onLabelSelect("USPS_FlatRateCardboardEnvelope");
    }

    if (this.props.parcelList?.length === 0) {
      this.setState({ showPackageForm: true });
    } else if (this.props.parcelList?.length > 0) {
      this.setState({ showPackageForm: false });
    }

    if (this.props.selectedOrder) {
      const items = this.props.selectedOrder?.line_items || [];
      let weight = 0;
      let weight_unit = "kg";
      if (
        !items ||
        items.length === 0 ||
        items.some((item) => !item.weight_unit || !item.weight)
      ) {
        return;
      }
      items.forEach((item) => {
        weight += convertToWeightUnit(
          item.weight * getQuantity(item),
          item.weight_unit.toString().trim().toLowerCase(),
          weight_unit,
        );
      });
      this.props.updateParcelWeight(Math.ceil(weight));
      this.props.updateParcelUnits(weight_unit);
    }

    this.setState({ errors: this.props.errors });

    return () => {
      window.removeEventListener("resize", () => {
        if (this.templateHelp?.current) {
          const helpActions = this.templateHelp.current.childNodes[0];
          this.templateHelp.current.style.display = "flex";
          helpActions.style.top =
            this.templateActions.current.getBoundingClientRect().top -
            20 +
            "px";
          helpActions.style.left =
            this.templateActions.current.getBoundingClientRect().left -
            15 +
            "px";
          helpActions.style.height =
            this.templateActions.current.getBoundingClientRect().height + "px";
        }
      });
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState != this.state ||
      prevProps !== this.props ||
      prevProps.labelTemplate.intro !== this.props.labelTemplate.intro
    ) {
      if (!this.props.labelTemplate.intro && this.templateHelp?.current) {
        const helpActions = this.templateHelp.current.childNodes[0];
        this.templateHelp.current.style.display = "flex";
        helpActions.style.top =
          this.templateActions.current.getBoundingClientRect().top - 20 + "px";
        helpActions.style.left =
          this.templateActions.current.getBoundingClientRect().left - 15 + "px";
        helpActions.style.height =
          this.templateActions.current.getBoundingClientRect().height + "px";
      }

      if (
        this.props.packageType === FLAT_RATE_PACKAGE_I &&
        !this.props.selectedParcel?.template
      ) {
        this.onLabelSelect("USPS_FlatRateCardboardEnvelope");
      }

      if (this.props.loading !== prevProps.loading && this.props.loading) {
        this.setState({ showPackageForm: false });
      } else if (
        this.props.parcelList?.length === 0 &&
        this.state.showPackageForm === false &&
        !this.props.loading
      ) {
        this.setState({ showPackageForm: true });
      }

      if (prevProps.labelTemplate !== this.props.labelTemplate) {
        if (this.props.parcelList?.length === 0) {
          this.setState({ showPackageForm: true });
        } else if (this.props.parcelList?.length > 0) {
          this.setState({ showPackageForm: false });
        }
      }
      if (this.props.errors !== prevProps.errors) {
        this.setState({ errors: this.props.errors });
      }
    }
  }

  getElementStyleValue = (element, property) => {
    return window
      .getComputedStyle(ReactDOM.findDOMNode(element))
      .getPropertyValue(property);
  };

  onLabelSelect = (labelType) => {
    const template = localParcels[labelType];
    this.props.selectParcel({
      parcel: {
        template: labelType,
        length: template.length.toString(),
        width: template.width.toString(),
        height: template.height.toString(),
        length_unit: "in",
      },
    });
  };

  toggleType = (packageType, template = "USPS_FlatRateCardboardEnvelope") => {
    if (packageType === FLAT_RATE_PACKAGE_I) {
      this.props.updatePackageType(packageType);
      this.props.updateTemplateUse(true);
      let selected = this.props.selectedParcel;
      this.onLabelSelect(template);
    } else if (packageType === CUSTOM_PACKAGE_I) {
      this.props.updatePackageType(packageType);
      this.props.updateTemplateUse(false);
      this.props.selectParcel({
        parcel: {
          length: "",
          width: "",
          height: "",
          length_unit: "cm",
        },
      });
    }
  };

  savePackage = () => {
    const checkValidPacakge = validatePackage(
      this.props.selectedParcel,
      this.props.selectedWeight,
      this.props.packageType,
    );
    if (!checkValidPacakge.valid) {
      this.setState({ errors: { ...checkValidPacakge } });
      return;
    }

    if (this.props.selectedIndex === -1) {
      this.props.addParcelItem({
        ...this.props.selectedParcel,
        ...this.props.selectedWeight,
      });
    } else {
      this.props.editParcelItem({
        ...this.props.selectedParcel,
        ...this.props.selectedWeight,
      });
    }

    this.setState({ showPackageForm: false });
  };

  renderDimensionList = (template) => {
    const items = localParcels;
    const list = Object.keys(localParcels).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => this.onLabelSelect(item)}
          className="border-bottom pl-2"
        >
          <p className="mb-0">{items[item]?.name}</p>
          <p className="dimensions">{items[item]?.dimensions}</p>
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown
        className={`template-selector ${this.state.errors?.errors?.template ? "show-error" : ""}`}
      >
        <Dropdown.Toggle className="col-12 p-2 d-flex flex-row justify-content-between align-items-center">
          <div className="text-left">
            <p className="m-0">{items[template]?.name}</p>
            <p className="dimensions m-0">{items[template]?.dimensions}</p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: 330, overflowY: "auto" }}>
          {list}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderTemplateParcel = () => {
    return (
      <div className="mb-3">
        {this.renderDimensionList(this.props.selectedParcel.template)}
      </div>
    );
  };
  renderCustomParcel = () => {
    const { selectedParcel } = this.props;
    return (
      <div className="custom-inputs col-12 p-0 d-flex flex-row justify-content-between">
        <label
          className={`d-flex flex-column align-items-start ${this.state.errors?.errors?.length ? "show-error" : ""}`}
        >
          <p>Length</p>
          <input
            type={"number"}
            onWheel={(e) => {
              e.target.blur();
            }}
            onScroll={(e) => {
              e.target.blur();
            }}
            min={0}
            value={selectedParcel.length}
            onChange={(text) =>
              this.props.updateParcelDimension({ length: text.target.value })
            }
          />
        </label>
        <label
          className={`d-flex flex-column align-items-start ${this.state.errors?.errors?.width ? "show-error" : ""}`}
        >
          <p>Width</p>
          <input
            type={"number"}
            onWheel={(e) => {
              e.target.blur();
            }}
            onScroll={(e) => {
              e.target.blur();
            }}
            min={0}
            value={selectedParcel.width}
            onChange={(text) =>
              this.props.updateParcelDimension({ width: text.target.value })
            }
          />
        </label>
        <label
          className={`d-flex flex-column align-items-start ${this.state.errors?.errors?.height ? "show-error" : ""}`}
        >
          <p>Height</p>
          <input
            type={"number"}
            onWheel={(e) => {
              e.target.blur();
            }}
            onScroll={(e) => {
              e.target.blur();
            }}
            min={0}
            value={selectedParcel.height}
            onChange={(text) =>
              this.props.updateParcelDimension({ height: text.target.value })
            }
          />
        </label>
        <label className="d-flex flex-column align-items-start">
          <p>&nbsp;</p>
          <Dropdown className={"col-12 p-0"}>
            <Dropdown.Toggle>
              {this.props.selectedParcel.length_unit}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  this.props.updateParcelDimension({ length_unit: "in" })
                }
              >
                in
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  this.props.updateParcelDimension({ length_unit: "cm" })
                }
              >
                cm
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  this.props.updateParcelDimension({ length_unit: "mm" })
                }
              >
                mm
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </label>
      </div>
    );
  };

  renderPackageType = (type) => {
    if (type === FLAT_RATE_PACKAGE_I) {
      return this.renderTemplateParcel();
    } else if (type === CUSTOM_PACKAGE_I) {
      return this.renderCustomParcel();
    }
    this.setState({ showPackageForm: false });
  };
  renderWeight = () => {
    const list = Object.keys(UNITS).map((item, idx) => {
      return (
        <Dropdown.Item
          onClick={() => {
            this.props.updateParcelUnits(item);
          }}
          key={idx}
        >
          {UNITS[item]}
        </Dropdown.Item>
      );
    });
    return (
      <div className="weight-inputs d-flex flex-row justify-content-between align-items-end mb-3">
        <label
          className={`d-flex flex-column ${this.state.errors?.errors?.weight ? "show-error" : ""}`}
        >
          <p className="package-prompt mb-2">Weight</p>
          <input
            type={"number"}
            onWheel={(e) => {
              e.target.blur();
            }}
            onScroll={(e) => {
              e.target.blur();
            }}
            min={0}
            value={this.props.selectedWeight.weight}
            onChange={(text) =>
              this.props.updateParcelWeight(text.target.value)
            }
          />
        </label>
        <label>
          <p>&nbsp;</p>
          <Dropdown>
            <Dropdown.Toggle className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex flex-row">
                <p className="d-none d-md-flex m-0">
                  {UNITS[this.props.selectedWeight.weight_unit]}
                </p>
                <p className="d-flex d-md-none m-0">
                  {this.props.selectedWeight.weight_unit}
                </p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>{list}</Dropdown.Menu>
          </Dropdown>
        </label>
      </div>
    );
  };

  renderQuantity = () => {
    return (
      <div className="multi-piece-quantity d-flex flex-row justify-content-between align-items-end">
        <label className={`d-flex flex-column`}>
          <p className="package-prompt mb-2">Quantity</p>
          <div
            className={
              "d-flex flex-row align-items-center justify-content-start"
            }
          >
            <button
              onClick={() => {
                if (!this.props.selectedParcel?.quantity) {
                  this.props.updateParcelDimension({ quantity: 1 });
                  return;
                }
                if (parseInt(this.props.selectedParcel.quantity) > 1)
                  this.props.updateParcelDimension({
                    quantity: parseInt(this.props.selectedParcel.quantity) - 1,
                  });
              }}
            >
              <FiMinus />
            </button>
            <input
              type={"number"}
              onWheel={(e) => {
                e.target.blur();
              }}
              onScroll={(e) => {
                e.target.blur();
              }}
              min={1}
              value={parseInt(this.props.selectedParcel.quantity)}
              onChange={(text) =>
                this.props.updateParcelDimension({
                  quantity: parseInt(text.target.value),
                })
              }
            />
            <button
              onClick={() => {
                if (!this.props.selectedParcel?.quantity) {
                  this.props.updateParcelDimension({ quantity: 1 });
                  return;
                }
                this.props.updateParcelDimension({
                  quantity: parseInt(this.props.selectedParcel.quantity) + 1,
                });
              }}
            >
              <FiPlus />
            </button>
          </div>
        </label>
      </div>
    );
  };

  renderMultiPieceWarning = () => {
    return (
      <div className="modal">
        <div
          className="backdrop"
          onClick={() => {
            this.setState({
              showMultiPieceWarning: false,
            });
          }}
          onKeyDown={() => {
            this.setState({
              showMultiPieceWarning: false,
            });
          }}
        ></div>
        <div className="modal-content overlay-prompt d-flex flex-column align-items-center text-center">
          <div className={"prompt-icon"}>
            <FiInfo />
          </div>
          <div className={"col-12 col-lg-9 p-0"}>
            <p>
              <b>You are about to create a mutil piece shipment. </b>
            </p>
            <p className={"prompt-description"}>
              This will create separate packages for the shipment, and this will
              charge more for the shipping.
            </p>
            <p className={"prompt-description"}>
              If you dont want to create a multi piece shipment and only wanted
              to get shipping rates, click 'Cancel' and go straight to 'Get
              Rates' to get a normal shipment.
            </p>
          </div>
          <div className="col-12 col-lg-9 p-0 d-flex flex-column justify-content-between">
            <button
              onClick={() => {
                this.savePackage();
                this.setState({ showPackageForm: true });
                this.setState({
                  showMultiPieceWarning: false,
                });
                this.props.setApplicationContext({
                  showMultiPieceWarning: false,
                });
              }}
              className="delete-button"
            >
              I understand, Proceed
            </button>
            <button
              onClick={() => {
                this.setState({
                  showMultiPieceWarning: false,
                });
                this.props.setApplicationContext({
                  showMultiPieceWarning: false,
                });
              }}
              className={"no-bg"}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const packageType = this.props.packageType;
    return (
      <div className="package-details">
        <div
          className={
            "d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center mb-3"
          }
        >
          <h3>Package Details</h3>
          {!this.props.template && (
            <div
              ref={this.templateActions}
              className={
                "template-actions d-flex flex-row justify-content-end align-items-center"
              }
            >
              <a onClick={() => this.props.toggleTemplateList()}>
                Use Template
              </a>
              <p>or</p>
              <a onClick={() => this.props.toggleTemplateForm()}>
                Create Template
              </a>
            </div>
          )}
        </div>
        {!this.props.template && this.props.labelTemplate.selectedTemplate && (
          <div
            className={
              "selected-template d-flex flex-row justify-content-between align-items-center"
            }
          >
            <div className={" d-flex flex-row align-items-center"}>
              <p>Template&nbsp;-&nbsp;</p>
              <p className={"template-name"}>
                {this.props.labelTemplate.selectedTemplate.name}
              </p>
            </div>
            <div>
              <a
                onClick={() =>
                  this.props.editTemplate(
                    this.props.labelTemplate.selectedTemplate,
                    () => {
                      this.props.toggleTemplateForm();
                    },
                  )
                }
              >
                <FiEdit2 />
              </a>
              <a onClick={() => this.props.clearSelectedTemplate()}>
                <FiX size={35} />
              </a>
            </div>
          </div>
        )}
        <div>
          <div>
            {/*<div className={`divider d-none ${!this.props.template?'d-md-flex':''}`}></div>*/}

            {this.state.showPackageForm && (
              <>
                <div
                  className={`button-group switch col-10 p-0 mx-auto ${!this.props.template ? "active" : ""} flex-row justify-content-between mb-3`}
                >
                  <button
                    className={`${packageType === CUSTOM_PACKAGE_I ? "active" : ""} mr-3`}
                    onClick={() => this.toggleType(CUSTOM_PACKAGE_I)}
                    disabled={packageType === CUSTOM_PACKAGE_I ? true : false}
                  >
                    Custom Package Dimensions
                  </button>
                  <button
                    className={`${packageType === FLAT_RATE_PACKAGE_I ? "active" : ""} ml-3`}
                    onClick={() => this.toggleType(FLAT_RATE_PACKAGE_I)}
                    disabled={
                      packageType === FLAT_RATE_PACKAGE_I ? true : false
                    }
                  >
                    Flat Rate Packages
                  </button>
                </div>
                <div className={`divider `}></div>
                <div
                  className={`button-group col-12 p-0 mx-auto d-flex ${!this.props.template ? "d-md-none" : ""} flex-row justify-content-between mb-3`}
                >
                  <Dropdown className="col-12 p-0">
                    <Dropdown.Toggle className="d-flex flex-row justify-content-between align-items-center">
                      {packageType === CUSTOM_PACKAGE_I
                        ? "Custom Package Dimensions"
                        : "Flat Rate Packages"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.toggleType(CUSTOM_PACKAGE_I)}
                        disabled={
                          packageType === CUSTOM_PACKAGE_I ? true : false
                        }
                      >
                        Custom Package Dimensions
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.toggleType(FLAT_RATE_PACKAGE_I)}
                        disabled={
                          packageType === FLAT_RATE_PACKAGE_I ? true : false
                        }
                      >
                        Flat Rate Packages
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-3">
                  {packageType === FLAT_RATE_PACKAGE_I && (
                    <p className="package-prompt mb-2">{"Flat Package Type"}</p>
                  )}
                  {this.renderPackageType(packageType)}
                </div>

                {this.renderWeight()}
                {this.renderQuantity()}
                {!this.state.errors?.valid && (
                  <div className={"error-block"}>
                    <FiAlertCircle />
                    <p>{this.state.errors.message}</p>
                  </div>
                )}

                {this.props.parcelList?.length === 0 && !this.props.template ? (
                  <a
                    className={"add-package-action"}
                    onClick={() => {
                      if (this.props.context.showMultiPieceWarning) {
                        this.setState({
                          showMultiPieceWarning: true,
                        });
                      } else {
                        this.savePackage();
                        this.setState({ showPackageForm: true });
                      }
                    }}
                  >
                    + Add another package
                    <OverlayTrigger
                      key={"business-details-missing"}
                      placement="right"
                      overlay={
                        <Tooltip
                          id="business-details-missing-tooltip"
                          className={"business-details-missing-tooltip"}
                        >
                          This will create a multi piece shipment. <br />
                          Will incur additional charges
                        </Tooltip>
                      }
                      delayShow={300}
                      delayHide={150}
                    >
                      <span>
                        <FiInfo className={"warning"} />
                      </span>
                    </OverlayTrigger>
                  </a>
                ) : (
                  <div
                    className={
                      "parcel-actions d-flex flex-column flex-lg-row justify-content-between align-items-center"
                    }
                  >
                    <button
                      className={
                        "button-secondary order-1 order-lg-0 mb-2 mb-lg-0"
                      }
                      onClick={() => {
                        this.props.clearParcelForm();
                        this.setState({ showPackageForm: false });
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className={"order-0 order-lg-1"}
                      onClick={() => this.savePackage()}
                    >
                      {this.props.selectedIndex === -1
                        ? "Add this package"
                        : "Save this package"}
                    </button>
                  </div>
                )}
                {this.props.parcelList?.length > 0 && (
                  <div className={`divider `}></div>
                )}
              </>
            )}
          </div>
          <div className={"parcels-list"}>
            {this.props.parcelList.map((item, idx) => {
              return (
                <PackageDetailItem
                  key={idx}
                  item={item}
                  template={this.props.template}
                  isPackageChecked={
                    Array.isArray(this.props.isPackageChecked)
                      ? this.props.isPackageChecked?.includes(idx)
                      : []
                  }
                  checkPackages={() => this.props.checkPackages(idx)}
                  selected={this.props.selectedIndex === idx}
                  editParcel={() => {
                    if (item.template) {
                      this.toggleType(FLAT_RATE_PACKAGE_I, item.template);
                    } else {
                      this.toggleType(CUSTOM_PACKAGE_I);
                    }
                    this.props.selectParcelItem(idx);
                    this.setState({ showPackageForm: true });
                  }}
                  deleteParcel={() => {
                    this.props.deleteParcelItem(idx);
                  }}
                  error={
                    this.props.parcelsError
                      ? this.props.parcelsError[idx]
                      : null
                  }
                />
              );
            })}
            {!this.state.showPackageForm &&
              !this.props.loading &&
              this.props.parcelList.every((parcel) =>
                ["length", "width", "height"].every(
                  (dimension) => parcel[dimension] > 0,
                ),
              ) && (
                <a
                  className={"add-package-action"}
                  onClick={() => this.setState({ showPackageForm: true })}
                >
                  + Add another package
                </a>
              )}
          </div>
        </div>

        {this.state.showTemplateHelp && (
          <div
            ref={this.templateHelp}
            className={"template-help"}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                showTemplateHelp: false,
              });
              this.props.checkTemplateIntro();
            }}
          >
            <div
              className={
                "template-actions d-flex flex-row justify-content-end align-items-center"
              }
            >
              <p className={"actions"}>Use Template</p>
              <p>or</p>
              <p className={"actions"}>Create Template</p>
              <div className={"template-intro-message"}>
                <div
                  className={
                    "d-flex flex-row justify-content-between align-items-center"
                  }
                >
                  <h3>Create and Save Template</h3>
                  <FiX
                    onClick={() => {
                      this.setState({
                        showTemplateHelp: false,
                      });
                      this.props.checkTemplateIntro();
                    }}
                  />
                </div>
                <p>
                  Now you can create, and save your Package Details as a
                  template and use it again later when you need it.
                </p>
              </div>
              <div className={"point"}></div>
            </div>
          </div>
        )}
        {this.props.context.showMultiPieceWarning &&
          this.state.showMultiPieceWarning &&
          this.renderMultiPieceWarning()}
      </div>
    );
  }
}
const mapStateToProps = ({
  parcels,
  parcels: { selectedParcel, packageType, selectedWeight },
  labelTemplate,
  userDetails,
  rateChoices,
  applicationContext,
  stores,
}) => ({
  selectedParcel,
  packageType,
  selectedWeight,
  parcels,
  labelTemplate,
  onboarded: userDetails.info.onboarded_at,
  selectedIndex: parcels.selectedParcelIndex,
  parcelList: parcels.parcels,
  loading: rateChoices.loading,
  context: applicationContext.context,
  selectedOrder: stores.selectedOrder,
});

export default connect(mapStateToProps, {
  selectParcel,
  updateParcelDimension,
  updateTemplateUse,
  updatePackageType,
  updateParcelWeight,
  updateParcelUnits,
  clearSelectedTemplate,
  checkTemplateIntro,
  editTemplate,
  clearParcelForm,
  editParcelItem,
  deleteParcelItem,
  addParcelItem,
  selectParcelItem,
  setApplicationContext,
})(PackageDetails);
