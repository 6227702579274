import React from "react";
import { connect } from "react-redux";
import { CARD_LIST, CARD_PAY } from "../../../constants/types";
import { FiTrash2 } from "react-icons/fi";
import { Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import MPESA from "../../../assets/mpesa-logo.svg";

const PaystackCardListItem = (props) => {
  const getCardIcon = (type) => {
    switch (type) {
      case "mpesa":
      default:
        return <img src={MPESA} alt="mpesa" />;
    }
  };

  const card = props.card;
  if (card) {
    return (
      <div
        className={`card-list-item card-section ${props.select ? "selectable" : ""}`}
        key={card.id}
        onClick={props.select ? () => props.selectCard(props.card) : null}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div
            className={`${!props.select ? "col-7" : "col-12"} p-0 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-start align-items-md-center`}
          >
            <div className="col-12 col-md-6 p-0 d-flex flex-row justify-content-start align-items-center">
              <div className="card-icon mr-3 mb-4">
                {getCardIcon(card.payment_method_type)}
              </div>
              <div>
                <p className="d-none d-md-flex">Phone Number</p>
                <p className="info">{card.details.mobile_money_phone_number}</p>
              </div>
            </div>
          </div>
          {props.cardActions()}
        </div>
      </div>
    );
  } else return <></>;
};

const mapStateToProps = ({ userDetails }) => ({
  selectedCard: userDetails.selectedCard,
  loading: userDetails.defaultLoading,
});

export default connect(mapStateToProps, {})(PaystackCardListItem);
