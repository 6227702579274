import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiInfo } from "react-icons/fi";

export const FormField = ({
  controlId,
  className,
  label,
  isRequired,
  value,
  isInvalid,
  type,
  setter,
  placeholder,
  errorMessage,
  tooltip,
  min,
  max,
  disabled,
  as,
  rows,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      {label && (
        <Form.Label className={"d-flex flex-row align-items-start"}>
          {label}
          {!!tooltip && (
            <OverlayTrigger
              key={"checkout-address-info"}
              placement="auto"
              overlay={
                <Tooltip
                  id="checkout-address-info"
                  className={"checkout-address-info"}
                >
                  {tooltip}
                </Tooltip>
              }
              delayShow={300}
              delayHide={150}
            >
              <span className={"checkout-address-info"}>
                <FiInfo className={"warning"} />
              </span>
            </OverlayTrigger>
          )}{" "}
          {isRequired ? <p className={"text-danger m-0"}>*</p> : null}
        </Form.Label>
      )}
      <Form.Control
        value={value}
        required={isRequired}
        isInvalid={isInvalid || errorMessage}
        type={type}
        min={min}
        max={max}
        as={as}
        rows={rows}
        onChange={(e) => {
          setter(e.target.value);
        }}
        onScroll={(e) => {
          e.target.blur();
        }}
        onWheel={(e) => {
          e.target.blur();
        }}
        placeholder={placeholder || null}
        disabled={disabled || false}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage ||
          `Please input a valid ${label?.toLowerCase() || "value"}`}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
