import React, { useEffect, useState } from "react";
import { FiCheck, FiCopy, FiTrash2, FiX } from "react-icons/fi";
import { ReactComponent as RemoveIcon } from "../../../assets/remove-token-icon.svg";
import { ReactComponent as CopyIcon } from "../../../assets/copy-icon.svg";
import moment from "moment";
import { connect } from "react-redux";
import {
  updateTokenName,
  generateToken,
  getTokens,
  clearNewToken,
  removeToken,
  selectToken,
  addGenerateError,
} from "../../actions";
import { useCookies } from "react-cookie";
import { Spinner } from "react-bootstrap";

const APITokenItem = (props) => {
  return (
    <div className="token-item d-flex flex-row align-items-center justify-content-between">
      <div>
        <div className="d-flex flex-row align-items-center justify-content-start">
          <p>{props.item.name}</p>
          <p className="info date">{`(${moment(props.item.created).format("ddd, MM DD, YYYY, h:mm:ss A")})`}</p>
        </div>
        {/* <p>{props.item.token_key}</p> */}
      </div>
      <FiTrash2
        onClick={() => props.toggleRemove(props.item)}
        className="trash"
      />
    </div>
  );
};

const API = (props) => {
  const [showConfirmation, toggleConfirmation] = useState(false);
  const [showGenerate, toggleGenerate] = useState(false);
  const [showList, toggleList] = useState(false);
  const [copied, setCopied] = useState("not_copied");

  useEffect(() => {
    props.getTokens();
  }, []);

  const [
    cookies,
    // setCookie,
    // removeCookie
  ] = useCookies();

  const deleteToken = () => {
    props.removeToken(props.selected.token_key, () => {
      toggleConfirmation(false);
      props.getTokens();
      props.selectToken(null);
    });
  };

  //     const switchCookie = (is_test)=> {
  //         if(cookies.is_test){
  //             removeCookie('is_test',{domain:(document.location.hostname.split('.').slice(-2).join('.')||document.location.hostname),path:'/'})
  //         }else{
  //             // setCookie('is_test',true,{domain:(document.location.hostname.split('.').slice(-2).join('.')||document.location.hostname),path:'/'})
  //
  //         }
  //
  // }

  const renderConfirmation = () => {
    if (showConfirmation) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => toggleConfirmation(false)}
          ></div>
          <div className="modal-content confirmation">
            <RemoveIcon />
            <div>
              <p>
                You are about to delete token{" "}
                <b>
                  {props.selected?.name
                    ? props.selected.name
                    : props.selected.token_key}
                </b>
              </p>
              <p>Are you sure?</p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button onClick={() => toggleConfirmation(!showConfirmation)}>
                Cancel
              </button>
              <button onClick={() => deleteToken()} className="delete-button">
                {props.loading ? (
                  <div className="spinner-border m-auto" role="status"></div>
                ) : (
                  "Delete Token"
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderGenerateModal = () => {
    if (showGenerate) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={props.token ? null : () => toggleGenerate(false)}
          ></div>
          <div className="modal-content settings-card generate">
            <div className="card-section head d-flex flex-row justify-content-between">
              <h3>New Token</h3>
            </div>
            <div className={`card-section last d-flex flex-column`}>
              {props.token && (
                <div className="success">
                  New API Token Successfully Generated
                </div>
              )}
              {props.generateError && (
                <p className="text-danger">{props.generateError}</p>
              )}
              {props.token ? (
                <div>
                  <div className="token-container d-flex flex-row justify-content-between align-items-center">
                    <p className="token-item">{props.token}</p>
                    <div
                      className="copy"
                      onClick={async () => {
                        setCopied("not_copied");
                        await navigator.clipboard
                          .writeText(props.token)
                          .then((res) => {
                            setCopied("copied");
                            props.addGenerateError("");
                          })
                          .catch((err) => {
                            console.log("Error copying token: ", err);
                            setCopied("copy_failed");
                          });
                      }}
                    >
                      {copied === "not_copied" ? (
                        <CopyIcon className="" />
                      ) : copied === "copied" ? (
                        <FiCheck className="success" />
                      ) : (
                        <FiX className="fail" />
                      )}
                    </div>
                  </div>
                  <p className="info">
                    You should never expose your Token.{" "}
                    <b>Please Store Securely</b>
                  </p>
                  <div className="col-12 p-0 d-flex flex-row justify-content-end align-items-center">
                    <button
                      className="d-flex"
                      onClick={() => {
                        if (copied !== "copied") {
                          props.addGenerateError(
                            "Copy the token before closing, Thank you.",
                          );
                          return;
                        }
                        toggleGenerate(false);
                        props.clearNewToken();
                        setCopied("not_copied");
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p>Give your new token a nickname</p>
                  <div className="token-container d-flex flex-row justify-content-between align-items-center">
                    <input
                      value={props.name}
                      onChange={(e) => props.updateTokenName(e.target.value)}
                      placeholder="Token Name"
                    />
                  </div>
                  <div className="col-12 p-0 d-flex flex-row justify-content-end align-items-center">
                    <button
                      className="d-flex"
                      onClick={() =>
                        props.generateToken({ name: props.name }, () =>
                          props.getTokens(),
                        )
                      }
                    >
                      {props.loading ? (
                        <div className="spinner-border " role="status"></div>
                      ) : (
                        "Generate"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="integrations api col-12 p-0">
      <div className="settings-card">
        {props.errors && <p className="text-danger">{props.errors}</p>}
        <div className="card-section head d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <h3>Test Token</h3>
            {
              <a onClick={() => props.getTokens()} className="mx-3">
                Refresh
              </a>
            }
          </div>
          {
            <a
              className="col-6 col-lg-3"
              onClick={() => toggleGenerate(!showGenerate)}
            >
              Generate Token
            </a>
          }
        </div>
        <div className={`card-section last d-flex flex-column`}>
          {/*{cookies.is_test?*/}
          <>
            <p>
              You should never expose your Test Token.{" "}
              <span className="caps">PLEASE STORE SECURELY</span>
            </p>
            {props.loading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <div className="token-list">
                {!!props.tokenList?.length ? (
                  props.tokenList?.map((item, id) => {
                    return (
                      <APITokenItem
                        item={item}
                        key={id}
                        toggleRemove={(idx) => {
                          props.selectToken(idx);
                          toggleConfirmation(!showConfirmation);
                        }}
                      />
                    );
                  })
                ) : (
                  <div>No tokens created</div>
                )}
              </div>
            )}
          </>
          {/*:*/}
          {/*<p>Live tokens coming soon!<a className='action mx-3' onClick={()=>{*/}
          {/*    // cookies.is_test?removeCookie('is_test',{domain:(document.location.hostname.split('.').slice(-2).join('.')||document.location.hostname),path:'/'}):setCookie('is_test',true,{domain:(document.location.hostname.split('.').slice(-2).join('.')||document.location.hostname),path:'/'})*/}
          {/*}}><span className='caps'>Go To Test Mode</span></a></p>*/}
          {/*}*/}
        </div>
      </div>
      {/* <div className="settings-card">
            <div className="card-section head d-flex flex-row justify-content-between">
                <h3>Webhooks</h3>
                <button className='col-12 col-lg-3' onClick={()=>toggleGenerate(!showGenerate)}>Add New Webhook</button>
            </div>
            <div className={`card-section last d-flex flex-column`}>
                
            </div>
        </div> */}
      {/*<div className="settings-card testing">*/}
      {/*    <div className="card-section head d-flex flex-row justify-content-between">*/}
      {/*        <h3>Test Mode</h3>*/}
      {/*    </div>*/}
      {/*    <div className={`card-section last d-flex flex-column`}>*/}
      {/*        <p>Want to try out printing a label without being charged, try out our test mode. </p>*/}
      {/*        <button className={` ${cookies.is_test?'test':'live'}`} onClick={()=>switchCookie(cookies.is_test)}>{` ${cookies.is_test?'Back To Live Mode':'Enable Test Mode'}`}</button>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {renderGenerateModal()}
      {renderConfirmation()}
    </div>
  );
};

const mapStateToProps = ({ api }) => ({
  api,
  name: api.name,
  token: api.token,
  loading: api.loading,
  errors: api.errors,
  generateError: api.generateError,
  tokenList: api.token_list,
  selected: api.selected_token,
});

export default connect(mapStateToProps, {
  updateTokenName,
  generateToken,
  getTokens,
  clearNewToken,
  removeToken,
  selectToken,
  addGenerateError,
})(API);
