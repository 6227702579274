import {
  EMAIL_CHANGED,
  LOGIN_FAILED,
  LOGIN_RETRY,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_SUCCESS,
  PASSWORD_CHANGED,
  PASSWORD_RESET_LINK_SENT,
  SEND_RESET_LINK,
  SEND_RESET_LINK_FAILED,
  SESSION_AUTH_LOGOUT,
  SESSION_AUTH_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
} from "../../constants/types";

const INITIAL_STATE = {
  email: "",
  password: "",
  user: null,
  error: "",
  loading: false,
  passwordEmailSent: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case PASSWORD_RESET_LINK_SENT:
      return {
        ...state,
        passwordEmailSent: action.payload,
        loading: false,
        error: "",
      };
    case SEND_RESET_LINK:
      return { ...state, passwordEmailSent: false, loading: true };
    case SEND_RESET_LINK_FAILED:
      let resetMessage = "Authentication failed";
      if (action.payload && action.payload.message) {
        resetMessage = action.payload.message;
      }
      return {
        ...state,
        passwordEmailSent: false,
        loading: false,
        error: resetMessage,
      };
    case LOGIN_SUCCESS:
    case SESSION_AUTH_SUCCESS:
      return { ...INITIAL_STATE, user: action.payload };
    case LOGIN_RETRY:
      return { ...state, error: "", password: "", loading: false };
    case LOGIN_FAILED:
      let message = "Authentication failed";
      if (typeof action.payload === "string") {
        message = action.payload;
      } else if (action.payload && action.payload.message) {
        message = action.payload.message;
      }
      return { ...state, error: message, password: "", loading: false };
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE, email: state.email };
    case UPDATE_PASSWORD:
      return { ...state, loading: true };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_PASSWORD_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
