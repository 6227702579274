import React, { useState } from "react";
import WelcomeTour1 from "../../assets/welcome-tour-1.png";
import WelcomeTour1_mob from "../../assets/welcome-tour-1-mob.png";
import WelcomeTour2 from "../../assets/welcome-tour-2.png";
import WelcomeTour2_mob from "../../assets/welcome-tour-2-mob.png";
import WelcomeTour3 from "../../assets/welcome-tour-3.png";
import WelcomeTour3_mob from "../../assets/welcome-tour-3-mob.png";
import WelcomeTour4 from "../../assets/welcome-tour-4.png";
import WelcomeTour4_mob from "../../assets/welcome-tour-4-mob.png";
import "../../scss/welcome-tour.scss";
import { setOnboarded } from "../actions";

const WelcomeTour = (props) => {
  const [slide, setSlide] = useState(0);

  const tourSlides = [
    {
      image: {
        src: WelcomeTour1,
        srcMob: WelcomeTour1_mob,
        alt: "Welcome Tour Introduction",
      },
      content: {
        title: `Welcome to Shipshap, ${props.fullName} 🎉`,
        description:
          "Let us take a quick tour on how to use ShipShap interface, and the benefits you will get.",
      },
      buttonText: "Let's Go",
    },
    {
      image: {
        src: WelcomeTour2,
        srcMob: WelcomeTour2_mob,
        alt: "Dashboard Tour",
      },
      content: {
        title: `Dashboard`,
        description:
          "Everything you’ll need at a glance, here you’ll be able to manage all your order and fulfilllment needs.",
      },
    },
    {
      image: {
        src: WelcomeTour3,
        srcMob: WelcomeTour3_mob,
        alt: "Stores and Orders Tour",
      },
      content: {
        title: `Import orders from all your shops!`,
        description:
          "In our store integrations you'll be able to connect your store and automatically import orders. Streamlining your fulfilment process.",
      },
    },
    {
      image: {
        src: WelcomeTour4,
        srcMob: WelcomeTour4_mob,
        alt: "Create Label Tour",
      },
      content: {
        title: `Send your first package`,
        description:
          "When you click “Create a Shipping Label” you'll be able to generate shipping labels, and get accurate rates immediately at checkout.",
      },
    },
  ];

  return (
    <div className={"welcome-tour-container d-flex flex-column flex-md-row"}>
      <div>
        <img
          src={tourSlides[slide].image.src}
          alt={tourSlides[slide].image.alt}
          className={"d-none d-md-flex"}
        />
        <img
          src={tourSlides[slide].image.srcMob}
          alt={tourSlides[slide].image.alt}
          className={"d-flex d-md-none"}
        />
      </div>
      <div>
        <p className={"slide-count"}>{`${slide + 1} of 4`}</p>
        <div className={"content"}>
          <h3>{tourSlides[slide].content.title}</h3>
          <p>{tourSlides[slide].content.description}</p>
        </div>
        <div className={"buttons"}>
          {props.setOnboarded && (
            <button
              className={"skip"}
              onClick={() => {
                props.setOnboarded();
              }}
            >
              Skip
            </button>
          )}
          <button
            onClick={() => {
              if (slide === 3) {
                if (props.setOnboarded) props.setOnboarded();
                else setSlide(0);
                return;
              }
              setSlide(slide + 1);
            }}
          >
            {tourSlides[slide].buttonText
              ? tourSlides[slide].buttonText
              : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeTour;
