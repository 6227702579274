import {
  CHECK_RATES,
  CLEAR_LABEL_CREATION_ERRORS,
  CLEAR_RATES,
  CLEAR_SELECTED_RATE,
  GET_RATES_FAILED,
  GET_RATES_SUCCESS,
  LABEL_CREATE_SUCCESS,
  LOGOUT_SUCCESS,
  SELECT_RATE,
} from "../../constants/types";
// import data from '../reducers/rates.json'; for testing

const INITIAL_RATES_STATE = {
  rates: [],
  selectedRate: {},
  loading: false,
  errors: null,
  warnings: null,
};

export function ratesReducer(state = INITIAL_RATES_STATE, action) {
  switch (action.type) {
    case CHECK_RATES:
      return {
        ...state,
        loading: true,
        errors: null,
        warnings: null,
        selectedRate: INITIAL_RATES_STATE.selectedRate,
      };
    case GET_RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload.rates,
        loading: false,
        errors: null,
        warnings: action.payload.warnings,
      };
    case GET_RATES_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        warnings: action.payload.warnings,
      };
    case SELECT_RATE:
      return { ...state, selectedRate: action.payload };
    case CLEAR_SELECTED_RATE:
      return {
        ...state,
        selectedRate: INITIAL_RATES_STATE.selectedRate,
        errors: null,
        warnings: null,
      };
    case CLEAR_RATES:
    case LABEL_CREATE_SUCCESS:
      // clear the address when the label is created
      return INITIAL_RATES_STATE;
    case CLEAR_LABEL_CREATION_ERRORS:
      return { ...state, errors: null, warnings: null };
    case LOGOUT_SUCCESS:
      return INITIAL_RATES_STATE;
    default:
      return state;
  }
}
