import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_SUCCESS,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_DETAIL,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_DETAIL_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_DETAIL_SUCCESS,
  SEARCH_TRANSACTION_TEXT_ENTERED,
  SELECT_ACCOUNT,
  SET_TRANSACTIONS_PAGE_SIZE,
  LOGOUT_SUCCESS,
  SESSION_AUTH_LOGOUT,
} from "../../constants/types";

const INITIAL_STATE = {
  accounts: [],
  transactions: [],
  selectedAccount: null,
  selectedTransaction: null,
  transactionsResponse: {},
  loading: false,
  loadingTransactions: false,
  loadingDetail: false,
  errors: "",
  detailErrors: "",
  searchText: "",
  currentPage: 1,
  pageSize: 10,
};

export function walletsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return { ...state, loading: true, errors: "" };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        accounts: action.payload,
        selectedAccount:
          state.selectedAccount || action.payload.length > 0
            ? action.payload[0]
            : null,
      };
    case GET_ACCOUNTS_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case GET_TRANSACTIONS:
      return { ...state, loadingTransactions: true, errors: "" };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        errors: "",
        transactions: action.payload.list,
        transactionsResponse: action.payload.response,
        currentPage: action.payload.currentPage,
      };
    case GET_TRANSACTIONS_FAILURE:
      return { ...state, loadingTransactions: false, errors: action.payload };
    case GET_TRANSACTIONS_DETAIL:
      return { ...state, loadingDetail: true, detailErrors: "" };
    case GET_TRANSACTIONS_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
        detailErrors: "",
        selectedTransaction: action.payload,
      };
    case GET_TRANSACTIONS_DETAIL_FAILURE:
      return { ...state, loadingDetail: false, detailErrors: action.payload };
    case SELECT_ACCOUNT:
      return { ...state, selectedAccount: action.payload };
    case SET_TRANSACTIONS_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case SEARCH_TRANSACTION_TEXT_ENTERED:
      if (action.payload.trim() === "") {
        return { ...state, searchText: "" };
      }
      return { ...state, searchText: action.payload };
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
