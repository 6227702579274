import { connect } from "react-redux";
import {
  getTransactionsByPage,
  searchTransactions,
  selectAccount,
  setTransactionsPageSize,
} from "../../actions";
import "../../../scss/wallets.scss";
import { getAccounts, getTransactions } from "../../actions/WalletsActions";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import TRACKING_STATUS from "../../../assets/tracking-status.json";
import Dropdown from "react-bootstrap/Dropdown";
import { DateRange } from "../common/DateRange";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import Pagination from "../common/Pagination";
import TransactionList from "./TransationList";
import { toMoneyFormat } from "../common/helpers";
import { Spinner } from "react-bootstrap";

export const StatusFilters = {
  complete: "Complete",
  pending: "Pending",
  cancelled: "Cancelled",
};

export const TypeFilters = {
  charge: "Charge",
  credits_added: "Top-up",
  customer_payment: "Customer payment",
  business_payment: "Payout",
  // credits: "credits"
};

const Wallets = (props) => {
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFromFilter, setDateFromFilter] = useState("");
  const [dateToFilter, setDateToFilter] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [dateElement, setDateElement] = useState(null);

  const filters = (() => {
    let filter = "";
    if (typeFilter) {
      if (!!filter) filter += "&";
      filter += `transaction_type=${typeFilter}`;
    }
    if (statusFilter) {
      if (!!filter) filter += "&";
      filter += `state=${statusFilter}`;
    }

    if (dateFromFilter) {
      if (!!filter) filter += "&";
      filter += `date_from=${dateFromFilter}`;
    }
    if (dateToFilter) {
      if (!!filter) filter += "&";
      filter += `date_to=${dateToFilter}`;
    }

    return filter;
  })();

  useEffect(() => {
    props.getAccounts();
  }, []);

  useEffect(() => {
    const account = props.selectedAccount
      ? props.selectedAccount.currency
      : "KES";
    setTimeout(
      props.getTransactions(
        null,
        props.pageSize,
        props.searchText,
        filters,
        account,
      ),
      1000,
    );
  }, [props.searchText, props.pageSize, filters, props.selectedAccount]);

  const renderStatusFilter = () => {
    const list = Object.keys(StatusFilters).map((status) => {
      return (
        <Dropdown.Item
          key={status}
          onClick={(e) => {
            setStatusFilter(status);
          }}
        >
          {StatusFilters[status]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${statusFilter ? "" : "placeholder"}`}>
          {StatusFilters[statusFilter] || "Status"}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderTypeFilter = () => {
    const list = Object.keys(TypeFilters).map((type) => {
      return (
        <Dropdown.Item
          key={type}
          onClick={(e) => {
            setTypeFilter(type);
          }}
        >
          {TypeFilters[type]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${typeFilter ? "" : "placeholder"}`}>
          {TypeFilters[typeFilter] || "Type"}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className={`wallets `}>
      <div
        className={
          "wallet-accounts d-flex flex-row flex-wrap align-items-stretch justify-content-start"
        }
      >
        {props.accounts.length > 0 &&
          props.accounts?.map((account) => {
            return (
              <div
                className={`account-item dash-card ${props.loading ? "disabled" : ""} ${props.selectedAccount?.currency === account?.currency ? "active" : ""}`}
                onClick={() => {
                  if (
                    !props.selectedAccount ||
                    (props.selectedAccount?.currency !== account?.currency &&
                      !props.loading)
                  ) {
                    props.selectAccount(account);
                  }
                }}
              >
                <h4>{`${account.currency.toString().toUpperCase()} Balance`}</h4>
                <p>{toMoneyFormat(account.balance, account.currency)}</p>
              </div>
            );
          })}
      </div>
      <div className={"settings-card"}>
        <div className="card-section head mb-2 d-flex flex-column justify-content-start align-items-start">
          <h3>Activity</h3>
          <div
            className={
              "wallets-filter-section d-flex flex-wrap flex-row align-items-center"
            }
          >
            {renderStatusFilter()}
            {renderTypeFilter()}
            <DateRange
              dateFrom={dateFromFilter}
              dateTo={dateToFilter}
              setDateFrom={setDateFromFilter}
              setDateTo={setDateToFilter}
            />
            {!!typeFilter ||
            !!statusFilter ||
            !!dateFromFilter ||
            !!dateToFilter ? (
              <button
                className={"button-secondary"}
                onClick={() => {
                  setStatusFilter("");
                  setTypeFilter("");
                  setDateToFilter("");
                  setDateFromFilter("");
                }}
              >
                <FiX />
              </button>
            ) : null}
          </div>
        </div>
        <div className="card-section last list">
          {props.loadingTransactions ? (
            <Spinner className={"text-dark"} animation={"border"} />
          ) : (
            <TransactionList
              list={props.transactions}
              searchText={props.searchText}
              pageSize={props.pageSize}
              pagination={
                <Pagination
                  current={props.current}
                  count={props.count}
                  pageSize={props.pageSize}
                  goTo
                  getListByPage={(pageNo) =>
                    props.getTransactionsByPage(
                      null,
                      pageNo,
                      props.pageSize,
                      props.searchText,
                      filters,
                      props.selectedAccount
                        ? props.selectedAccount.currency
                        : "KES",
                    )
                  }
                  setPageSize={(size) => props.setTransactionsPageSize(size)}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ wallets }) => ({
  accounts: wallets.accounts,
  transactions: wallets.transactions,
  selectedAccount: wallets.selectedAccount,
  loading: wallets.loading,
  loadingTransactions: wallets.loadingTransactions,
  errors: wallets.errors,
  searchText: wallets.searchText,
  count: wallets.transactionsResponse?.count || null,
  current: wallets.currentPage,
  pageSize: wallets.pageSize,
});

export default connect(mapStateToProps, {
  getAccounts,
  getTransactions,
  selectAccount,
  searchTransactions,
  setTransactionsPageSize,
  getTransactionsByPage,
})(Wallets);
