import React, { useEffect } from "react";
import shop_integrations from "../../assets/shop-integrations.svg";
import shopify from "../../assets/shopify-logo.svg";
import etsy from "../../assets/etsy-logo.svg";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import shop_signup from "../../assets/shop-signup.png";
import appStore from "../../assets/appstore-badge.png";
import playStore from "../../assets/google-play-badge.png";
import { connect } from "react-redux";
import { updateMailingListEmail, addEmailToMailingList } from "../actions";

const ShopIntegrations = (props) => {
  useEffect(() => {
    // window.scrollTo(0,0)
  }, []);

  return (
    <div
      className="landing shop integrations scrollspy-landing"
      data-bs-spy="scroll"
      data-bs-target="#navbar-landing"
      data-bs-smooth-scroll="true"
      data-offset="50"
      tabIndex="0"
    >
      <section className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div className="col-12 col-lg-7 p-0 m-auto d-flex justify-content-center align-items-center order-0 order-lg-1">
          <img src={shop_integrations} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 d-flex flex-column align-items-start align-items-lg-start order-1 order-lg-0">
          <h1>Shop Integrations</h1>
          <p className="info">
            Streamline your eCommerce operations and say goodbye to messy
            multi-step order fulfillment. With Shipshap, you can directly import
            your eCommerce orders, generate labels, and track shipments.{" "}
          </p>
        </div>
      </section>

      <section className="integration-cta col-12 d-flex flex-column align-items-center">
        <h1>Try Out Our Shop Integrations</h1>
        <p className="info">
          ShipShap facilitates integrations with platforms like Etsy and
          Shopify. Our integrated platform puts all your orders and shipments in
          one place.{" "}
        </p>
        <div className="integrations col-12 col-lg-8 d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div>
            <img src={shopify} alt="shopify" />
            <p>
              Sync orders directly from Shopify. You’ll get an accurate rate at
              checkout, and be able to print shipping labels and update
              customers with tracking, all from one dashboard.{" "}
            </p>
            <Link to={ROUTES.MORE_SHOPIFY} className="action">
              Get Started <FiArrowRight size={18} />
            </Link>
          </div>
          <div>
            <img src={etsy} alt="etsy" />
            <p>
              Integrate Etsy to get a streamlined dashboard with all your
              orders. In one click create a shipping label, get an accurate rate
              at checkout, and update customers with tracking.
            </p>
            <a className="soon">Coming Soon</a>
          </div>
        </div>
        {props.added ? (
          <div
            className={
              "mail success col-12 col-lg-8 d-flex flex-column flex-lg-row align-items-center justify-content-between"
            }
          >
            <div className="d-flex flex-column order-1 order-lg-0">
              <h4>Registration Completed!</h4>
              <p className="info">
                You will receive news when we have new feature on our API’s and
                Integration features.
              </p>
            </div>
            <div className="d-flex order-0 order-lg-1">
              <FiCheck />
            </div>
          </div>
        ) : (
          <div className="mail col-12 col-lg-8">
            <h4>Be the first to know about our APIs and Integrations</h4>
            <p className="info">
              We are constantly adding to our APIs and Integrations, to meet all
              your shipping and business needs.
            </p>
            {props.errors && (
              <p className="text-danger p-0 m-0">{props.errors}</p>
            )}
            <div className="d-flex flex-column flex-lg-row">
              <input
                placeholder="Please enter your Email Address"
                value={props.email}
                onChange={(e) => props.updateMailingListEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    props.addEmailToMailingList(props.email);
                  }
                }}
              />
              <button
                onClick={() => {
                  props.addEmailToMailingList(props.email);
                }}
              >
                {props.loading ? (
                  <div className="spinner-border text-light"></div>
                ) : (
                  "Send me updates"
                )}
              </button>
            </div>
          </div>
        )}
      </section>
      <section className="sign-up col-12 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-start align-items-start ">
          <img src={shop_signup} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 p-0 d-flex flex-column justify-content-center align-items-start ">
          <h1>Sign up Today!</h1>
          <Link to={ROUTES.HOME + ROUTES.DASHBOARD} className="button">
            Start Shipping Now
          </Link>
          <div className="buttons col-12 col-lg-8 p-0 d-flex flex-row align-items-center justify-content-between">
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap"
              className=""
            >
              <img src={playStore} className="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
              className=""
            >
              <img src={appStore} className="" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ mailingList }) => ({
  email: mailingList.mailingListEmail,
  loading: mailingList.loading,
  errors: mailingList.errors,
  added: mailingList.added,
});
export default connect(mapStateToProps, {
  updateMailingListEmail,
  addEmailToMailingList,
})(ShopIntegrations);
