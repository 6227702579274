import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { FiAlertCircle, FiChevronLeft, FiInfo } from "react-icons/fi";
import { ReactComponent as BoxLogo } from "../../../assets/box-in-logo.svg";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import history from "../../../History";
import {
  saveBusinessDetails,
  updateBusinessForm,
} from "../../actions/BusinessActions";
import COUNTRYLIST from "../../../assets/country-list.json";
import { render } from "react-dom";
import getCountryFlag from "country-flag-icons/unicode";
import COUNTRY_LIST from "../../../assets/country-list.json";

const BankAccountForm = (props) => {
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");

  const handleEnter = (event) => {
    const form = event.target.parentNode.children;
    const index = [...form].indexOf(event.target);
    if (
      event.key.toLowerCase() === "enter" ||
      event.key.toLowerCase() === "arrowdown"
    ) {
      form[index + 1].focus();
      event.preventDefault();
    } else if (event.key.toLowerCase() === "arrowup") {
      form[index - 1].focus();
      event.preventDefault();
    }
    event.stopPropagation();
  };

  const handleNext = () => {
    history.push(ROUTES.BUSINESS_REGISTRATION);
  };

  const clearInputErrors = () => {
    setCountryError("");
    setBankNameError("");
    setAccountNumberError("");
    setAccountNameError("");
  };

  const validateInputs = ({
    country,
    bank_name,
    account_name,
    account_number,
  }) => {
    let valid = true;

    if (!country || country === "") {
      setCountryError("Country cannot be emtpy");
      valid = false;
    }
    if (!bank_name || bank_name.trim() === "") {
      setBankNameError("Bank name cannot be emtpy");
      valid = false;
    }
    if (!account_number || account_number.toString().trim() === "") {
      setAccountNumberError("Account number cannot be emtpy");
      valid = false;
    }
    // if(isNaN(account_number)){
    //     setAccountNumberError('Account number has to be a number')
    //     valid=false
    // }
    if (!account_name || account_name.trim() === "") {
      setAccountNameError("Account name cannot be emtpy");
      valid = false;
    }
    return valid;
  };

  const handleSubmit = () => {
    const { country, bank_name, account_number, account_name } =
      props.businessDetailsForm;
    const data = {
      country,
      bank_name,
      account_name,
      account_number,
    };
    clearInputErrors();
    if (validateInputs(data)) {
      props.saveBusinessDetails(data, handleNext);
    }
  };

  useEffect(() => {
    if (props.details) {
      const details = {};
      Object.keys(props.details).forEach((item) => {
        if (
          props.details[item] &&
          props.details[item].toString().trim() !== ""
        ) {
          details[item] = props.details[item];
        }
      });
      props.updateBusinessForm(details);
    }
    if (props.businessDetailsForm?.country === "" && props.context.country) {
      props.updateBusinessForm({ country: props.context.country });
      setCountry(COUNTRYLIST[props.context.country]?.name);
    }
  }, []);

  useEffect(() => {
    setCountry(
      COUNTRYLIST[props.businessDetailsForm?.country]?.name || country || "",
    );
  }, [props.businessDetailsForm?.country]);

  const renderCountryList = () => {
    const list = Object.keys(COUNTRY_LIST).filter((item) => {
      return (
        item.toLowerCase().includes(country?.toString().toLowerCase()) ||
        COUNTRY_LIST[item].name
          ?.toString()
          .toLowerCase()
          .includes(country?.toString().toLowerCase())
      );
    });
    if (list.length > 0)
      return list.map((item, idx) => {
        return (
          <a
            key={item}
            onClick={() => {
              setCountry(COUNTRYLIST[props.businessDetailsForm?.country]?.name);
            }}
            onKeyDown={(e) => {
              setCountry(COUNTRYLIST[props.businessDetailsForm?.country]?.name);
            }}
          >{`${getCountryFlag(item)} ${COUNTRY_LIST[item]?.name}`}</a>
        );
      });
    return <p></p>;
  };

  return (
    <>
      <h3>Proof of bank account ownership</h3>

      {props.businessDetailsForm?.errors && (
        <div className={"error-block"}>
          <FiAlertCircle />
          <p>{props.businessDetailsForm?.error}</p>
        </div>
      )}
      <div
        className={"form col-12 d-flex flex-column align-items-start text-left"}
      >
        <label
          className={`country-select col-12 p-0 d-flex flex-column ${countryError.trim() !== "" ? "error" : ""}`}
        >
          Country
          <input
            placeholder={"Enter your country"}
            type={"text"}
            value={country}
            onChange={(e) => {
              setCountryError("");
              if (e.target.value.trim() === "")
                props.updateBusinessForm({ country: "" });
              setCountry(e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              setTimeout(() => {
                let list = Object.keys(COUNTRY_LIST).filter((item) => {
                  return item
                    .toLowerCase()
                    .includes(e.target.value?.toString().toLowerCase());
                });
                if (list.length > 0 && !!e.target.value) {
                  setCountry(COUNTRY_LIST[list[0]]?.name);
                  props.updateBusinessForm({ country: list[0] });
                  return;
                }
                list = Object.keys(COUNTRY_LIST).filter((item) => {
                  return COUNTRY_LIST[item]?.name
                    ?.toString()
                    .toLowerCase()
                    .includes(e.target.value?.toString().toLowerCase());
                });
                if (list.length > 0 && !!e.target.value) {
                  setCountry(COUNTRY_LIST[list[0]]?.name);
                  props.updateBusinessForm({ country: list[0] });
                } else if (
                  !props.businessDetailsForm?.country?.toString().trim()
                ) {
                  setCountry(COUNTRY_LIST[props.country]?.name);
                } else {
                  setCountry(
                    COUNTRY_LIST[props.businessDetailsForm?.country]?.name,
                  );
                }
              }, 200);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
          {country && (
            <div className={"search-results"}>{renderCountryList(country)}</div>
          )}
          {countryError.trim() !== "" && (
            <p className={"error"}>{countryError}</p>
          )}
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${bankNameError.trim() !== "" ? "error" : ""}`}
        >
          Bank Name
          <input
            placeholder={"Enter your bank name"}
            value={props.businessDetailsForm?.bank_name}
            onChange={(e) => {
              setBankNameError("");
              props.updateBusinessForm({ bank_name: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          {bankNameError.trim() !== "" && (
            <p className={"error"}>{bankNameError}</p>
          )}
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${accountNumberError.trim() !== "" ? "error" : ""}`}
        >
          Account Number
          <input
            placeholder={"Enter your account number"}
            value={props.businessDetailsForm?.account_number}
            onChange={(e) => {
              setAccountNumberError("");
              props.updateBusinessForm({ account_number: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          {accountNumberError.trim() !== "" && (
            <p className={"error"}>{accountNumberError}</p>
          )}
        </label>
        <label
          className={`col-12 p-0 d-flex flex-column ${accountNameError.trim() !== "" ? "error" : ""}`}
        >
          Account Name
          <input
            placeholder={"Enter your account name"}
            value={props.businessDetailsForm?.account_name}
            onChange={(e) => {
              setAccountNameError("");
              props.updateBusinessForm({ account_name: e.target.value });
            }}
            onKeyDown={handleEnter}
          />
          {accountNameError.trim() !== "" && (
            <p className={"error"}>{accountNameError}</p>
          )}
        </label>
      </div>
      <div
        className={
          "buttons col-12 d-flex flex-column align-items-center text-left"
        }
      >
        <button
          onClick={handleSubmit}
          disabled={props.businessDetailsForm?.loading}
        >
          {props.businessDetailsForm?.loading ? (
            <Spinner animation={"border"} role={"status"} />
          ) : (
            "Continue"
          )}
        </button>
        <a className={"skip"} onClick={handleNext}>
          Skip
        </a>
      </div>
    </>
  );
};

const mapStateToProps = ({
  businessDetails,
  businessDetailsForm,
  applicationContext,
}) => ({
  businessDetailsForm,
  details: businessDetails.details,
  context: applicationContext.context,
});

export default connect(mapStateToProps, {
  saveBusinessDetails,
  updateBusinessForm,
})(BankAccountForm);
