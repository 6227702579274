import {
  CLEAR_CSV_CONFIG,
  CLEAR_CSV_ERRORS,
  CREATE_CSV_STORE,
  CREATE_CSV_STORE_FAILED,
  CREATE_CSV_STORE_SUCCESS,
  GET_DOWNLOAD_ORDER_CSV,
  GET_DOWNLOAD_ORDER_CSV_FAILED,
  GET_DOWNLOAD_ORDER_CSV_SUCCESS,
  SAVE_CSV_CONFIG,
  SELECT_CSV_STORE,
  UNSELECT_SHOP_CSV,
  UPLOAD_CSV_FILE,
  UPLOAD_CSV_FILE_FAILED,
  UPLOAD_CSV_FILE_SUCCESS,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import axios from "axios";
import { parseError } from "./ActionHelpers";

export const selectStore = (store) => (dispatch) => {
  dispatch({
    type: SELECT_CSV_STORE,
    payload: store,
  });
};

export const createShopCSV = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: CREATE_CSV_STORE, // TODO: Change this type
    });

    instance
      .post("/platforms/shops", data)
      .then((res) => {
        dispatch({
          type: CREATE_CSV_STORE_SUCCESS, // TODO: Change this type
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 400) {
          dispatch({
            type: CREATE_CSV_STORE_FAILED, // TODO: Change this type
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: CREATE_CSV_STORE_FAILED, // TODO: Change this type
            payload: err,
          });
        }
        reject(err);
      });
  });
};

export const unselectShopCSV = () => (dispatch) => {
  dispatch({
    type: UNSELECT_SHOP_CSV,
  });
};

export const uploadCSVFile = (shop_id, file, onSuccess) => (dispatch) => {
  dispatch({
    type: UPLOAD_CSV_FILE,
  });
  instance
    .post(`/platforms/shops/${shop_id}/csv`, {
      file_name: file.name,
    })
    .then((res) => {
      // ! Upload to google storage bucket
      // * Step two
      const csvFile = res.data;
      dispatch({
        type: SAVE_CSV_CONFIG,
        payload: csvFile,
      });
      if (csvFile.status === "processing") {
        axios
          .put(res.data.storage_url, file, {
            headers: { "Content-Type": "text/csv" },
          })
          .then((res) => {
            // * Everything has worked out
            // TODO: Set timeout and check status here
            checkCSVUploadStatus(shop_id, csvFile)
              .then((res) => {
                dispatch({
                  type: UPLOAD_CSV_FILE_SUCCESS,
                });
                onSuccess();
              })
              .catch((err) => {
                dispatch({
                  type: UPLOAD_CSV_FILE_FAILED,
                  payload: err,
                });
              });
          })
          .catch((e) => {
            dispatch({
              type: UPLOAD_CSV_FILE_FAILED,
              payload: [{ description: parseError(e) }],
            });
            console.error(e);
          });
      }
    })
    .catch((e) => {
      dispatch({
        type: UPLOAD_CSV_FILE_FAILED,
        payload: [{ description: parseError(e) }],
      });
      console.error(e);
    });
};

const checkCSVUploadStatus = async (shop_id, csvData) => {
  return new Promise((resolve, reject) => {
    if (!!csvData.id) {
      let status = null;
      let networkFailed = false;
      let csvErrors = [];

      const interval = setInterval(() => {
        if (status && status === "success") {
          clearInterval(interval);
          resolve();
        } else if (!status || status === "processing") {
          instance
            .get(`platforms/shops/${shop_id}/csv?search=${csvData.id}`)
            .then((res) => {
              status = res.data[0].status || null;
              if (status === "error") {
                csvErrors = res.data[0].errors;
              }
            })
            .catch((err) => {
              console.error(err);
              if (err.code === "ERR_NETWORK" || !err.response) {
                networkFailed = true;
              }
            });
        } else if (status && status === "error") {
          reject([
            { description: "There was an error parsing the CSV" },
            ...csvErrors,
          ]);
        } else {
          clearInterval(interval);
          console.error(
            "Something went wrong while checking for CSV upload status",
          );
          reject([
            {
              description:
                "Something went wrong while checking for CSV upload status",
            },
          ]);
        }
      }, 2000);
      setTimeout(() => {
        if (networkFailed) {
          clearInterval(interval);
          reject([{ description: "There was an error parsing the CSV" }]);
        }
      }, 30000);
    } else {
      reject("No valid csvData");
    }
  });
};

export const clearCSVErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_CSV_ERRORS,
  });
};

export const getDownloadOrderCSV = (id, onSuccess) => (dispatch) => {
  dispatch({
    type: GET_DOWNLOAD_ORDER_CSV,
  });
  instance
    .get(`platforms/export-payable-orders/${id}/`, { responseType: "blob" })
    .then((response) => {
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
      const filename = filenameMatch
        ? filenameMatch[1]
        : "ShipShap.order.download.csv";

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({
        type: GET_DOWNLOAD_ORDER_CSV_SUCCESS,
      });
      onSuccess();
    })
    .catch((e) => {
      dispatch({
        type: GET_DOWNLOAD_ORDER_CSV_FAILED,
      });
      console.error(e);
    });
};

export const clearCSVConfig = () => (dispatch) => {
  dispatch({
    type: CLEAR_CSV_CONFIG,
  });
};
