import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  FiFile,
  FiFilePlus,
  FiUploadCloud,
  FiCheck,
  FiTrash2,
} from "react-icons/fi";

const FileUpload = (props) => {
  const [dragActive, setDragActive] = useState(false);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    return () => {
      documents.forEach((file) => {
        if (file instanceof File && file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, []);

  useEffect(() => {
    props.onChange(documents);
  }, [documents]);

  useEffect(() => {}, [props.files]);

  const handleFile = (e) => {
    if (!e.target.files) {
      return;
    }
    const files = Array.from(e.target.files);
    if (props.validate && !props.validate(files)) {
      return;
    }

    const fileSources = [...files];

    fileSources.forEach((file) => {
      if (file instanceof File && file.type.includes("image/")) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      }
    });

    setDocuments(fileSources);

    e.target.value = "";
  };

  const handleDrag = (e) => {
    e.preventDefault();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleFile(e);
  };

  const getSizeString = (bytes) => {
    let size = bytes;
    let unit = "B";
    if (size > 1024) {
      size = (size / 1000).toFixed(1);
      unit = " KB";
    }
    if (size > 1024) {
      size = (size / 1000).toFixed(1);
      unit = " MB";
    }
    return size.toString() + unit;
  };

  const renderUpload = () => {
    return (
      <div
        className={
          "content empty d-flex flex-column justify-content-around align-items-center"
        }
      >
        <div className={"icon"}>
          <FiUploadCloud size={45} />
        </div>
        <p className={"mb-0"}>
          <span>Click to upload&nbsp;</span>or drag and drop
        </p>
      </div>
    );
  };

  const renderFileItems = (file, idx, type = "uri") => {
    if (!file) {
      return;
    }

    const isImage = props.fileType.includes("image/") && !!file?.preview;
    return (
      <div
        className={`content ${isImage ? "image" : "file"}`}
        key={`${type}-${idx}`}
      >
        <div
          className={"actions"}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            if (type === "uri") {
              props.filesRemove(idx);
            } else {
              const newDocuments = [...documents];
              if (
                newDocuments[idx] instanceof File &&
                newDocuments[idx].preview
              ) {
                URL.revokeObjectURL(newDocuments[idx].preview);
              }
              newDocuments.splice(idx, 1);
              setDocuments(newDocuments);
            }
          }}
        >
          <FiTrash2 />
        </div>
        {isImage ? <img src={file.preview} /> : <FiFile />}
        {!isImage && (
          <div>
            <div
              className={
                "col-12 p-0 d-flex flex-row align-items-center justify-content-between"
              }
            >
              {!!file.name && <p className={"doc-name"}>{file.name}</p>}
              {props.uploading ? (
                <Spinner animation={"border"} />
              ) : (
                <FiCheck className={`uploaded`} />
              )}
            </div>
            {!!file.size && (
              <p className={"doc-size"}>{getSizeString(file.size)}</p>
            )}
            {!!props.progress && (
              <div
                className={
                  "d-flex flex-row align-items-center justify-content-between"
                }
              >
                <div className={"progress-bar"}>
                  <div
                    className={`progress-bar-filler`}
                    style={{ width: `calc( 100% * ${props.progress} )` }}
                  ></div>
                </div>
                <p>{`${props.progress * 100}%`}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderFiles = () => {
    const list = [];
    if (!!props.files?.length) {
      props.files.forEach((file, idx) => {
        list.push(renderFileItems(file, idx));
      });
    } else {
      documents.forEach((item, idx) => {
        list.push(renderFileItems(item, idx, "file"));
      });
    }
    return (
      <div className={"file-items d-flex flex-row flex-wrap"}>
        {list}
        {props.multiple && renderUpload()}
      </div>
    );
  };

  return (
    <label
      htmlFor={"file"}
      className={`file-upload ${dragActive ? "active" : ""} ${props.error ? "error " : ""}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        id={"file"}
        name={"file"}
        className={`${props.error ? "is-invalid" : ""}`}
        type={"file"}
        accept={props.fileType}
        onChange={handleFile}
        multiple={props.multiple}
      />
      {dragActive ? (
        <div
          className={
            "content drag d-flex flex-column justify-content-around align-items-center"
          }
        >
          <div className={"icon"}>
            <FiFilePlus size={45} />
          </div>
          <p className={"mb-0"}>
            <span>Drop file to upload</span>
          </p>
        </div>
      ) : !!documents?.length || !!props.files?.length ? (
        renderFiles()
      ) : (
        renderUpload()
      )}
    </label>
  );
};

export default FileUpload;
