import React, { Component } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import {
  editCustomsItems,
  selectCustomsItem,
  updateItems,
} from "../../actions";
import CustomsItemFormItem from "./CustomsItemFormItem";
import COUNTRYLIST from "../../../assets/country-list.json";
import UNITS from "../../../assets/mass-units.json";
import { FiX } from "react-icons/fi";
import CONVERSION_FACTORS from "../../../assets/weight-conversion-factors.json";
import CURRENCY_UNITS from "../../../assets/currency-units.json";
import { PHONEBOOK } from "../../../constants/types";
import getCountryFlag from "country-flag-icons/unicode";
import { getTotalWeightInUnit } from "../common/helpers";
class CustomsItemForm extends Component {
  state = {
    item: {
      description: "",
      quantity: "",
      net_weight: "",
      weight_unit: "kg",
      value_amount: "",
      value_currency: "USD",
      origin_country: "",
      tariff_number: null,
    },
    quantityTypeError: false,
    net_weightValueError: false,
    net_weightTypeError: false,
    value_amountTypeError: false,
    descriptionError: false,
    net_weightError: false,
    quantityError: false,
    value_amountError: false,
    value_currencyError: false,
    origin_countryError: false,
    tariff_numberError: false,
    country: "",
    errors: false,
  };

  componentDidMount = () => {
    if (!this.props.selectedOrder) {
      this.updateState("origin_country", this.props.country);
    }
    if (this.props.itemIdx !== -1) {
      const item = this.props.customs.items[this.props.itemIdx];
      this.setState({ item: { ...item } });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.item.origin_country !== prevState.item.origin_country) {
      this.setState({
        country:
          COUNTRYLIST[this.state.item.origin_country]?.name ||
          this.state.country ||
          "",
      });
    }
  }

  closeForm = async () => {
    await this.props.selectCustomsItem(-1);
    await this.props.toggleForm();
  };

  updateState = (field, value) => {
    if (this.state.errors) {
      this.setState({ errors: false });
    }

    let state = {
      item: {
        ...this.state.item,
        [field]: value,
      },
    };

    if (this.state.hasOwnProperty([`${field}Error`])) {
      state[`${field}Error`] = false;
    }
    if (this.state.hasOwnProperty([`${field}TypeError`])) {
      state[`${field}TypeError`] = false;
    }
    if (this.state.hasOwnProperty([`${field}ValueError`])) {
      state[`${field}ValueError`] = false;
    }

    this.setState({
      ...state,
    });
  };

  requiredFieldsCheck = (item, fieldsArr) => {
    let valid = true;
    fieldsArr.forEach((field) => {
      if (!item[field] || item[field] === "") {
        this.setState({ [`${field}Error`]: true });
        valid = false;
      }
    });

    return valid;
  };
  numberFieldsCheck = (item, fieldsArr) => {
    let valid = true;
    fieldsArr.forEach((field) => {
      if (item[field] && isNaN(item[field])) {
        this.setState({ [`${field}TypeError`]: true });
        valid = false;
      }
    });
    return valid;
  };

  checkItemWeight = (quantity, itemWeight, parcelWeight) => {
    let totalItemWeight = quantity * itemWeight;
    return totalItemWeight > parcelWeight;
  };

  saveCustomItems = () => {
    let valid = true;
    const validatedItem = { ...this.state.item };
    let numberFields = ["quantity", "net_weight", "value_amount"];
    let requiredFields = [
      "description",
      "quantity",
      "net_weight",
      "value_amount",
      "value_currency",
      "origin_country",
    ];

    valid = this.requiredFieldsCheck(validatedItem, requiredFields) && valid;
    valid = this.numberFieldsCheck(validatedItem, numberFields) && valid;

    if (
      valid &&
      this.checkItemWeight(
        parseFloat(validatedItem.quantity),
        parseFloat(validatedItem.net_weight),
        parseFloat(
          getTotalWeightInUnit(this.props.parcels, validatedItem.weight_unit),
        ),
      )
    ) {
      this.setState({ net_weightValueError: true });
      valid = false;
    }

    if (valid) {
      if (this.props.itemIdx !== -1) {
        this.setState({ errors: false });
        this.props.editCustomsItems(validatedItem, this.props.itemIdx);
        this.closeForm();
      } else {
        this.setState({ errors: false });
        this.props.updateItems(validatedItem);
        this.closeForm();
      }
    } else {
      this.setState({ errors: true });
    }
  };

  renderCurrency = (selectedItem) => {
    const CURRENCY = CURRENCY_UNITS;

    const list = Object.keys(CURRENCY).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => {
            this.updateState("value_currency", item);
          }}
        >
          {CURRENCY[item].description}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown className="col-12 p-0">
        <Dropdown.Toggle className="col-12 p-0 d-flex justify-content-between align-items-center px-3">
          {CURRENCY[selectedItem.value_currency].description}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  renderCountryList = (country) => {
    const list = Object.keys(COUNTRYLIST).filter((item) => {
      return (
        item.toLowerCase().includes(country?.toString().toLowerCase()) ||
        COUNTRYLIST[item]?.name
          ?.toString()
          .toLowerCase()
          .includes(country?.toString().toLowerCase())
      );
    });
    if (list.length > 0)
      return list.map((item, idx) => {
        return (
          <a
            key={item}
            onClick={(e) => {
              this.updateState("origin_country", item);
            }}
            onKeyDown={(e) => {
              this.updateState("origin_country", item);
            }}
          >{`${getCountryFlag(item)} ${COUNTRYLIST[item]?.name}`}</a>
        );
      });
    return <p></p>;
  };

  renderWeight = (selectedItem) => {
    const list = Object.keys(UNITS).map((item, idx) => {
      return (
        <Dropdown.Item
          onClick={() => {
            this.updateState("weight_unit", item);
          }}
          key={idx}
        >
          {UNITS[item]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown className="col-12 p-0">
        <Dropdown.Toggle className="col-12 p-0 d-flex justify-content-between align-items-center px-3">
          <div className="d-flex flex-row">
            <p className="d-none d-md-flex m-0">
              {UNITS[selectedItem.weight_unit]}
            </p>
            <p className="d-flex d-md-none m-0">{selectedItem.weight_unit}</p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  renderFormItem = () => {
    const { item } = this.state;

    return (
      <div className={"form-content"}>
        <label className="d-flex flex-column">
          Description
          <input
            value={item.description}
            onChange={(e) => this.updateState("description", e.target.value)}
          />
          <p
            className={`text-danger ${this.state.descriptionError ? "error" : ""}`}
          >
            Field is required
          </p>
        </label>
        <div className="quantity d-flex flex-row justify-content-between">
          <label className="d-flex flex-column p-0">
            Quantity
            <input
              type={"number"}
              onWheel={(e) => {
                e.target.blur();
              }}
              onScroll={(e) => {
                e.target.blur();
              }}
              min={0}
              value={item.quantity}
              onChange={(e) => this.updateState("quantity", e.target.value)}
            />
            <p
              className={`text-danger ${this.state.quantityError ? "error" : ""}`}
            >
              Field is required
            </p>
            <p
              className={`text-danger ${this.state.quantityTypeError ? "error" : ""}`}
            >
              Field must be number
            </p>
          </label>
          <label className="d-flex flex-column  p-0">
            Net Weight{" "}
            <input
              type={"number"}
              onWheel={(e) => {
                e.target.blur();
              }}
              onScroll={(e) => {
                e.target.blur();
              }}
              min={0}
              value={item.net_weight}
              onChange={(e) => this.updateState("net_weight", e.target.value)}
            />
            <p
              className={`text-danger ${this.state.net_weightError ? "error" : ""}`}
            >
              Field is required
            </p>
            <p
              className={`text-danger ${this.state.net_weightTypeError ? "error" : ""}`}
            >
              Field must be number
            </p>
            <p
              className={`text-danger ${this.state.net_weightValueError ? "error" : ""}`}
            >{`Net weight cannot be greater than parcel weight`}</p>
            <p>
              <span>Quantity * Weight per item</span>
            </p>
          </label>
          <label className="d-flex flex-column p-0">
            &nbsp;{this.renderWeight(item)}
          </label>
        </div>
        <div className="value col-12 p-0 d-flex flex-row justify-content-between">
          <label className="d-flex flex-column">
            Value Currency{this.renderCurrency(item)}
            <p
              className={`text-danger ${this.state.value_currencyError ? "error" : ""}`}
            >
              Field is required
            </p>
          </label>
          <label className="d-flex flex-column">
            Value Amount
            <input
              type={"number"}
              onWheel={(e) => {
                e.target.blur();
              }}
              onScroll={(e) => {
                e.target.blur();
              }}
              min={0}
              value={item.value_amount}
              onChange={(e) => this.updateState("value_amount", e.target.value)}
            />
            <p
              className={`text-danger ${this.state.value_amountError ? "error" : ""}`}
            >
              Field is required
            </p>
            <p
              className={`text-danger ${this.state.value_amountTypeError ? "error" : ""}`}
            >
              Field must be number
            </p>
          </label>
        </div>
        <label className="origin-country d-flex flex-column">
          Origin Country
          <input
            type={"text"}
            value={
              this.state.country ||
              COUNTRYLIST[this.state.item.origin_country]?.name ||
              ""
            }
            onChange={(e) => {
              this.setState({ origin_countryError: false });
              if (e.target.value.trim() === "")
                this.updateState("origin_country", "");
              this.setState({ country: e.target.value });
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              setTimeout(() => {
                let list = Object.keys(COUNTRYLIST).filter((item) => {
                  return item
                    .toLowerCase()
                    .includes(e.target.value?.toString().toLowerCase());
                });
                if (list.length === 1) {
                  this.setState({
                    country: COUNTRYLIST[list[0]]?.name,
                  });
                  this.updateState("origin_country", list[0]);
                  return;
                }
                list = Object.keys(COUNTRYLIST).filter((item) => {
                  return COUNTRYLIST[item]?.name
                    ?.toString()
                    .toLowerCase()
                    .includes(e.target.value?.toString().toLowerCase());
                });
                if (list.length === 1) {
                  this.setState({
                    country: COUNTRYLIST[list[0]]?.name,
                  });
                  this.updateState("origin_country", list[0]);
                } else if (!this.state.item.origin_country?.toString().trim()) {
                  this.setState({
                    country: COUNTRYLIST[this.props.country]?.name,
                  });
                  this.updateState("origin_country", this.props.country);
                } else {
                  this.setState({
                    country: COUNTRYLIST[this.state.item.origin_country]?.name,
                  });
                }
              }, 200);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
          {this.state.country && (
            <div className={"search-results"}>
              {this.renderCountryList(this.state.country)}
            </div>
          )}
          <p
            className={`text-danger ${this.state.origin_countryError ? "error" : ""}`}
          >
            Field is required
          </p>
        </label>
        <label className="d-flex flex-column">
          Tariff Number
          <input
            type={"number"}
            onWheel={(e) => {
              e.target.blur();
            }}
            onScroll={(e) => {
              e.target.blur();
            }}
            min={0}
            value={item.tariff_number || ""}
            onChange={(e) => this.updateState("tariff_number", e.target.value)}
          />
          <p
            className={`text-danger ${this.state.tariff_numberError ? "error" : ""}`}
          >
            Field is required
          </p>
        </label>
      </div>
    );
  };

  render() {
    const modal = this.props.show && (
      <div
        className={`modal d-flex justify-content-center justify-content-lg-start aligin-items-center ${this.props.show ? "show" : ""}`}
      >
        <div
          onClick={() => this.closeForm()}
          onKeyDown={() => {}}
          className="backdrop"
        ></div>
        <Card className="modal-content settings-card customs-items d-block col-11 col-lg-7 m-auto">
          <div className="card-section head col-12 d-flex flex-row justify-content-between">
            <h5>Customs Items</h5>
            <a
              className="d-flex "
              onClick={() => this.closeForm()}
              onKeyDown={() => this.closeForm()}
            >
              <FiX />
            </a>
          </div>
          {/*{this.state.errors && <p className="text-danger">There are errors in the form</p>}*/}
          <div className="customs-item-form col-12">
            {this.renderFormItem()}
            <div className="col-12 p-0 d-flex">
              <p className="text-danger"></p>
              <button
                className="col-12 p-0 ml-auto"
                onClick={() => this.saveCustomItems()}
              >
                Add
              </button>
            </div>
          </div>
        </Card>
      </div>
    );

    return modal;
  }
}
const mapStatetoProps = ({ customs, parcels, stores, applicationContext }) => ({
  customs,
  itemIdx: customs.selectedItem,
  parcels,
  country: applicationContext.context.country,
  order: stores.selectedOrder,
});

export default connect(mapStatetoProps, {
  updateItems,
  editCustomsItems,
  selectCustomsItem,
})(CustomsItemForm);
