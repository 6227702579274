import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import "../scss/secretSantaLanding.scss";
import introArt from "../assets/GiftExchange.png";
import masthead from "../assets/gift-exchange/corporate-masthead.png";
import masthead_mob from "../assets/gift-exchange/corporate-masthead-mob.png";
import sec2_img1 from "../assets/gift-exchange/section2-img1.png";
import sec2_img2 from "../assets/gift-exchange/section2-img2.png";
import sec2_img3 from "../assets/gift-exchange/section2-img3.png";
import sec2_img1_mob from "../assets/gift-exchange/section2-img1-mob.png";
import sec2_img2_mob from "../assets/gift-exchange/section2-img2-mob.png";
import sec2_img3_mob from "../assets/gift-exchange/section2-img3-mob.png";
import how_it_works_1 from "../assets/gift-exchange/how-it-works-1.png";
import how_it_works_1_mob from "../assets/gift-exchange/how-it-works-1-mob.png";
import how_it_works_2 from "../assets/gift-exchange/how-it-works-2.png";
import how_it_works_2_mob from "../assets/gift-exchange/how-it-works-2-mob.png";
import how_it_works_3 from "../assets/gift-exchange/how-it-works-3.png";
import how_it_works_3_mob from "../assets/gift-exchange/how-it-works-3-mob.png";
import how_it_works_4 from "../assets/gift-exchange/how-it-works-4.png";
import how_it_works_4_mob from "../assets/gift-exchange/how-it-works-4-mob.png";
import how_it_works_5 from "../assets/gift-exchange/how-it-works-5.png";
import how_it_works_5_mob from "../assets/gift-exchange/how-it-works-5-mob.png";
import scribble_green from "../assets/gift-exchange/scribble-green.svg";
import scribble_white from "../assets/gift-exchange/scribble-white.svg";
import section4_img from "../assets/gift-exchange/section4-img.png";
import section4_img_mob from "../assets/gift-exchange/section4-img-mob.png";
import plus_ico from "../assets/plus.svg";
import minus_ico from "../assets/minus.svg";
import call_to_action from "../assets/gift-exchange/call-to-action.png";
import call_to_action_mob from "../assets/gift-exchange/call-to-action-mob.png";
import gDown from "../assets/google-play-badge.png";
import aDown from "../assets/appstore-badge.png";
import { DownloadAppLink } from "./DownloadAppButton";
import { DownloadAppButton } from "./DownloadAppButton";
import { Spinner } from "react-bootstrap";

const SecretSanta = (props) => {
  const [invited, setInvited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copyText, setCopyText] = useState("Invite Code:");
  const [inviteCode, setInviteCode] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const [error, setError] = useState("");

  const { params } = props.match;

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      setInvited(true);
      const httpsCallable = firebase
        .functions()
        .httpsCallable("get_exchange_details");
      setLoading(true);
      httpsCallable({ exchange_id: props.match.params.exchangeId })
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (response.data.errors) {
            setError(response.data.errors);
          } else {
            setInviteCode(response.data.exchange.invite_code);
            setInviteLink(response.data.exchange.invite_link);
          }
        })
        .catch((httpsError) => {
          setLoading(false);
          console.error(
            "There was an error getting the gift-exchange: ",
            httpsError,
          );
        });
    }
  }, [params]);

  const checkPlatform = () => {
    const OS = navigator.platform;
    if (OS === "Android") {
      return "https://play.google.com/store/apps/details?id=com.shipshap&hl=en&gl=US";
    } else {
      return "https://apps.apple.com/us/app/shipshap/id1483246532";
    }
  };

  const downBtn = checkPlatform();
  return (
    <div className="santaPage">
      <section className={"section-1 d-flex flex-column flex-lg-row"}>
        <div
          className={
            "content col-12 col-lg-5 m-0 ml-lg-auto d-flex flex-column justify-content-center "
          }
        >
          <h1>The leading virtual gift exchange platform</h1>

          <p>
            We take care of the details, with the most advanced and
            comprehensive app on the market, so you can enjoy connecting with
            your colleagues.
          </p>

          <div className={"p-0 d-flex flex-column"}>
            <DownloadAppLink
              styling={"d-flex justify-content-center align-items-center mb-4"}
            />
            <div className={"d-flex flex-row align-items-center"}>
              <img src={scribble_green} className={"mr-2"} />
              <p className={"p-0 m-0"}>
                create your free account in under a minute
              </p>
            </div>
          </div>
        </div>
        <div className={"image col-12 col-lg-6 p-0 d-flex"}>
          <img src={masthead} className={"d-none d-lg-flex w-100"} />
          <img src={masthead_mob} className={"d-flex d-lg-none w-100"} />
        </div>
      </section>
      <section className={"section-2 "}>
        <img src={scribble_white} className={"scribble-bg d-none d-lg-flex"} />
        <div
          className={
            "col-12 col-lg-10 d-flex m-auto flex-column justify-content-center"
          }
        >
          <h1 className={"p-0 mt-5 mb-5"}>
            Fostering connection
            <br />
            across distances
          </h1>
          <div className={"list d-flex flex-column flex-lg-row mt-5 mb-5"}>
            <div className={"col-12 col-lg-4"}>
              <div className={"container col-12 p-0"}>
                <img src={sec2_img1} className={"d-none d-lg-flex w-75"} />
                <img src={sec2_img1_mob} className={"d-flex d-lg-none w-75"} />
                <h4>
                  Personalized
                  <br />
                  Gifting
                </h4>
              </div>
              <p>
                We encourage fostering community with gifts from the heart.
                Packaging by hand is made simple and easy by our app.{" "}
              </p>
            </div>
            <div className={"col-12 col-lg-4"}>
              <div className={"container col-12 p-0"}>
                <img src={sec2_img2} className={"d-none d-lg-flex w-75"} />
                <img src={sec2_img2_mob} className={"d-flex d-lg-none w-75"} />
                <h4>
                  The Best <br />
                  Shipping Rates
                </h4>
              </div>
              <p>
                We’ve partnered with the biggest Shipping companies to bring you
                the cheapest options.
              </p>
            </div>
            <div className={"col-12 col-lg-4"}>
              <div className={"container col-12 p-0"}>
                <img src={sec2_img3} className={"d-none d-lg-flex w-75"} />
                <img src={sec2_img3_mob} className={"d-flex d-lg-none w-75"} />
                <h4>
                  Management <br />
                  made simple
                </h4>
              </div>
              <p>
                Organizing and managing a virtual office-wide gift exchange has
                never been so easy: you’ll be guided through every step of the
                process, from matching to delivery, and have detailed tracking
                for the entire group.
              </p>
            </div>
          </div>
          <a href={checkPlatform()} className={"m-auto mt-5 mb-5"}>
            Get Started
          </a>
        </div>
      </section>
      <section className={"section-3 flex-column"}>
        <img
          src={scribble_white}
          className={"scribble-bg top d-none d-lg-flex"}
        />
        <div className={"scribbles m-auto d-none d-lg-flex flex-column"}>
          <img
            src={scribble_white}
            className={"scribble-bg d-none d-lg-flex"}
          />
          <img
            src={scribble_white}
            className={"scribble-bg d-none d-lg-flex"}
          />
          <img
            src={scribble_white}
            className={"scribble-bg d-none d-lg-flex"}
          />
          <img
            src={scribble_white}
            className={"scribble-bg d-none d-lg-flex"}
          />
        </div>
        <div className={"col-12 col-lg-11 m-0 ml-lg-auto"}>
          <h1>How it works</h1>
          <p>
            A simple and easy step by step guided experience. <br />
            We’ll hold your hand through the entire logistical set-up, until the
            final package delivery.
          </p>
        </div>
        <div className={"item d-flex flex-column flex-lg-row"}>
          <div
            className={
              "col-12 col-lg-4 m-0 m-lg-auto d-flex flex-column justify-content-center"
            }
          >
            <h3>1.Create and Manage groups: </h3>
            <p>
              Easily create new groups and invite friends to join, enter their
              address and gift preferences. Keep the exchange anonymous with our
              default address feature, extending the excitement to the very end.
            </p>
          </div>
          <div className={"image col-12 col-lg-6 d-flex p-0"}>
            <img
              src={how_it_works_1}
              className={"d-none d-lg-flex w-100 pr-5"}
            />
            <img
              src={how_it_works_1_mob}
              className={"col-12 col-lg-10 p-0 d-flex d-lg-none w-100 "}
            />
          </div>
        </div>
        <div className={"item d-flex flex-column flex-lg-row "}>
          <div
            className={
              "col-12 col-lg-4 m-0 m-lg-auto d-flex flex-column justify-content-center order-1 order-lg-2"
            }
          >
            <h3>2.Get the right match: </h3>
            <p>
              Tell us who you don’t want to match with who, and we’ll randomly
              assign everyone else to a recipient.
            </p>
          </div>
          <div
            className={"image col-12 col-lg-6 d-flex p-0 order-2 order-lg-1"}
          >
            <img
              src={how_it_works_2}
              className={"d-none d-lg-flex w-100 pl-5"}
            />
            <img
              src={how_it_works_2_mob}
              className={"col-12 col-lg-10 p-0 d-flex d-lg-none w-100 "}
            />
          </div>
        </div>
        <div className={"item d-flex flex-column flex-lg-row"}>
          <div
            className={
              "col-12 col-lg-4 m-0 m-lg-auto d-flex flex-column justify-content-center"
            }
          >
            <h3>3.Personal Touch: </h3>
            <p>
              Each member of the exchange is guided through their own packaging,
              labelling, and shipping process. Everyone’s package is done by
              hand, building community and connection.
            </p>
          </div>
          <div className={"image col-12 col-lg-6 d-flex p-0"}>
            <img
              src={how_it_works_3}
              className={"d-none d-lg-flex w-100 pr-5"}
            />
            <img
              src={how_it_works_3_mob}
              className={"col-12 col-lg-10 p-0 d-flex d-lg-none w-100 "}
            />
          </div>
        </div>
        <div className={"item d-flex flex-column flex-lg-row "}>
          <div
            className={
              "col-12 col-lg-4 m-0 m-lg-auto d-flex flex-column justify-content-center order-1 order-lg-2"
            }
          >
            <h3>4.Customized and competitive shipping rates: </h3>
            <p>
              Exclusive access to the best rates, allowing you to easily select
              the best option for you. Who said you can’t have fun and save
              money.{" "}
            </p>
          </div>
          <div
            className={"image col-12 col-lg-6 d-flex p-0 order-2 order-lg-1"}
          >
            <img
              src={how_it_works_4}
              className={"d-none d-lg-flex w-100 pl-5"}
            />
            <img
              src={how_it_works_4_mob}
              className={"col-12 col-lg-10 p-0 d-flex d-lg-none w-100 "}
            />
          </div>
        </div>
        <div className={"item d-flex flex-column flex-lg-row"}>
          <div
            className={
              "col-12 col-lg-4 m-0 m-lg-auto d-flex flex-column justify-content-center"
            }
          >
            <h3>5.Manage the entire exchange: </h3>
            <p>
              Use our integrated tracking page to see who has shipped, and who
              may need a reminder. Plus, know that nothing is lost with
              individual tracking on each package.
            </p>
          </div>
          <div className={"image col-12 col-lg-6 d-flex p-0"}>
            <img
              src={how_it_works_5}
              className={"d-none d-lg-flex w-100 pr-5"}
            />
            <img
              src={how_it_works_5_mob}
              className={"col-12 col-lg-10 p-0 d-flex d-lg-none w-100 "}
            />
          </div>
        </div>
      </section>
      <section className={"section-4 "}>
        <div
          className={
            "container col-12 col-xl-11 m-0 ml-xl-auto d-flex flex-column-reverse flex-xl-row align-items-end justify-content-between"
          }
        >
          <h1 className={""}>
            Spreading <br className={"d-flex d-xl-none"} /> love{" "}
            <br className={"d-none d-xl-flex"} />
            across <br className={"d-flex d-xl-none"} />
            oceans
          </h1>
          <div
            className={
              "content col-12 col-xl-5 h-100 pt-5 pr-xl-5 d-flex flex-column"
            }
          >
            <p>
              Our mission is to bring families, friends, and colleagues together
              across distances, and help them express their love through gift
              giving. We know the logistics aren’t always easy, and can
              sometimes stop people from following through. So, we’re here to
              take care of all those details, allowing you to have fun and
              connect with the people around you.
            </p>
            <p>
              Gift Exchange was founded by Eyoel, who knows firsthand the
              challenges of trying to connect with friends and family who live
              across the world. Moving to the United States, and having family
              in Ethiopia, Eyoel realized how much logistical work and
              unnecessary cost goes into exchanging gifts across cities and
              oceans. The pandemic amplified the need to connect people who
              could not share space together. Gift Exchange is here to bring a
              little play back into your virtual exchanges, by taking the
              logistical burden off your back so you can just enjoy connecting
              and sharing with the people you love.
            </p>
          </div>
          <div className={"col-12 col-xl-7 p-0 pl-xl-5"}>
            <img src={section4_img} className={"d-none d-xl-flex w-100"} />
            <img src={section4_img_mob} className={"d-flex d-xl-none w-100"} />
          </div>
        </div>
      </section>
      <section className={"section-5 "}>
        <div className={"col-12 col-lg-6 m-auto d-flex flex-column "}>
          <h1 id="faq" className={"m-auto"}>
            FAQ
          </h1>
          <div className="accordion" id="accordionExample">
            <div className="">
              <div className="" id="headingOne">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p>Do I have to pay for the app?</p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse pr-4"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <p className="answer">No, the app is free to download!</p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingTwo">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <p>Are there any in app purchases?</p>

                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseTwo"
                className="collapse pr-4"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  There are no in-app purchases. You pay for your shipping
                  label, and that’s it.
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingThree">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    <p>Where do I get the packaging and stamp for my gift?</p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseThree"
                className="collapse pr-4"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  You can get boxes & Priority Mail envelopes from USPS, UPS, or
                  your local shipping center for FREE. You can also recycle any
                  box or suitable packaging you already have, and purchase the
                  stamp when you ship the package.
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingFour">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    <p>How do I print the label if I don’t have a printer?</p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseFour"
                className="collapse pr-4"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  If you don’t have a printer, no worries. You can simply go to
                  your local shipping location (examples), and at the same time
                  that you send the package off, print out the pre-made label
                  that we produce for you. You can also go to any local printing
                  store and do it there!
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingFive">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    <p>Can my Gift Exchange have an odd number of people?</p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseFive"
                className="collapse pr-4"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  Yes! As long as there are 2 or more people, everyone will be
                  able to play.
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingSix">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    <p>
                      Can children participate? How do I manage their account
                      and wishlist?
                    </p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseSix"
                className="collapse pr-4"
                aria-labelledby="headingSix"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  Children can play, but an adult will need to manage their
                  account. You can simply create their account, and manage their
                  profile through the app
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingSeven">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    <p>
                      Does everyone involved need to have the Gift Exchange app?
                    </p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseSeven"
                className="collapse pr-4"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  Yes, because they’ll need to enter their address information
                  (available for android and apple products).
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingEight">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="true"
                    aria-controls="collapseEight"
                  >
                    <p>Can I change who the manager of the group is?</p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseEight"
                className="collapse pr-4"
                aria-labelledby="headingEight"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  You can make other people managers as well, but once you
                  create an exchange - you’ll always be a manager. You can
                  easily create a new exchange if you wish to make changes, or
                  are unhappy with the setup you created.
                </p>
              </div>
            </div>
            <div className="">
              <div className="" id="headingNine">
                <h5 className="mb-0">
                  <div
                    className="col-12 p-0 d-flex flex-row justify-content-between align-items-center"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="true"
                    aria-controls="collapseNine"
                  >
                    <p>
                      Does every member of the exchange have access to the
                      management tracking features?
                    </p>
                    <img src={plus_ico} className={"inactive"} />
                    <img src={minus_ico} className={"active"} />
                  </div>
                </h5>
              </div>

              <div
                id="collapseNine"
                className="collapse pr-4"
                aria-labelledby="headingNine"
                data-parent="#accordionExample"
              >
                <p className="answer">
                  Everyone in the exchange can see who is tracking updates on
                  who’s gifts are on the way. Only admins who aren’t
                  participating can see who’s matched with who.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className={
          "section-6 d-flex flex-column flex-lg-row align-items-center"
        }
      >
        <img src={scribble_white} className={"scribble-bg d-none d-lg-flex"} />
        <div className={"content col-12 col-lg-5 m-0 ml-lg-auto"}>
          <h1>Download the app for Free!</h1>
          <p>
            This holiday season, focus on the intangibles and let us take care
            of the logistics.{" "}
          </p>
          <div className={"links d-flex flex-row col-9 p-0"}>
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap&hl=en&gl=US"
              className=" col-6 m-2"
            >
              <img
                src={gDown}
                alt="Play Store Download"
                className="img-fluid w-100"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532"
              className="col-6 m-2"
            >
              <img
                src={aDown}
                alt="App Store Download"
                className="img-fluid w-100"
              />
            </a>
          </div>
        </div>
        <div className={"col-12 col-lg-6 p-0"}>
          <img src={call_to_action} className={"d-none d-lg-flex w-100"} />
          <img src={call_to_action_mob} className={"d-flex d-lg-none w-100"} />
        </div>
      </section>
    </div>
  );
};

export default SecretSanta;
