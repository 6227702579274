import React, { Component, useEffect, useState } from "react";
import image from "../assets/greenpatch.png";
import { Link } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import { SignInLink } from "./SignIn";
import { connect } from "react-redux";
import {
  clearPasswordReset,
  createUser,
  emailChanged,
  googleLogin,
  passwordChanged,
} from "./actions/AuthActions";

import { ReactComponent as BoxLogo } from "../assets/box-in-logo-green.svg";
import { ReactComponent as BoxLogoWhite } from "../assets/box-in-logo.svg";
import { ReactComponent as GlobeVisual } from "../assets/auth-globe.svg";
import { ReactComponent as GoogleLogo } from "../assets/google-logo.svg";
import { Spinner } from "react-bootstrap";
import { auth } from "../services/firebase";
import history from "../History";
import { FiAlertCircle, FiEye, FiEyeOff, FiCheck } from "react-icons/fi";

const SignUpPage = (props) => {
  const [error, setError] = useState("");

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        history.replace(ROUTES.HOME);
        return;
      }
    });
  }, []);

  return (
    <div className="auth signup d-flex flex-row">
      <div
        className={
          "col-12 col-lg-5 d-none d-lg-flex flex-column align-items-center"
        }
      >
        <Link
          className="header d-flex flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogo />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>

        <div className={"intro d-flex flex-column align-items-center"}>
          <div className={"visual d-flex flex-row align-items-center"}>
            <GlobeVisual />
          </div>

          <div className={"text-center"}>
            <p className={"info"}>
              We connect you with the best shipping rates and streamline the
              order fulfillment process for you, from importing your orders, to
              printing a label, to tracking: all from the convenience of your
              device. Shipping from Africa and beyond has never been easier
            </p>
          </div>
        </div>
      </div>
      <div className="patch col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-left justify-content-around">
        <Link
          className="header d-flex d-lg-none flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogoWhite />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>
        <div className="box container  col-10 col-lg-6 d-flex flex-column justify-content-center align-items-start text-left">
          <h3 className="signlabel text-center ">Sign up</h3>
          {!error && props.error && props.error !== "" && (
            <div className={"error-block"}>
              <FiAlertCircle />
              {props.error}
            </div>
          )}
          {error && (
            <div className={"error-block"}>
              <FiAlertCircle />
              {error}
            </div>
          )}
          <SignUpForm showError={(error) => setError(error)} />

          <p className="divider">
            <span className="text">or</span>
          </p>
          <SignInWithGoogleButton gIcon="googleImg" />
          <div
            className={
              "switchForm d-flex flex-row justify-content-center align-items-center"
            }
          >
            <p className={"mb-0 "}>
              Don't have an account?
              <Link to={ROUTES.SIGN_IN} className={"ml-3"}>
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  passwordTwo: "",
  error: "",
  showPassword: false,
  accepted: false,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.props;

    if (password !== this.state.passwordTwo) {
      this.props.showError("Passwords do not match");
      return;
    }

    this.props.createUser({ email, password: password });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, error: "" });
    this.props.showError("");
  };

  render() {
    const { email, password, error } = this.props;
    const { passwordTwo } = this.state;
    const isInvalid =
      password === "" ||
      passwordTwo === "" ||
      email === "" ||
      !this.state.accepted;

    return (
      <form onSubmit={this.onSubmit}>
        <label htmlFor="email" className={"col-12 p-0 d-flex flex-column"}>
          Email
        </label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(event) => this.props.emailChanged(event.target.value)}
          placeholder="user@gmail.com"
        />
        <label htmlFor="password" className={"col-12 p-0 d-flex flex-column"}>
          Password
        </label>
        <div className={"password-field"}>
          <input
            type={this.state.showPassword ? "text" : "password"}
            name="passwordOne"
            value={password}
            onChange={(event) => this.props.passwordChanged(event.target.value)}
            placeholder="Password"
          />
          <div
            className={"show-pass-button"}
            onClick={() => {
              this.setState({ showPassword: !this.state.showPassword });
            }}
          >
            {this.state.showPassword ? <FiEye /> : <FiEyeOff />}
          </div>
        </div>
        <label
          htmlFor="passwordTwo"
          className={"col-12 p-0 d-flex flex-column"}
        >
          Confirm Password
        </label>
        <div className={"password-field"}>
          <input
            type={this.state.showPassword ? "text" : "password"}
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            placeholder="Retype your password"
          />
        </div>

        <div
          className={`checkbox d-flex flex-row justify-content-start ${this.state.accepted ? "active" : ""}`}
          onClick={() => this.setState({ accepted: !this.state.accepted })}
        >
          <div className={`check`}>
            <FiCheck />
          </div>
          <p className={"m-0"}>
            By creating an account, you agree to our &nbsp;{" "}
            <Link to={ROUTES.TERMS}>Terms and Conditions</Link>
          </p>
        </div>

        <button className="btn1" disabled={isInvalid} type="submit">
          {this.props.loading ? (
            <Spinner animation="border" role="status" className={"mt-0"} />
          ) : (
            "Create Account"
          )}
        </button>
      </form>
    );
  }
}

const SignUpButton = () => (
  <Link to={ROUTES.SIGN_UP}>
    <button className="btn2">Create Account</button>
  </Link>
);

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
  password: auth.password,
  error: auth.error,
  loading: auth.loading,
});
// const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);
const SignUpForm = connect(mapStateToProps, {
  createUser,
  emailChanged,
  passwordChanged,
})(SignUpFormBase);

const SignInWithGoogleButtonBase = (props) => (
  <button
    className="googlesignin"
    onClick={() => {
      props.googleLogin();
    }}
  >
    <GoogleLogo className={"mr-3"} />
    Sign in with Google
  </button>
);

const SignInWithGoogleButton = connect(null, { googleLogin })(
  SignInWithGoogleButtonBase,
);

const mapSignInPageStateToProps = ({ auth }) => ({
  error: auth.error,
});
export default connect(mapSignInPageStateToProps, null)(SignUpPage);

export { SignUpButton, SignUpForm, SignInWithGoogleButton };
