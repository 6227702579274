import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  clearSelectedCheckout,
  getCartConfigurations,
  selectCheckoutConfig,
} from "../../actions/CheckoutsActions";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import CheckoutsDetail from "./CheckoutsDetail";
import { FiPlus, FiInfo, FiX } from "react-icons/fi";
import "../../../scss/checkouts.scss";
import history from "../../../History";
import { Spinner } from "react-bootstrap";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";
import Popup from "../common/Popup";
import {
  ETSY,
  FIREBASE_AUTH,
  MANUAL,
  SESSION_AUTH,
  SHOPIFY,
  WOOCOMMERCE,
} from "../../../constants/types";
import shopify from "../../../assets/shopify-logo-icon.svg";
import woo_commerce from "../../../assets/woo-commerce-logo-icon.svg";
import etsy from "../../../assets/etsy-logo.svg";
import localStoreIcon from "../../../assets/store-icon.svg";
import { auth } from "../../../services/firebase";
import { disconnectStore, updateStoreName } from "../../actions";

export const CheckoutItem = (props) => {
  const {
    id,
    name_to_show_customers: name,
    shop_logo: logo,
    shop_platform: platform,
    url,
  } = props.item;

  const getIcon = (platform, logo) => {
    switch (platform) {
      case SHOPIFY:
        return <img src={shopify} alt={"shopify-logo"} />;
      case WOOCOMMERCE:
        return <img src={woo_commerce} alt={"woocommerce-logo"} />;
      case ETSY:
        return <img src={etsy} alt={"etsy-logo"} />;
      case MANUAL:
        return <img src={logo ? logo : localStoreIcon} alt={name} />;
      default:
        return <img src={localStoreIcon} alt={"store"} />;
    }
  };

  const getName = (url, platform, shopName) => {
    switch (platform) {
      case SHOPIFY:
        return url ? url.substring(0, url.indexOf(".")) : "Shopify";
      case WOOCOMMERCE:
        return url
          ? url.substring(url.split("/", 2).join("/").length + 1, url.length)
          : "WooCommerce";
      case ETSY:
        return url
          ? url.substring(url.split("/", 2).join("/").length + 1, url.length)
          : "Etsy";
      default:
        return props.connect ? "ShipShap Store" : shopName || "Own Store";
    }
  };

  const getAction = (platform) => {
    switch (platform) {
      case MANUAL:
        return !props.connect ? (
          <button
            onClick={() => {
              props.selectItem(props.item, () => {
                history.push(`${ROUTES.CHECKOUTS_DETAILS}/${props.item.id}`);
              });
            }}
          >
            View Details
          </button>
        ) : (
          <button onClick={props.connect}>Create Store</button>
        );
      case SHOPIFY:
      case WOOCOMMERCE:
      case ETSY:
        return (
          <>
            {!!props.connect && (
              <button onClick={() => props.connect()}>Connect Store</button>
            )}
            {!!props.disconnect && platform !== MANUAL && (
              <button className={"active"} onClick={() => props.disconnect()}>
                Disconnect Store
              </button>
            )}
          </>
        );
      default:
        return;
    }
  };

  return (
    <div
      className={
        "checkouts-item card-section d-flex flex-column justify-content-start align-items-start"
      }
    >
      <div
        className={`w-100 d-flex flex-row align-items-center justify-content-between `}
      >
        <div
          className={"d-flex flex-row justify-content-start align-items-center"}
        >
          {getIcon(platform, logo)}
          <h4>{name ? name : getName(url, platform, name)}</h4>
        </div>
        {getAction(platform)}
      </div>
      {props.connect && platform === ETSY && (
        <div className="text-muted small mt-3">
          The term 'Etsy' is a trademark of Etsy, Inc. This application uses the
          Etsy API but is not endorsed or certified by Etsy, Inc.
        </div>
      )}
    </div>
  );
};

const Stores = (props) => {
  const [showNameForm, toggleNameForm] = useState(false);
  const [showStoreList, toggleStoreList] = useState(false);
  const [redirectLoad, toggleRedirectLoad] = useState(false);
  const [connectError, setConnectError] = useState("");
  const [selectedPlatform, setPlatform] = useState("");

  let redirectURL = process.env.REACT_APP_CORE_URL
    ? "https://" + process.env.REACT_APP_CORE_URL
    : "http://localhost:8000";

  const location = useLocation();
  const redirectError = new URLSearchParams(location.search).get("error");
  const shop = new URLSearchParams(location.search).get("shop");
  const connectStore = new URLSearchParams(location.search).get("connect");

  useEffect(() => {
    props.updateStoreName("");
    props.getCartConfigurations();
    if (connectStore) {
      toggleStoreList(true);
    }
  }, []);

  const list = [
    {
      shop_name: "ShipShap Store",
      shop_platform: MANUAL,
      connected: false,
    },
    {
      shop_name: "WooCommerce",
      shop_platform: WOOCOMMERCE,
      connected: false,
    },
    {
      shop_name: "Shopify",
      shop_platform: SHOPIFY,
      connected: false,
    },
    {
      shop_name: "Etsy",
      shop_platform: ETSY,
      connected: false,
    },
  ];

  const getFormMessage = (platform) => {
    switch (platform) {
      case SHOPIFY:
        return "Please enter the name of your Shopify store you want to connect.";
      case WOOCOMMERCE:
        return "Please enter your Woo Commerce store URL you want to connect";
      default:
        return "Please enter the name or url of the store you want to connect";
    }
  };

  const getPlaceholderMessage = (platform) => {
    console.log(platform);
    switch (platform) {
      case SHOPIFY:
        return "myShopifyStore";
      case WOOCOMMERCE:
        return "https://mydomain.com/my-woo-store";

      case ETSY:
        return "https://www.etsy.com/shop/myEtsyStore";
      default:
        return "Store Name/URL";
    }
  };

  const getRedirectURL = async (platform, store) => {
    console.log(platform);
    if (auth()?.currentUser) {
      await auth()
        .currentUser?.getIdToken()
        .then((token) => {
          setConnectError("");
          switch (platform) {
            case SHOPIFY:
              redirectURL =
                redirectURL +
                "/platforms/shopify/login?shop=" +
                store +
                "&token=" +
                token;
              break;
            case WOOCOMMERCE:
              redirectURL =
                redirectURL +
                "/platforms/woo-commerce/authenticate?store_url=" +
                store +
                "&token=" +
                token;
              break;
            case ETSY:
              redirectURL =
                redirectURL + "/platforms/etsy/authenticate?token=" + token;
              break;
          }
          window.location.href = redirectURL;
        })
        .catch((err) => {
          setConnectError("Error: Failed to get User authentication");
          console.log(err);
        });
    } else {
      setConnectError("Error: Failed to get User authentication");
    }
  };

  const renderStoreNameForm = () => {
    const checkConnect = () => {
      if (selectedPlatform === WOOCOMMERCE) {
        let givenURL;
        try {
          givenURL = new URL(props.storeName);
        } catch (error) {
          console.log("error is", error);
          setConnectError("Invalid store URL");
          return false;
        }
        if (givenURL.protocol === "https:") return true;
        else {
          setConnectError("Invalid store URL");
          return false;
        }
      }
      return true;
    };

    if (showNameForm) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => {
              toggleNameForm(false);
              setPlatform("");
            }}
          ></div>
          <div className="modal-content settings-card store-name">
            <div className={"card-section head"}>
              <h3>Connect Store</h3>
            </div>
            <div className={"card-section last"}>
              <p>{getFormMessage(selectedPlatform)}</p>
              {connectError && <p className={"text-danger"}>{connectError}</p>}
              <input
                autoFocus
                placeholder={getPlaceholderMessage(selectedPlatform)}
                value={props.storeName}
                onChange={(e) => {
                  setConnectError("");
                  props.updateStoreName(e.target.value);
                }}
              />
              <button
                className={"ml-auto"}
                onClick={async () => {
                  if (checkConnect()) {
                    toggleRedirectLoad(true);
                    await getRedirectURL(selectedPlatform, props.storeName);
                    toggleRedirectLoad(false);
                    toggleNameForm(false);
                    setPlatform("");
                  }
                }}
              >
                {redirectLoad ? (
                  <Spinner animation={"border"} variant={"light"} />
                ) : (
                  "Connect"
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderStoreList = () => {
    if (showStoreList) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => toggleStoreList(false)}
          ></div>
          <div className="modal-content settings-card checkouts-list">
            <div className={"card-section head"}>
              <h3>Available Stores</h3>
            </div>
            {list.map((el, id) => {
              if (el.shop_platform !== MANUAL) {
                return props.context.auth !== FIREBASE_AUTH ? null : (
                  <CheckoutItem
                    item={el}
                    key={id}
                    connect={() => {
                      props.updateStoreName("");
                      toggleNameForm(true);
                      setPlatform(el.shop_platform);
                      toggleStoreList(false);
                    }}
                  />
                );
              }
              return (
                <CheckoutItem
                  item={el}
                  key={id}
                  connect={() => {
                    props.clearSelectedCheckout(() => {
                      history.push(ROUTES.CHECKOUTS_CREATE);
                      toggleStoreList(false);
                    });
                  }}
                />
              );
            })}
            {props.context.auth !== FIREBASE_AUTH && (
              <div
                className={`store-item card-section d-flex flex-row align-items-center justify-content-between `}
              >
                You dont have credentials to connect stores
              </div>
            )}
            <div className={"card-section last"}>
              <button
                className={"col-6 col-lg-3 p-0"}
                onClick={() => toggleStoreList(false)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={"checkouts-main"}>
      <Switch>
        <Route
          path={ROUTES.SHOPIFY_CONNECT}
          exact
          render={() => {
            if (props.context.auth === SESSION_AUTH) {
              return <Redirect to={ROUTES.STORES} />;
            }
            if (auth()?.currentUser) {
              auth()
                .currentUser?.getIdToken()
                .then((token) => {
                  redirectURL =
                    redirectURL +
                    "/platforms/shopify/login?shop=" +
                    shop +
                    "&token=" +
                    token;
                  window.location.href = redirectURL;
                })
                .catch((err) => {
                  setConnectError("Error: Failed to get User authentication");
                  console.log(err);
                  return <Redirect to={ROUTES.STORES} />;
                });
            } else {
              props.setContextReturnURL(location.pathname + location.search);
              return <Redirect to={ROUTES.SIGN_IN} />;
            }
          }}
        />

        <Route
          path={ROUTES.SHOPIFY_CONNECT_ALT}
          exact
          render={() => {
            if (props.context.auth === SESSION_AUTH) {
              return <Redirect to={ROUTES.STORES} />;
            }
            if (auth()?.currentUser) {
              auth()
                .currentUser?.getIdToken()
                .then((token) => {
                  redirectURL =
                    redirectURL + "/platforms/shopify/save-alt?token=" + token;
                  window.location.href = redirectURL;
                })
                .catch((err) => {
                  setConnectError("Error: Failed to get User authentication");
                  console.error(err);
                  return <Redirect to={ROUTES.STORES} />;
                });
            } else {
              props.setContextReturnURL(location.pathname + location.search);
              return <Redirect to={ROUTES.SIGN_IN} />;
            }
          }}
        />
        <Route
          path={ROUTES.CHECKOUTS_DETAILS}
          exact
          render={() => <Redirect to={ROUTES.CHECKOUTS_CREATE} />}
        />
        <Route
          path={[
            ROUTES.CHECKOUTS_DETAILS + "/:id",
            ROUTES.CHECKOUTS_PRODUCTS,
            ROUTES.CHECKOUTS_SETTINGS,
            ROUTES.CHECKOUTS_CREATE,
          ]}
          component={CheckoutsDetail}
        />
        <Route
          path={""}
          exact
          render={() => {
            return !!props.stores?.length || props.loading ? (
              <div className={"checkouts-list settings-card"}>
                {!!props.errors && (
                  <MessageBlock
                    type={"error"}
                    icon={<FiInfo />}
                    message={parseError(props.errors)}
                  />
                )}
                {!!redirectError && !props.errors && (
                  <MessageBlock
                    type={"error"}
                    icon={<FiInfo />}
                    message={redirectError}
                  />
                )}
                <div
                  className={
                    "card-section head d-flex flex-row justify-content-between align-items-center"
                  }
                >
                  <h3>Stores</h3>
                  <a
                    onClick={() => {
                      toggleStoreList(true);
                    }}
                    className={"d-flex flex-row align-items-center"}
                  >
                    <FiPlus />
                    <p>Add New Store</p>
                  </a>
                </div>
                {props.loading ? (
                  <div className={"card-section last"}>
                    <Spinner animation={"border"} />
                  </div>
                ) : (
                  props.stores.map((item) => {
                    if (item.shop_platform !== MANUAL) {
                      return (
                        <CheckoutItem
                          item={item}
                          key={item.id}
                          disconnect={() =>
                            props.disconnectStore(item.shop, () =>
                              props.getConnectedStores(),
                            )
                          }
                        />
                      );
                    }
                    return (
                      <CheckoutItem
                        item={item}
                        key={item.id}
                        selectItem={props.selectCheckoutConfig}
                      />
                    );
                  })
                )}
              </div>
            ) : (
              <div className={"checkouts-empty"}>
                <p>No stores available.</p>
                <p>
                  Create and manage products and checkout links with ShipShap
                  store, or Connect an external store to import orders.
                </p>
                <a
                  onClick={() => {
                    toggleStoreList(true);
                  }}
                >
                  Add New Store
                </a>
              </div>
            );
          }}
        />
      </Switch>
      {renderStoreNameForm()}
      {renderStoreList()}
    </div>
  );
};

const mapStateToProps = ({ checkouts, applicationContext, stores }) => ({
  stores: checkouts.cartConfigs,
  errors: checkouts.errors,
  loading: checkouts.loading,
  context: applicationContext.context,
  storeName: stores.name,
  checkouts,
});

export default connect(mapStateToProps, {
  getCartConfigurations,
  selectCheckoutConfig,
  clearSelectedCheckout,
  updateStoreName,
  disconnectStore,
})(Stores);
