import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { getUserDetails, removeUserCard, selectCard } from "../../actions";
import CardListItem from "./CardListItem";
import { CARD_LIST, MPESA } from "../../../constants/types";

import { functions, analytics, auth } from "../../../services/firebase";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Card } from "react-bootstrap";
import CardForm from "./CardForm";
import { ReactComponent as RemoveIcon } from "../../../assets/remove-card-icon.svg";
import PaystackCardListItem from "./PaystackCardListItem";
import MessageBlock from "../common/MessageBlock";

const CardList = (props) => {
  const [showCardForm, toggleCardForm] = useState(false);
  const [showConfirmation, toggleConfirmation] = useState(false);

  useEffect(() => {
    props.getUserDetails();
    toggleConfirmation(false);
  }, []);

  const deleteCard = (card) => {
    props.removeUserCard(card.id, () => {
      toggleConfirmation(false);
      props.selectCard(null, CARD_LIST, () => {
        return;
      });
    });
  };

  const renderList = (cards) => {
    return cards.map((item, id) => {
      return (
        <CardListItem
          card={item}
          key={id}
          type={CARD_LIST}
          showOverlay={() => toggleConfirmation(true)}
        />
      );
    });
  };

  const renderCardForm = () => {
    return (
      showCardForm && (
        <div className="modal d-flex justify-content-center justify-content-lg-start aligin-items-center">
          <div onClick={() => toggleCardForm()} className="backdrop"></div>
          <div className="modal-content settings-card create-card d-flex col-11 col-lg-6 col-xl-4 m-auto ">
            <div className="card-section head d-flex flex-row justify-content-between">
              <h5>Save Payment Method</h5>
              <a className="d-flex " onClick={() => toggleCardForm()}>
                Close
              </a>
            </div>
            <CardForm onFinish={() => toggleCardForm(!showCardForm)} />
          </div>
        </div>
      )
    );
  };

  const renderConfirmation = () => {
    if (showConfirmation) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => toggleConfirmation(false)}
          ></div>
          <div className="modal-content confirmation">
            <RemoveIcon />
            <div>
              <p>
                You are about to delete payment method{" "}
                <b>
                  {props.selected?.payment_method_type === MPESA
                    ? props.selected?.details?.mobile_money_phone_number
                    : `**** ${props.selected?.details?.last4}`}
                </b>
              </p>
              <p>Are you sure?</p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button onClick={() => toggleConfirmation(!showConfirmation)}>
                Cancel
              </button>
              <button
                onClick={() => deleteCard(props.selected)}
                className="delete-button"
              >
                {props.loading ? (
                  <div className="spinner-border m-auto" role="status"></div>
                ) : (
                  "Delete Payment Method"
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="settings-card cards-content col-12">
      {!!props.errors && <MessageBlock type={"error"} message={props.errors} />}
      <div className="card-section head col-12 d-flex flex-row justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-row align-items-center">
          <h3>Payment Methods</h3>
          <p
            onClick={() => props.getUserDetails()}
            className="refresh-btn mb-0 mx-3"
          >
            Refresh
          </p>
        </div>
        <a
          onClick={() => toggleCardForm(true)}
          className="action d-flex flex-row align-items-center"
        >
          Add New Payment
        </a>
      </div>
      <div>
        <div className="cards-list list-group">
          {props.loading ? (
            <div className={"card-section last card-list-item"}>
              <div className="spinner-border text-dark m-auto" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {props.cards?.length > 0 ? (
                renderList(props.cards)
              ) : (
                <div className={`card-list-item card-section last`}>
                  No Payment Methods saved
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {renderCardForm()}
      {renderConfirmation()}
    </div>
  );
};

const mapStateToProps = ({ userDetails }) => ({
  cards: userDetails.cards,
  loading: userDetails.loading,
  errors: userDetails.errors,
  selected: userDetails.selectedCard,
});

export default connect(mapStateToProps, {
  getUserDetails,
  removeUserCard,
  selectCard,
})(CardList);
