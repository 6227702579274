import {
  CREATE_CUSTOMS_DECLARATION_SUCCESS,
  CUSTOM_PACKAGE_I,
  LABEL_CREATE_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_DECLARATION,
  UPDATE_PARCEL_DECLARATION,
  UPDATE_PARCEL_DIMENSION,
  UPDATE_PARCEL_TYPE,
  UPDATE_PARCEL_WEIGHT,
  UPDATE_PARCEL_WEIGHT_UNITS,
  UPDATE_SELECTED_PARCEL,
  UPDATE_SHIPMENT_EXTRA,
  UPDATE_TEMPLATE_PARCEL,
  CLEAR_CUSTOMS,
  CLEAR_RECEIVER,
  CLEAR_SENDER,
  SENDER_ADDR_SELECT,
  RECEIVER_ADDR_SELECT,
  CLEAR_LABEL_TEMPLATE,
  SELECT_LABEL_TEMPLATE,
  CLEAR_SELECTED_LABEL_TEMPLATE,
  EDIT_LABEL_TEMPLATE,
  ADD_PARCEL_ITEM,
  EDIT_PARCEL_ITEM,
  CLEAR_PARCEL_ITEM,
  DELETE_PARCEL_ITEM,
  SELECT_PARCEL_ITEM,
  CLEAR_LABEL_CREATION_ERRORS,
} from "../../constants/types";

const INITIAL_PARCEL_STATE = {
  loading: false,
  errors: "",
  useTemplate: false,
  packageType: CUSTOM_PACKAGE_I,
  parcels: [],
  selectedParcel: {
    // default parcel selection (USPS_FlatRateEnvelope)
    length: "",
    width: "",
    height: "",
    length_unit: "cm",
    quantity: 1,
  },
  selectedWeight: {
    weight: "",
    weight_unit: "kg",
  },
  selectedExtras: {
    requireSignature: false,
  },
  selectedParcelIndex: -1,
  declaration: null,
};

export function parcelsReducer(state = INITIAL_PARCEL_STATE, action) {
  const parcels = [...state.parcels];
  const selectedParcel = { ...state.selectedParcel };
  const selectedWeight = { ...state.selectedWeight };
  switch (action.type) {
    case UPDATE_SELECTED_PARCEL:
      return {
        ...state,
        selectedParcel: {
          ...action.payload,
          quantity: state.selectedParcel.quantity,
        },
      };
    case UPDATE_PARCEL_WEIGHT:
      return {
        ...state,
        selectedWeight: { ...state.selectedWeight, weight: action.payload },
      };
    case UPDATE_PARCEL_WEIGHT_UNITS:
      return {
        ...state,
        selectedWeight: {
          ...state.selectedWeight,
          weight_unit: action.payload,
        },
      };
    case UPDATE_PARCEL_DIMENSION:
      return {
        ...state,
        selectedParcel: { ...state.selectedParcel, ...action.payload },
      };
    case UPDATE_SHIPMENT_EXTRA:
      const newState = {
        ...state,
        selectedExtras: {
          ...state.selectedExtras,
          [action.payload.prop]: action.payload.value,
        },
      };
      return newState;
    case UPDATE_TEMPLATE_PARCEL:
      return { ...state, useTemplate: action.payload };
    case UPDATE_PARCEL_TYPE:
      return { ...state, packageType: action.payload };
    case CLEAR_CUSTOMS:
      return { ...state, declaration: INITIAL_PARCEL_STATE.declaration };
    case CREATE_CUSTOMS_DECLARATION_SUCCESS:
    case UPDATE_PARCEL_DECLARATION:
      return { ...state, declaration: action.payload };
    case CLEAR_SELECTED_LABEL_TEMPLATE:
    case LABEL_CREATE_SUCCESS:
      // clear the address when the label is created
      return INITIAL_PARCEL_STATE;
    case EDIT_LABEL_TEMPLATE:
    case SELECT_LABEL_TEMPLATE:
      if (action.payload) {
        const template = { ...action.payload };
        delete template.name;
        delete template.id;
        if (template.declaration) delete template.declaration;
        return { ...state, ...template };
      }
      return state;
    case CLEAR_PARCEL_ITEM:
      return {
        ...state,
        selectedParcel: INITIAL_PARCEL_STATE.selectedParcel,
        selectedWeight: INITIAL_PARCEL_STATE.selectedWeight,
        selectedParcelIndex: -1,
      };
    case ADD_PARCEL_ITEM:
      parcels.push(action.payload);
      return {
        ...state,
        parcels,
        selectedParcel: INITIAL_PARCEL_STATE.selectedParcel,
        selectedWeight: INITIAL_PARCEL_STATE.selectedWeight,
      };
    case EDIT_PARCEL_ITEM:
      parcels[state.selectedParcelIndex] = action.payload;
      return {
        ...state,
        parcels,
        selectedParcel: INITIAL_PARCEL_STATE.selectedParcel,
        selectedWeight: INITIAL_PARCEL_STATE.selectedWeight,
        selectedParcelIndex: -1,
      };
    case DELETE_PARCEL_ITEM:
      parcels.splice(action.payload, 1);
      if (state.selectedParcelIndex === action.payload) {
        return {
          ...state,
          parcels,
          selectedParcel: INITIAL_PARCEL_STATE.selectedParcel,
          selectedWeight: INITIAL_PARCEL_STATE.selectedWeight,
          selectedParcelIndex: -1,
        };
      }
      return { ...state, parcels };
    case SELECT_PARCEL_ITEM:
      Object.keys(selectedParcel).forEach((item) => {
        selectedParcel[item] = parcels[action.payload][item];
      });
      Object.keys(selectedWeight).forEach((item) => {
        selectedWeight[item] = parcels[action.payload][item];
      });
      return {
        ...state,
        selectedParcelIndex: action.payload,
        selectedParcel,
        selectedWeight,
      };
    case LOGOUT_SUCCESS:
      return INITIAL_PARCEL_STATE;
    default:
      return state;
  }
}
