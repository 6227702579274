import {
  CLEAR_CUSTOMS,
  UPDATE_CUSTOMS_CERTIFIED,
  UPDATE_CUSTOMS_CONTENT_EXPLANATION,
  UPDATE_CUSTOMS_CONTENT_TYPE,
  UPDATE_CUSTOMS_DECLARATION,
  UPDATE_CUSTOMS_INCOTERM,
  UPDATE_CUSTOMS_ITEMS,
  UPDATE_CUSTOMS_NONDELIVERY,
  UPDATE_CUSTOMS_SIGNER,
  CREATE_CUSTOMS_DECLARATION,
  CREATE_CUSTOMS_DECLARATION_SUCCESS,
  CREATE_CUSTOMS_DECLARATION_FAILURE,
  CLEAR_SENDER,
  CLEAR_RECEIVER,
  SENDER_ADDR_SELECT,
  RECEIVER_ADDR_SELECT,
  LABEL_CREATE_SUCCESS,
  CLEAR_LABEL_TEMPLATE,
  SELECT_LABEL_TEMPLATE,
  CLEAR_SELECTED_LABEL_TEMPLATE,
  EDIT_CUSTOMS_ITEMS,
  DELETE_CUSTOMS_ITEMS,
  SELECT_CUSTOMS_ITEM,
  EDIT_LABEL_TEMPLATE,
  SELECT_ORDER,
  LOGOUT_SUCCESS,
} from "../../constants/types";
import CURRENCY_UNITS from "../../assets/currency-units.json";
import { getQuantity } from "../Home/common/helpers";

const INITIAL_STATE = {
  certified: false,
  contentType: "DOCUMENTS",
  contentsExplanation: "", // required if content type other
  customsSigner: "",
  nonDeliveryOption: "RETURN", // other option is ABANDON
  items: [], // list of items
  errors: "",
  loading: false,
  selectedItem: -1,
  incoterm: "DDU", // optional - who pays for customs tax/duty
  eel_pfc: "NOEEI_30_37_a", // optional - use this default for items that cost less than $2500
};

export function customsReducer(state = INITIAL_STATE, action) {
  const items = [...state.items];
  switch (action.type) {
    // case UPDATE_CUSTOMS_DECLARATION:
    //     return;
    case CREATE_CUSTOMS_DECLARATION:
      return { ...state, loading: true, errors: "" };
    case CREATE_CUSTOMS_DECLARATION_SUCCESS:
      return { ...state, loading: false, errors: "" };
    case CREATE_CUSTOMS_DECLARATION_FAILURE:
      return { ...state, loading: false, errors: action.payload };

    case UPDATE_CUSTOMS_ITEMS:
      items.push(action.payload);
      return { ...state, items };
    case EDIT_CUSTOMS_ITEMS:
      items[action.payload.itemIdx] = action.payload.item;
      return { ...state, items };
    case DELETE_CUSTOMS_ITEMS:
      items.splice(action.payload, 1);
      return { ...state, items };
    case SELECT_ORDER:
      if (action.payload?.line_items?.length > 0) {
        const lineItems = action.payload.line_items;
        const list = lineItems.map((item) => {
          let currency = "USD";
          if (Object.keys(CURRENCY_UNITS).includes(item.currency))
            currency = item.currency;
          return {
            description: item.name,
            quantity: getQuantity(item),
            net_weight: item.weight,
            weight_unit: item.weight_unit,
            value_amount: item.price,
            value_currency: currency || null,
            origin_country: null,
            tariff_number: null,
            image: item.image || null,
          };
        });

        return { ...state, items: list };
      }
      return { ...state, items: INITIAL_STATE.items };
    case UPDATE_CUSTOMS_CERTIFIED:
      return { ...state, certified: action.payload };
    case UPDATE_CUSTOMS_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    case UPDATE_CUSTOMS_CONTENT_EXPLANATION:
      return { ...state, contentsExplanation: action.payload };
    case UPDATE_CUSTOMS_SIGNER:
      return { ...state, customsSigner: action.payload };
    case UPDATE_CUSTOMS_NONDELIVERY:
      return { ...state, nonDeliveryOption: action.payload };
    case UPDATE_CUSTOMS_INCOTERM:
      return { ...state, incoterm: action.payload };
    case SELECT_CUSTOMS_ITEM:
      return { ...state, selectedItem: action.payload };
    case EDIT_LABEL_TEMPLATE:
    case SELECT_LABEL_TEMPLATE:
      if (action.payload && action.payload.declaration) {
        return { ...state, ...action.payload.declaration };
      }
      return state;
    case CLEAR_SELECTED_LABEL_TEMPLATE:
    case CLEAR_CUSTOMS:
    case LABEL_CREATE_SUCCESS:
    case LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
