import {
  ADD_TOKEN,
  CLEAR_NEW_TOKEN,
  GENERATE_TOKEN,
  GENERATE_TOKEN_FAILURE,
  GENERATE_TOKEN_SUCCESS,
  GET_TOKENS,
  GET_TOKENS_FAILURE,
  GET_TOKENS_SUCCESS,
  REMOVE_TOKEN,
  REMOVE_TOKEN_FAILURE,
  REMOVE_TOKEN_SUCCESS,
  SELECT_TOKEN,
  UPDATE_API_NAME,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";

export const updateTokenName = (name) => {
  return {
    type: UPDATE_API_NAME,
    payload: name,
  };
};

export const generateToken = (data, onSuccess) => (dispatch) => {
  dispatch({
    type: GENERATE_TOKEN,
  });

  if (!data.name) {
    dispatch({
      type: GENERATE_TOKEN_FAILURE,
      payload: "Please give your new token a name.",
    });
    return;
  }

  const request = {
    name: data.name,
  };

  instance
    .post("/api/auth/generate/", request)
    .then((res) => {
      dispatch({
        type: GENERATE_TOKEN_SUCCESS,
        payload: res.data.token,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: GENERATE_TOKEN_FAILURE,
        payload: errMsg,
      });
    });
};

export const addGenerateError = (msg) => {
  return {
    type: GENERATE_TOKEN_FAILURE,
    payload: msg,
  };
};

export const getTokens = (onSuccess) => (dispatch) => {
  dispatch({
    type: GET_TOKENS,
  });

  instance
    .get("/api/auth/list/")
    .then((res) => {
      dispatch({
        type: GET_TOKENS_SUCCESS,
        payload: res.data,
      });

      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: GET_TOKENS_FAILURE,
        payload: errMsg,
      });
    });
};

export const clearNewToken = () => {
  return {
    type: CLEAR_NEW_TOKEN,
  };
};
export const selectToken = (item) => {
  return {
    type: SELECT_TOKEN,
    payload: item,
  };
};

const addToken = (item, dispatch) => {
  dispatch({
    type: ADD_TOKEN,
    payload: item,
  });
};

export const removeToken = (id, onSuccess) => (dispatch) => {
  dispatch({
    type: REMOVE_TOKEN,
  });

  const request = {
    token_key: id,
  };

  instance
    .post("/api/auth/delete/", request)
    .then((res) => {
      dispatch({
        type: REMOVE_TOKEN_SUCCESS,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: REMOVE_TOKEN_FAILURE,
        payload: errMsg,
      });
    });
};
