import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  FiCheck,
  FiCopy,
  FiExternalLink,
  FiLink,
  FiPlus,
  BsThreeDotsVertical,
  FiInfo,
  FiImage,
} from "react-icons/fi";
import {
  clearSelectedProduct,
  getCartProducts,
  selectProduct,
} from "../../actions/CheckoutsActions";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import history from "../../../History";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";
import { toMoneyFormat } from "../common/helpers";

const CheckoutProductItem = (props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, [1000]);
    }
  }, [copied]);

  const renderImage = (images) => {
    if (!!images.length) {
      const image = images.find((image) => !!image?.image);
      if (image) {
        return <img src={image.image} />;
      }
    }
    return <FiImage />;
  };

  const renderPrice = (variations) => {
    if (!!variations.length) {
      const variation = variations.find((variation) => !!variation?.price);
      if (variation) {
        return <p>{toMoneyFormat(variation.price, props.currency)}</p>;
      }
    }
    return <p>-</p>;
  };

  const { id, images, title, variations, sku, checkout_url } = props.item;
  return (
    <tr
      key={id}
      onClick={() => props.select()}
      className={"checkout-product-list-item"}
    >
      <td>{renderImage(images)}</td>
      <td>
        <p>{title}</p>
      </td>
      <td>{renderPrice(variations)}</td>
      <td>
        <a
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(checkout_url).then(() => {
              setCopied(true);
              toast("Copied");
            });
          }}
        >
          {copied ? <FiCheck className={"copied"} /> : <FiCopy />}
        </a>
      </td>
      <td>
        <div>
          <a>Edit</a>
          {/*<a><BsThreeDotsVertical/></a>*/}
        </div>
      </td>
    </tr>
  );
};

const CheckoutProductsList = (props) => {
  useEffect(() => {
    if (props.selectedConfig?.shop)
      props.getCartProducts(props.selectedConfig?.shop);
  }, []);

  return (
    <div className={"checkout-product-list settings-card"}>
      {props.errors && (
        <MessageBlock
          icon={<FiInfo />}
          type={"error"}
          message={parseError(props.errors)}
        />
      )}
      <div className={"card-section head"}>
        <h3>Products</h3>
        <Link className={"action"} to={ROUTES.CHECKOUTS_PRODUCTS_CREATE}>
          <FiPlus />
          Add Products
        </Link>
      </div>
      <div className={"card-section last"}>
        {props.loading ? (
          <Spinner animation={"border"} />
        ) : (
          <>
            {!!props.products && (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Product Name</th>
                    {/*<th>Status</th>*/}
                    <th>Price</th>
                    <th>Checkout URL</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {props.products.map((item) => {
                  return (
                    <tbody key={item.id}>
                      <CheckoutProductItem
                        key={item.id}
                        item={item}
                        select={() =>
                          props.selectProduct(item, () =>
                            history.push(ROUTES.CHECKOUTS_PRODUCTS_CREATE),
                          )
                        }
                        currency={props.selectedConfig.currency}
                      />
                    </tbody>
                  );
                })}
              </table>
            )}
            {!props.products?.length && (
              <div className={"empty-product-list"}>
                <p>
                  Your Shop has been created! but no products are currently
                  listed
                </p>
                <Link to={ROUTES.CHECKOUTS_PRODUCTS_CREATE}>
                  List your first product!
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ checkouts }) => ({
  products: checkouts.products,
  selectedConfig: checkouts.selectedConfig,
  selectedProduct: checkouts.selectedProduct,
  loading: checkouts.loadingProducts,
  errors: checkouts.productErrors,
});

export default connect(mapStateToProps, {
  getCartProducts,
  selectProduct,
  clearSelectedProduct,
})(CheckoutProductsList);
