import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../History";
import MessageBlock from "../common/MessageBlock";
import { Form, Spinner } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";
import { FormField } from "../common/FormField";
import Dropdown from "react-bootstrap/Dropdown";
import {
  clearProductImages,
  clearSelectedProduct,
  createCartProduct,
  selectProductImages,
  updateProductForm,
  uploadProductImages,
} from "../../actions/CheckoutsActions";
import { convertToBase64 } from "../common/helpers";
import { FiCheck, FiInfo, FiCopy } from "react-icons/fi";
import toast from "react-hot-toast";
import MASS_UNITS from "../../../assets/mass-units.json";
import LENGTH_UNITS from "../../../assets/length_units.json";
import { parseError } from "../../actions/ActionHelpers";
import FileUpload from "../common/FileUpload";

const CheckoutProductCreate = (props) => {
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    valid: true,
  });

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, [1000]);
    }
  }, [copied]);

  useEffect(() => {
    return () => {
      props.clearSelectedProduct();
    };
  }, []);

  useEffect(() => {
    if (props.errors) {
      if (
        Object.keys(props.errors).some((item) =>
          Object.keys(props.selectedProduct).includes(item),
        )
      ) {
        const errors = {};
        Object.keys(props.errors).forEach((field) => {
          if (!!fieldErrors[field]) {
            errors[field] = fieldErrors[field];
          } else {
            errors[field] = parseError(props.errors[field]);
          }
        });
        setFieldErrors({
          ...fieldErrors,
          valid: false,
          ...errors,
        });
      } else {
        setErrorMsg(parseError(props.errors));
      }
    } else {
      setErrorMsg("");
    }
  }, [props.errors]);

  const setter = (fieldName) => {
    return (value) => {
      setFieldErrors({
        valid: true,
      });
      setValidated(false);
      let newVal = value;

      if (fieldName === "sku") newVal = value.toString().replace(" ", "-");
      props.updateProductForm(fieldName, newVal, () => setValidated(false));
    };
  };

  const handleFile = (files) => {
    if (!files?.length) {
      setter("images")([]);
      return;
    }
    const images = [];
    files.forEach((file) => {
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = function (e) {
          images.push({ image: e.target.result });
          setter("images")([...images]);
        };
        reader.readAsDataURL(file);
      } else {
        images.push({ image: files.preview });
      }
    });
  };

  const massElements = Object.keys(MASS_UNITS).map((item, idx) => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  });

  const lengthElements = Object.keys(LENGTH_UNITS).map((item, idx) => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  });

  return (
    <div
      className={
        "d-flex flex-column flex-lg-row align-items-start justify-content-between"
      }
    >
      <div className={"products-create-form settings-card order-1 order-lg-0"}>
        <div className={"card-section head"}>
          <h3>Create Product</h3>
          <a
            className={"action red"}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </a>
        </div>
        <div className={"card-section last"}>
          {!props.checkouts?.products?.length && (
            <MessageBlock message={"Create the first product for your store"} />
          )}
          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => {
              event.stopPropagation();
              event.preventDefault();

              const form = event.currentTarget;
              let isFormValid = form.checkValidity();
              setValidated(true);

              const data = { ...props.selectedProduct };

              // const images = data.images.filter(image=>{
              //     return !image || (image instanceof Object && !!image?.id)
              // })
              // if(images.length>0) {
              //     Object.assign(data, {images})
              // }
              // else delete data.images
              //
              // if(!data.id && !data.images) {
              //     setFieldErrors({
              //         ...fieldErrors,
              //         valid:false,
              //         images: "Please select product images"
              //     })
              // }
              if (data.images?.some((image) => !!image.id)) delete data.images;
              else if (!data.images) {
                setFieldErrors({
                  ...fieldErrors,
                  valid: false,
                  images: "Please select product images",
                });
              }
              if (isFormValid && fieldErrors.valid)
                props.createCartProduct(
                  data,
                  props.checkouts.selectedConfig.shop,
                  () => {
                    history.replace(ROUTES.CHECKOUTS_PRODUCTS);
                  },
                );
            }}
          >
            <FormField
              controlId={"product.name"}
              className={""}
              label={"Product Name"}
              isRequired
              value={props.selectedProduct.title}
              type={"text"}
              setter={setter("title")}
              placeholder={"Product #1"}
              errorMessage={props.errors?.title}
            />
            <Form.Group controlId="product.description" className={""}>
              <Form.Label className={"d-flex flex-row align-items-start"}>
                Product Description <p className={"text-danger m-0"}>*</p>
              </Form.Label>
              <Form.Control
                as={"textarea"}
                type={"text"}
                required
                onChange={(e) => {
                  setter("description")(e.target.value);
                }}
                value={props.selectedProduct.description}
                isInvalid={validated && !!fieldErrors.description}
              />
              <Form.Control.Feedback type="invalid">
                {fieldErrors.description ||
                  "Please enter valid product description"}
              </Form.Control.Feedback>
            </Form.Group>
            {!!props.selectedProduct?.id && !!props.images?.length ? (
              <div className={"images-preview"}>
                <p>Product Images</p>
                <div className={"images-list"}>
                  {props.images.map((image, idx) => {
                    return <img src={image.image} key={image.id || idx} />;
                  })}
                </div>
              </div>
            ) : (
              <div>
                <Form.Group controlId="product.images" className={""}>
                  <Form.Label className={"d-flex flex-row align-items-start"}>
                    Product Images <p className={"text-danger m-0"}>*</p>
                  </Form.Label>
                  <FileUpload
                    fileType={"image/*"}
                    multiple
                    onChange={(files) => {
                      handleFile(files);
                    }}
                    validate={(files) => {
                      if (files?.length > 5) {
                        setFieldErrors({
                          valid: false,
                          images: "Maximum number of images allowed is 5",
                        });
                        return false;
                      }
                      return files.every((file) => {
                        if (file.size > 1024 * 1024) {
                          setFieldErrors({
                            valid: false,
                            images: `Image size should not exceed 1MB`,
                          });
                          return false;
                        }
                        if (file.type === "image/svg+xml") {
                          setFieldErrors({
                            valid: false,
                            images: `SVG files are not supported`,
                          });
                          return false;
                        }
                        return true;
                      });
                    }}
                    error={fieldErrors.images}
                    // preview={!!props.images?.length?props.images.map(image=>{
                    //     if(image instanceof Object && !!image?.id){
                    //         return {preview: image.image}
                    //     }
                    //     else return {preview: image}
                    // }):[]}
                  />
                  {!!fieldErrors.images && (
                    <p className={"text-danger"}>
                      {fieldErrors.images || "Please select a logo file"}
                    </p>
                  )}
                </Form.Group>
                <p className={"info"}>Select a maximum of 5 images</p>
                <p className={"info"}>Recommended size for images &lt;1MB </p>
              </div>
            )}
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <FormField
                controlId={"product.price"}
                className={"col-9"}
                label={"Price"}
                isRequired
                value={props.selectedProduct.price}
                type={"number"}
                setter={setter("price")}
                placeholder={"0"}
                errorMessage={props.errors?.price}
              />
              <div
                className={
                  "col-3 h-100 d-flex align-items-end justify-content-end"
                }
              >
                <input
                  type={"text"}
                  disabled
                  value={props.checkouts.selectedConfig.currency}
                />
              </div>
            </div>
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <FormField
                controlId={"product.weight"}
                className={"col-9"}
                label={"Weight"}
                isRequired
                value={props.selectedProduct.weight}
                type={"number"}
                setter={setter("weight")}
                placeholder={"0"}
                errorMessage={props.errors?.weight}
              />
              <div className={"col-3"}>
                <Form.Group controlId="product.mass_unit" className={""}>
                  <Form.Select
                    // disabled
                    value={props.selectedProduct.weight_unit || "kg"}
                    onChange={(e) => {
                      setter("weight_unit")(e.target.value);
                    }}
                  >
                    {Object.keys(MASS_UNITS)?.length > 0 ? (
                      massElements
                    ) : (
                      <option>Select your weight unit</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a weight unit
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div
              className={
                "d-flex flex-row justify-content-between align-items-center"
              }
            >
              <FormField
                controlId={"product.length"}
                className={"col-3"}
                label={"Length"}
                isRequired
                value={props.selectedProduct.length}
                type={"number"}
                setter={setter("length")}
                placeholder={"0"}
                errorMessage={props.errors && props.errors["length"]}
              />
              <FormField
                controlId={"product.width"}
                className={"col-3"}
                label={"Width"}
                isRequired
                value={props.selectedProduct.width}
                type={"number"}
                setter={setter("width")}
                placeholder={"0"}
                errorMessage={props.errors?.width}
              />
              <FormField
                controlId={"product.height"}
                className={"col-3"}
                label={"Height"}
                isRequired
                value={props.selectedProduct.height}
                type={"number"}
                setter={setter("height")}
                placeholder={"0"}
                errorMessage={props.errors?.height}
              />
              <div className={"col-3"}>
                <Form.Group controlId="product.length_unit" className={""}>
                  <Form.Select
                    // disabled
                    value={props.selectedProduct.length_unit || "cm"}
                    onChange={(e) => {
                      setter("length_unit")(e.target.value);
                    }}
                  >
                    {Object.keys(LENGTH_UNITS)?.length > 0 ? (
                      lengthElements
                    ) : (
                      <option>Select your length unit</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a length unit
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <FormField
              controlId={"product.sku"}
              className={""}
              label={"SKU (optional)"}
              value={props.selectedProduct.sku}
              type={"text"}
              setter={setter("sku")}
              placeholder={"product-sku"}
              errorMessage={props.errors?.sku}
            />
            {errorMsg && (
              <MessageBlock
                icon={<FiInfo />}
                type={"error"}
                message={parseError(errorMsg)}
              />
            )}
            <button type={"submit"} disabled={props.loading}>
              {props.loading ? <Spinner animation={"border"} /> : "Save"}
            </button>
          </Form>
        </div>
      </div>
      {!!props.selectedProduct.checkout_url && (
        <div className={"checkout_url order-0 order-lg-1"}>
          <div className={""}>
            <h4>Checkout URL</h4>
            <div>
              <p className={"url-text"}>{props.selectedProduct.checkout_url}</p>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText(props.selectedProduct.checkout_url)
                    .then(() => {
                      setCopied(true);
                      toast("Copied");
                    });
                }}
              >
                {copied ? <FiCheck className={"copied"} /> : "Copy"}
              </a>
            </div>
          </div>
          {/*<button>Delete Product</button>*/}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ checkouts }) => ({
  checkouts,
  selectedProduct: checkouts.selectedProduct,
  images: checkouts.selectedProduct?.images,
  loading: checkouts.loadingProducts,
  errors: checkouts.productErrors,
});

export default connect(mapStateToProps, {
  updateProductForm,
  clearSelectedProduct,
  createCartProduct,
  selectProductImages,
  clearProductImages,
  uploadProductImages,
})(CheckoutProductCreate);
