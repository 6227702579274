import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  CLEAR_TEMPLATE_VALUE,
  CUSTOM_PACKAGE_I,
  FLAT_RATE_PACKAGE_I,
} from "../../../constants/types";
import {
  updateTemplateName,
  updateTemplateUse,
  updateNewTemplate,
  clearNewTemplate,
  clearSelectedTemplate,
  createTemplate,
  updateExtras,
  selectTemplate,
  editTemplate,
  saveEditableTemplate,
} from "../../actions";
import "../../../scss/template.scss";

import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";

import { ReactComponent as Check } from "../../../assets/check.svg";
import { FiPlus, FiX } from "react-icons/fi";
import PackageDetails from "./PackageDetails";
import CustomsInfo from "./CustomsInfo";
import { compose } from "recompose";
import { withCookies } from "react-cookie";

class LabelTemplate extends Component {
  state = {
    templateError: "",
    checkPackage: false,
    checkPackages: [],
    checkContent: false,
    checkCustomsItems: false,
    checkIncoTerm: false,
    checkNonDelivery: false,
    checkSigner: false,
    checkCertify: false,
    checkSignature: false,
    useCustoms: false,
    duplicate: false,
  };

  constructor() {
    super();
    this.templateNameField = createRef();
    this.templateFormElement = createRef();
  }

  componentDidMount = () => {
    if (
      this.props.labelTemplate.selectType === "edit" &&
      this.props.labelTemplate.editableTemplate
    ) {
      const template = { ...this.props.labelTemplate.editableTemplate };
      this.props.updateTemplateName(template.name);
      if (template.packageType || template.useTemplate || template.parcels) {
        const packages = [];
        template.parcels.forEach((item, idx) => {
          packages.push(idx);
        });
        this.setState({
          checkPackages: packages,
        });
      }
      if (template.selectedExtras) {
        this.setState({ checkSignature: true });
      }
      if (template.declaration) {
        this.setState({ useCustoms: true });
        if (template.declaration.contentType) {
          this.setState({ checkContent: true });
        }
        if (template.declaration.items?.length > 0) {
          this.setState({ checkCustomsItems: true });
        }
        if (template.declaration.incoterm) {
          this.setState({ checkIncoTerm: true });
        }
        if (template.declaration.nonDeliveryOption) {
          this.setState({ checkNonDelivery: true });
        }
        if (template.declaration.customsSigner) {
          this.setState({ checkSigner: true });
        }
        if (template.declaration.certified) {
          this.setState({ checkCertify: true });
        }
      }
    }

    if (this.props.showCustoms) {
      this.setState({ useCustoms: true });
    }
  };
  checkStateUpdate = (prev, current) => {
    Object.keys(prev).forEach((item) => {
      if (prev[item] !== current[item]) {
        return true;
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const prevStateCheck = { ...prevState };
    const currentStateCheck = { ...this.state };

    delete prevStateCheck.templateError;
    delete currentStateCheck.templateError;

    if (
      (prevProps !== this.props ||
        this.checkStateUpdate(prevStateCheck, currentStateCheck)) &&
      this.state.templateError.trim() !== ""
    ) {
      this.setState({ templateError: "" });
    }

    if (
      this.props.showSaveTemplatePrompt &&
      prevProps.labelTemplate.templates !== this.props.labelTemplate.templates
    ) {
      const templates = [...this.props.labelTemplate.templates];
      this.props.selectTemplate(templates[templates.length - 1], () => {
        this.toggleTemplate();
      });
    }
  }

  toggleTemplate = () => {
    this.setState({
      templateError: "",
      checkPackage: false,
      checkPackages: [],
      checkContent: false,
      checkCustomsItems: false,
      checkIncoTerm: false,
      checkNonDelivery: false,
      checkSigner: false,
      checkCertify: false,
      checkSignature: false,
      useCustoms: false,
    });
    this.props.updateTemplateName("");
    this.props.editTemplate(null);
    if (this.props.labelTemplate.selectedTemplate) {
      this.props.selectTemplate(this.props.labelTemplate.selectedTemplate);
    }
    this.props.toggleTemplate();
  };

  getCheckAll = (type) => {
    let checked = true;
    Object.keys(this.state).forEach((item) => {
      if (item !== "templateError" && item !== "checkAll") {
        if (item !== type) {
          if (Array.isArray(this.state[item])) {
            if (this.state[item].length !== this.parcels?.parcels?.length)
              checked = false;
          } else if (!this.state[item]) {
            checked = false;
          }
        }
      }
    });
    if (this.state[type]) {
      checked = false;
    }
    return checked;
  };

  getCheckOne = () => {
    let checked = false;
    Object.keys(this.state).forEach((item) => {
      if (item !== "templateError" && item !== "checkAll") {
        if (Array.isArray(this.state[item])) {
          if (this.state[item].length > 0) checked = true;
        } else if (this.state[item]) {
          checked = true;
        }
      }
    });
    return checked;
  };

  checkPackage = (value) => {
    let checked = this.getCheckAll("checkPackage");

    this.setState({
      checkPackage: !this.state.checkPackage,
      checkAll: checked,
    });
  };

  checkPackages = (idx) => {
    let checked = this.getCheckAll("checkPackages");

    const packages = [...this.state.checkPackages];
    if (packages.includes(idx)) {
      packages.splice(packages.indexOf(idx), 1);
    } else {
      packages.push(idx);
      packages.sort((idx1, idx2) => idx1 - idx2);
    }

    this.setState({
      checkPackages: packages,
      checkAll: checked,
    });
  };

  checkContent = (value) => {
    let checked = this.getCheckAll("checkContent");
    this.setState({
      checkContent: !this.state.checkContent,
      checkAll: checked,
    });
  };
  checkCustomsItems = (value) => {
    let checked = this.getCheckAll("checkCustomsItems");
    this.setState({
      checkCustomsItems: !this.state.checkCustomsItems,
      checkAll: checked,
    });
  };
  checkIncoTerm = (value) => {
    let checked = this.getCheckAll("checkIncoTerm");
    this.setState({
      checkIncoTerm: !this.state.checkIncoTerm,
      checkAll: checked,
    });
  };
  checkNonDelivery = (value) => {
    let checked = this.getCheckAll("checkNonDelivery");
    this.setState({
      checkNonDelivery: !this.state.checkNonDelivery,
      checkAll: checked,
    });
  };
  checkSigner = (value) => {
    let checked = this.getCheckAll("checkSigner");
    this.setState({
      checkSigner: !this.state.checkSigner,
      checkAll: checked,
    });
  };
  checkCertify = (value) => {
    let checked = this.getCheckAll("checkCertify");
    this.setState({
      checkCertify: !this.state.checkCertify,
      checkAll: checked,
    });
  };
  checkSignature = (value) => {
    let checked = this.getCheckAll("checkSignature");
    this.setState({
      checkSignature: !this.state.checkSignature,
      checkAll: checked,
    });
  };

  removeCustoms = () => {
    this.setState({
      checkContent: false,
      checkCustomsItems: false,
      checkIncoTerm: false,
      checkNonDelivery: false,
      checkSigner: false,
      checkCertify: false,
      checkSignature: false,
      useCustoms: false,
    });
  };

  validateFields = (item, idx) => {
    let valid = true;
    const { length, width, height, template, weight } = item;
    let text = "";
    this.setState({ templateError: "" });
    if (!(length && width && height) && !template) {
      text += "Dimensions";
      valid = false;
    }
    if (!weight) {
      text += text ? " and Weight" : "Weight";
      valid = false;
    }
    text += ` fields are empty, cannot add package ${idx + 1} to template`;
    if (!valid) {
      this.setState({ templateError: text });
    }

    return valid;
  };

  createTemplate = () => {
    const { name } = this.props.newTemplate;
    if (!name || name.trim() === "") {
      this.setState({ templateError: "Name is required" });
      this.templateNameField.current.focus();
      this.templateNameField.current.scrollTo({ top: 0 });
      this.templateNameField.current.classList.add("error");
      return;
    }

    if (!this.getCheckOne()) {
      this.setState({ templateError: "Please select atleast one item" });
      return;
    }
    const template = {};
    if (this.state.checkPackages?.length > 0) {
      if (
        this.state.checkPackages.every(
          (item, idx) =>
            !this.validateFields(this.props.parcels.parcels[item], idx),
        )
      )
        return;
      const parcels = [];
      this.state.checkPackages?.forEach((item) => {
        if (this.props.parcels?.parcels[item])
          parcels.push(this.props.parcels?.parcels[item]);
      });
      template.packageType = this.props.parcels.packageType;
      template.useTemplate = this.props.parcels.useTemplate;
      template.parcels = parcels;
    }
    if (this.state.checkContent) {
      if (
        this.props.customs.contentType === "OTHER" &&
        this.props.customs.contentsExplanation.trim() === ""
      ) {
        this.setState({
          templateError: "Content Explanation is empty, cannot add to template",
        });
        return;
      }
      if (!template.declaration) template.declaration = {};
      template.declaration.contentType = this.props.customs.contentType;
      template.declaration.contentsExplanation =
        this.props.customs.contentsExplanation;
    }
    if (this.state.checkCustomsItems) {
      if (this.props.customs.items?.length === 0) {
        this.setState({
          templateError: "Customs Items list is empty, cannot add to template",
        });
        return;
      }
      if (!template.declaration) template.declaration = {};
      if (this.props.customs.items?.length > 0)
        template.declaration.items = [...this.props.customs.items];
    }
    if (this.state.checkIncoTerm) {
      if (!template.declaration) template.declaration = {};
      template.declaration.incoterm = this.props.customs.incoterm;
    }
    if (this.state.checkNonDelivery) {
      if (!template.declaration) template.declaration = {};
      template.declaration.nonDeliveryOption =
        this.props.customs.nonDeliveryOption;
    }
    if (this.state.checkSigner) {
      if (this.props.customs.customsSigner.trim() === "") {
        this.setState({
          templateError: "Customs Signer name is empty, cannot add to template",
        });
        return;
      }
      if (!template.declaration) template.declaration = {};
      template.declaration.customsSigner = this.props.customs.customsSigner;
    }
    if (this.state.checkCertify) {
      if (!template.declaration) template.declaration = {};
      template.declaration.certified = this.props.customs.certified;
    }
    if (this.state.checkSignature) {
      template.selectedExtras = this.props.parcels.selectedExtras;
    }

    this.setState({ templateError: "" });
    if (
      this.props.labelTemplate.editableTemplate &&
      this.props.labelTemplate.selectType === "edit" &&
      !this.state.duplicate
    ) {
      this.props.saveEditableTemplate(
        {
          name: this.props.newTemplate.name.trim(),
          ...template,
        },
        () => {
          this.toggleTemplate();
        },
      );
      if (
        this.props.labelTemplate.selectedTemplate &&
        this.props.labelTemplate.editableTemplate.id ===
          this.props.labelTemplate.selectedTemplate.id
      ) {
        this.props.selectTemplate({
          id: this.props.labelTemplate.editableTemplate.id,
          name: this.props.newTemplate.name.trim(),
          ...template,
        });
      } else {
        this.props.selectTemplate(this.props.labelTemplate.selectedTemplate);
      }
      return;
    } else if (
      this.props.labelTemplate.editableTemplate &&
      this.props.labelTemplate.selectType === "edit" &&
      this.state.duplicate
    ) {
      if (
        this.props.labelTemplate.templates.some(
          (item) => item.name === this.props.newTemplate.name,
        )
      ) {
        this.setState({
          templateError: "There is an existing template with the same name",
        });
        return;
      }
    }

    this.props.createTemplate(
      {
        name: this.props.newTemplate.name.trim(),
        ...template,
      },
      () => {
        if (!this.props.showSaveTemplatePrompt) {
          this.toggleTemplate();
        }
      },
    );
  };

  render() {
    if (this.props.showCustomsForm) return <></>;
    return (
      <div className="modal d-flex">
        <div
          className="backdrop"
          onClick={() => {
            this.toggleTemplate();
          }}
        ></div>
        <div
          className={`modal-content label-template settings-card ${this.props.cookies.cookies.is_test ? "test-mode" : "live-mode"}`}
          ref={this.templateFormElement}
        >
          <div
            className={
              "card-section head d-flex flex-row justify-content-between align-items-center"
            }
          >
            <h3>New Template</h3>
            <a onClick={this.toggleTemplate}>
              <FiX size={35} />
            </a>
          </div>
          <div className="modal-head card-section d-flex flex-row align-items-center">
            <div className="col-12 p-0 d-flex flex-column ">
              <label className="d-flex flex-column">
                <h4>Template Name</h4>
                <input
                  ref={this.templateNameField}
                  className="template-name p-1"
                  type="text"
                  value={this.props.newTemplate.name}
                  onChange={(e) => {
                    this.props.updateTemplateName(e.target.value);
                    e.target.classList.remove("error");
                  }}
                />
              </label>
            </div>
          </div>
          <div className="content-container card-section">
            <div className="package-details">
              {
                <PackageDetails
                  template
                  checkPackages={(idx) => this.checkPackages(idx)}
                  isPackageChecked={this.state.checkPackages}
                  errors={this.props.packageDetailErrors}
                  parcelsError={this.props.parcelsError}
                />
              }
            </div>
          </div>
          <div className="content-container card-section">
            {this.state.useCustoms ? (
              <>
                <div
                  className={
                    "template-customs-close d-flex flex-row justify-content-between align-items-center"
                  }
                >
                  <h3>Customs Declaration</h3>
                  <FiX onClick={() => this.removeCustoms()} />
                </div>
                <CustomsInfo
                  template
                  checkContent={this.checkContent}
                  isContentChecked={this.state.checkContent}
                  checkItems={this.checkCustomsItems}
                  isItemsChecked={this.state.checkCustomsItems}
                  checkIncoTerm={this.checkIncoTerm}
                  isIncoTermChecked={this.state.checkIncoTerm}
                  checkNonDeliverable={this.checkNonDelivery}
                  isNonDeliverableChecked={this.state.checkNonDelivery}
                  checkSigner={this.checkSigner}
                  isSignerChecked={this.state.checkSigner}
                  checkCertify={this.checkCertify}
                  isCertifyChecked={this.state.checkCertify}
                  show={this.props.showCustomsForm}
                  toggleForm={() => this.props.toggleCustomsForm()}
                  errors={this.props.customsErrors}
                />
              </>
            ) : (
              <div
                className={
                  "template-customs-add d-flex flex-row justify-content-between align-items-center"
                }
              >
                <h3>Customs Declaration</h3>
                <FiPlus onClick={() => this.setState({ useCustoms: true })} />
              </div>
            )}
          </div>
          <div className="content-container card-section d-flex flex-row align-items-center">
            <div
              className={`check-template  p-0 d-flex justify-content-center align-items-center ${this.state.checkSignature ? "active" : ""}`}
            >
              <div
                className="template-checkbox d-flex justify-content-center align-items-center"
                onClick={() => this.checkSignature(this.props.parcels)}
              >
                <Check />
              </div>
            </div>
            <label
              className={"form-checkbox mb-0"}
              onClick={() =>
                this.props.updateExtras(
                  "requireSignature",
                  !this.props.parcels.selectedExtras.requireSignature,
                )
              }
            >
              <div
                className={`check mr-2 ${this.props.parcels.selectedExtras.requireSignature ? "active" : ""}`}
              >
                <Check />
              </div>
              <p>Requires Signature</p>
            </label>
          </div>
          <div className="content-container card-section">
            <div className="template-create-action d-flex flex-column">
              {this.state.templateError && (
                <p className="text-danger">{this.state.templateError}</p>
              )}
              <div
                className={`${this.props.labelTemplate.selectType === "edit" ? "edit-actions" : ""} d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center`}
              >
                <button
                  className="p-0 order-lg-2"
                  onClick={() => this.createTemplate()}
                >
                  Save Template
                </button>
                {this.props.labelTemplate.selectType === "edit" && (
                  <button
                    className="p-0 order-lg-1"
                    onClick={async () => {
                      await this.setState({
                        duplicate: true,
                      });
                      this.createTemplate();
                    }}
                  >
                    Save Template as New
                  </button>
                )}
                <button
                  className="button-secondary p-0 order-lg-0"
                  onClick={() => this.toggleTemplate()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ newTemplate, labelTemplate, parcels, customs }) => ({
  newTemplate,
  labelTemplate,
  parcels,
  customs,
});

export default compose(
  withCookies,
  connect(mapStateToProps, {
    updateTemplateName,
    updateTemplateUse,
    updateNewTemplate,
    clearNewTemplate,
    clearSelectedTemplate,
    createTemplate,
    updateExtras,
    selectTemplate,
    editTemplate,
    saveEditableTemplate,
  }),
)(LabelTemplate);
