import React, { useCallback } from "react";
import { Link, matchPath, Route, Switch, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import WelcomeTour from "./WelcomeTour";
import "../../scss/help-center.scss";
import { connect } from "react-redux";

const HelpCenter = (props) => {
  const { pathname } = useLocation();

  const match = (path) => {
    return matchPath(pathname, { path, exact: true });
  };

  const WelcomeTourWithProps = useCallback(
    () => <WelcomeTour fullName={props.full_name} />,
    [],
  );

  return (
    <div className={"help-center d-flex flex-row"}>
      <div className="menu-container d-none d-lg-flex flex-column">
        <div className="d-flex flex-column">
          <h4>Onboarding</h4>
          <Link
            to={ROUTES.WELCOME_TOUR}
            className={`${match(ROUTES.WELCOME_TOUR) || match(ROUTES.HELP_CENTER) ? "active" : ""}`}
          >
            Welcome Tour
          </Link>
          {/*<Link >*/}
          {/*    Shipping Label*/}
          {/*</Link>*/}
        </div>

        {/*<div className="d-flex flex-column">*/}
        {/*    <h4>Call Center</h4>*/}
        {/*    <Link >Call Center Africa</Link>*/}
        {/*</div>*/}
      </div>
      <div className="help-center-content p-0 ml-auto d-flex">
        <Switch>
          <Route path={ROUTES.WELCOME_TOUR} component={WelcomeTourWithProps} />
          <Route path={ROUTES.HELP_CENTER} component={WelcomeTourWithProps} />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = ({ userDetails }) => ({
  full_name: userDetails.info.full_name,
});

export default connect(mapStateToProps, {})(HelpCenter);
