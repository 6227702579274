import {
  CONTACT_FROM_CLEAR_FROM,
  CONTACT_FROM_SUBMIT,
  CONTACT_FROM_SUBMIT_FAILURE,
  CONTACT_FROM_SUBMIT_SUCCESS,
  CONTACT_FROM_UPDATE_FIELD,
  GET_FAQS,
  GET_FAQS_FAILURE,
  GET_FAQS_SUCCESS,
  MAILING_LIST_ADD_EMAIL,
  MAILING_LIST_ADD_EMAIL_FAILURE,
  MAILING_LIST_ADD_EMAIL_SUCCESS,
  MAILING_LIST_CLEAR_FROM,
  MAILING_LIST_UPDATE_EMAIL,
} from "../../constants/types";

const INITIAL_MAILING_LIST_STATE = {
  mailingListEmail: "",
  loading: false,
  errors: "",
  added: false,
};

export function mailingListReducer(state = INITIAL_MAILING_LIST_STATE, action) {
  switch (action.type) {
    case MAILING_LIST_UPDATE_EMAIL:
      return { ...state, mailingListEmail: action.payload, errors: "" };
    case MAILING_LIST_ADD_EMAIL:
      return { ...state, loading: true, errors: "" };
    case MAILING_LIST_ADD_EMAIL_SUCCESS:
      return { ...state, loading: false, added: true, mailingListEmail: "" };
    case MAILING_LIST_ADD_EMAIL_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case MAILING_LIST_CLEAR_FROM:
      return { ...state, loading: false, added: false, mailingListEmail: "" };
    default:
      return state;
  }
}

const INITIAL_CONTACT_FORM_STATE = {
  email: "",
  fName: "",
  lName: "",
  phone: "",
  subject: "",
  description: "",
  loading: false,
  errors: "",
  success: false,
};

export function contactFormReducer(state = INITIAL_CONTACT_FORM_STATE, action) {
  switch (action.type) {
    case CONTACT_FROM_UPDATE_FIELD:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
        errors: "",
      };
    case CONTACT_FROM_SUBMIT:
      return { ...state, loading: true, errors: "" };
    case CONTACT_FROM_CLEAR_FROM:
      return INITIAL_CONTACT_FORM_STATE;
    case CONTACT_FROM_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case CONTACT_FROM_SUBMIT_SUCCESS:
      return { ...state, loading: false, errors: "", success: true };
    default:
      return state;
  }
}

const INITIAL_FAQ_STATE = {
  errors: "",
  loading: false,
  faqs: [],
};

export function faqReducer(state = INITIAL_FAQ_STATE, action) {
  switch (action.type) {
    case GET_FAQS:
      return { ...state, loading: true, errors: "" };
    case GET_FAQS_SUCCESS:
      return { ...state, loading: false, errors: "", faqs: action.payload };
    case GET_FAQS_FAILURE:
      return { ...state, loading: true, errors: action.payload };
    default:
      return state;
  }
}
