import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_SUCCESS,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_DETAIL,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_DETAIL_SUCCESS,
  SEARCH_TRANSACTION_TEXT_ENTERED,
  SELECT_ACCOUNT,
  SET_TRANSACTIONS_PAGE_SIZE,
  GET_TRANSACTIONS_DETAIL_FAILURE,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import { paginationHandler, parseError } from "./ActionHelpers";
import axios from "axios";

const sortAccounts = (accounts) => {
  if (!accounts) return [];
  const zeroBalance = accounts
    ?.filter((account) => parseFloat(account.balance) === 0)
    .sort((acc1, acc2) => {
      if (acc1.currency === "KES") return -1;
      if (acc2.currency === "KES") return 1;
      return 0;
    });
  const amountBalance = accounts
    ?.filter((account) => parseFloat(account.balance) !== 0)
    .sort((acc1, acc2) => {
      if (acc1.currency === "KES") return -1;
      if (acc2.currency === "KES") return 1;
      if (parseFloat(acc1.balance) > parseFloat(acc2.balance)) return -1;
      if (parseFloat(acc1.balance) < parseFloat(acc2.balance)) return 1;
      return 0;
    });

  return amountBalance.concat(zeroBalance);
};

export const getAccounts = (onSuccess) => (dispatch) => {
  dispatch({
    type: GET_ACCOUNTS,
  });

  instance
    .get("/payments/accounts")
    .then((res) => {
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        payload: sortAccounts(res.data),
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch({
        type: GET_ACCOUNTS_FAILURE,
        payload: parseError(err),
      });
    });
};

export const getTransactionDetails =
  (transactionID, onSuccess) => (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS_DETAIL,
    });

    instance
      .get("/payments/accounts")
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_DETAIL_SUCCESS,
          payload: res.data,
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        dispatch({
          type: GET_TRANSACTIONS_DETAIL_FAILURE,
          payload: parseError(err),
        });
      });
  };

const CancelToken = axios.CancelToken;
let cancel;

export const getTransactions =
  (onSuccess, pageSize = 10, search = "", filters = "", account = "KES") =>
  (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS,
    });

    let searchURL = "";
    if (search && search !== "") {
      searchURL = "&search=" + search;
    }
    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(
        `/payments/transactions/currency/${account}?page_size=${pageSize}${searchURL}${filterURL}`,
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        if (err.code !== "ERR_CANCELED") {
          dispatch({
            type: GET_TRANSACTIONS_FAILURE,
            payload: parseError(err),
          });
        }
      });
  };

export const getTransactionsByPage =
  (
    onSuccess,
    pageNo,
    pageSize = 10,
    search = "",
    filters = "",
    account = "KES",
  ) =>
  (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS,
    });

    let searchURL = "";
    if (search && search !== "") {
      searchURL = "&search=" + search;
    }
    let filterURL = "";
    if (filters && filters !== "") {
      filterURL = "&" + filters;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(
        `/payments/transactions/currency/${account}?page_size=${pageSize}&page=${pageNo}${searchURL}${filterURL}`,
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_TRANSACTIONS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        if (err.code !== "ERR_CANCELLED") {
          dispatch({
            type: GET_TRANSACTIONS_FAILURE,
            payload: parseError(err),
          });
        }
      });
  };

export const selectAccount = (account, onSuccess) => (dispatch) => {
  dispatch({
    type: SELECT_ACCOUNT,
    payload: account,
  });
  if (onSuccess) onSuccess();
};

export const searchTransactions = (searchPhrase) => (dispatch) => {
  dispatch({
    type: SEARCH_TRANSACTION_TEXT_ENTERED,
    payload: searchPhrase,
  });
};

export const setTransactionsPageSize = (size) => {
  return {
    type: SET_TRANSACTIONS_PAGE_SIZE,
    payload: size,
  };
};
