import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { ReactComponent as Map } from "../../../assets/map-pin.svg";
import { ReactComponent as Calender } from "../../../assets/calender.svg";
import LOGO from "../../../assets/box-in-logo-green.svg";
import { clearLabelCreationErrors } from "../../actions";
import { isNumber } from "chart.js/helpers";
class LabelSummary extends Component {
  componentDidMount() {
    this.props.clearLabelCreationErrors();
  }

  render() {
    const {
      service: { name, provider, provider_image },
      amount,
      currency,
      estimated_days,
    } = this.props.selectedRate;

    return (
      <Card className="label-summary my-3 p-3">
        <div className="d-flex flex-row justify-content-between">
          {this.props.showCarriers ? (
            <div className="d-flex flex-row align-items-center">
              <img src={provider_image} alt={"ShipShap Rate"} />
              <div className="provider d-flex flex-column">
                <h5 className="mb-0">{provider}</h5>
                <p className="mb-0">{name}</p>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center">
              <img src={LOGO} alt={"ShipShap Rate"} />
              <div className="provider d-flex flex-column">
                <h5 className="mb-0">ShipShap</h5>
                <p className="mb-0">{`${this.props.senderAddress?.country === this.props.receiverAddress?.country ? "Local" : "International"} Rate`}</p>
              </div>
            </div>
          )}
          <h5 className="price-box">{`${parseFloat(amount).toFixed(2)} ${currency}`}</h5>
        </div>
        <hr />
        <div className="address-info">
          <div className="address d-flex flex-row align-contents-center">
            <div className="map-pin mt-3">
              <Map className="" />
            </div>
            <div>
              <p className="address-tag mb-0">From</p>
              <h5>{this.props.senderAddress.name}</h5>
              <p>
                {`${this.props.senderAddress.street1},`}
                {this.props.senderAddress.street2
                  ? ` #${this.props.senderAddress.street2},`
                  : null}
                {` ${this.props.senderAddress.city}, ${this.props.senderAddress.state} ${this.props.senderAddress.zip_code}, ${this.props.senderAddress.country}`}
              </p>
            </div>
          </div>
          <div className="address d-flex flex-row align-contents-center">
            <div className="map-pin mt-3">
              <Map className="" />
            </div>
            <div>
              <p className="address-tag mb-0">To</p>
              <h5>{this.props.receiverAddress.name}</h5>
              <p>
                {`${this.props.receiverAddress.street1},`}
                {this.props.receiverAddress.street2
                  ? ` #${this.props.receiverAddress.street2},`
                  : null}
                {` ${this.props.receiverAddress.city}, ${this.props.receiverAddress.state} ${this.props.receiverAddress.zip_code}, ${this.props.receiverAddress.country}`}
              </p>
            </div>
          </div>
        </div>
        {isNumber(estimated_days) && (
          <>
            <hr />
            <div className="info d-flex flex-row align-contents-start">
              <Calender className="calender" />
              {estimated_days > 1
                ? `Estimated time for delivery ${estimated_days} days`
                : "Same day delivery"}
            </div>
          </>
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({ rateChoices, senderAddress, receiverAddress }) => ({
  selectedRate: rateChoices.selectedRate,
  senderAddress,
  receiverAddress,
});

export default connect(mapStateToProps, { clearLabelCreationErrors })(
  LabelSummary,
);
