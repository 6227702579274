import React, { useEffect, useRef, useState } from "react";
import "../scss/business-intro.scss";
import { InlineWidget } from "react-calendly";
import { ReactComponent as IntroLogo } from "../assets/Intro-Logo.svg";
import * as ROUTES from "../constants/routes";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiMinus,
  FiMail,
  FiPlus,
  FiX,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getFreshdeskFAQs } from "./actions";
import Accordion from "react-bootstrap/Accordion";
import { Spinner } from "react-bootstrap";
import {
  BusinessIntroFooterSocials,
  BusinessIntroHeader,
} from "./BusinessIntro";

const BusinessIntroFAQs = (props) => {
  useEffect(() => {
    props.getFreshdeskFAQs();
  }, []);

  return (
    <div className={"business-intro-faq"}>
      <BusinessIntroHeader />
      <div
        className={
          "hero-section d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
        }
      >
        <div>
          <h2>Shipshap FAQs</h2>
        </div>
      </div>
      <div className={"faq-list"}>
        <Accordion>
          {props.faqs.map((item, idx) => {
            return (
              <Accordion.Item eventKey={idx} key={idx}>
                <Accordion.Header>
                  {`${idx + 1}. ${item.title}`}
                  <FiPlus className={"plus"} />
                  <FiMinus className={"minus"} />
                </Accordion.Header>
                <Accordion.Body>{item.description_text}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
        {props.loading && (
          <div className={"loadings d-flex flex-row align-items-center m-auto"}>
            <Spinner animation={"grow mr-3"} size="sm" />
            <Spinner animation={"grow mr-3"} size="sm" />
            <Spinner animation={"grow mr-3"} size="sm" />
          </div>
        )}
      </div>
      <div className={"footer"}>
        <div>
          <div className={"about-description"}>
            <Link className="navbar-brand" to={ROUTES.LANDING}>
              <IntroLogo />
            </Link>
            <p>
              Shipshap gives you the infrastructure to effectively manage all
              your order fulfillment and payment needs.
            </p>
          </div>
          <div className={"services-links"}>
            <h4>Services</h4>
            <Link to={ROUTES.INTRO}>Deliveries</Link>
            <Link to={ROUTES.INTRO}>E-Commerce Integrations</Link>
            <Link to={ROUTES.INTRO}>Payments</Link>
          </div>
          <div className={"info"}>
            <h4>Info</h4>
            <Link to={ROUTES.INTRO_FAQ}>FAQ</Link>
            <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
            <Link to={ROUTES.TERMS}>Terms & Conditions</Link>
          </div>
        </div>
        <div className={"divider"} />
        <BusinessIntroFooterSocials />
      </div>
    </div>
  );
};

const mapStateToProps = ({ faqs }) => ({
  loading: faqs.loading,
  errors: faqs.errors,
  faqs: faqs.faqs,
});

export default connect(mapStateToProps, { getFreshdeskFAQs })(
  BusinessIntroFAQs,
);
