import axios from "axios";
import { Cookies } from "react-cookie";
import { auth } from "./firebase";
import { store } from "../App";
import { FIREBASE_AUTH } from "../constants/types";

const instance = axios.create({
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  baseURL: process.env.REACT_APP_CORE_URL
    ? "https://" + process.env.REACT_APP_CORE_URL
    : "http://localhost:8000",
});

instance.interceptors.request.use(async (request) => {
  if (
    store.getState().applicationContext.context.auth === FIREBASE_AUTH &&
    (await auth()?.currentUser)
  ) {
    await auth()
      .currentUser?.getIdToken()
      .then((token) => {
        request.headers = {
          ...request.headers,
          Authorization: `WebToken ${token}`,
        };
      })
      .catch((err) => {
        console.log("Error: Failed to get User authentication");
        console.log(err);
      });
  }

  return request;
});

export default instance;
