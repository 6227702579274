import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
const configData = require("./firebase-config.json");

export const app = firebase.initializeApp(configData);

export const auth = firebase.auth;
export const functions = firebase.functions();
export const analytics = firebase.analytics;
