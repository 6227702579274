import {
  ADD_PARCEL_ITEM,
  CLEAR_PARCEL_ITEM,
  DELETE_PARCEL_ITEM,
  EDIT_PARCEL_ITEM,
  LABEL_CREATE_SUCCESS,
  SELECT_PARCEL_ITEM,
  UPDATE_PARCEL_DECLARATION,
  UPDATE_PARCEL_DIMENSION,
  UPDATE_PARCEL_TYPE,
  UPDATE_PARCEL_WEIGHT,
  UPDATE_PARCEL_WEIGHT_UNITS,
  UPDATE_SELECTED_PARCEL,
  UPDATE_SHIPMENT_EXTRA,
  UPDATE_TEMPLATE_PARCEL,
} from "../../constants/types";

export const selectParcel = ({ parcel }) => {
  return { type: UPDATE_SELECTED_PARCEL, payload: parcel };
};

export const updateTemplateUse = (useTemplate) => {
  return { type: UPDATE_TEMPLATE_PARCEL, payload: useTemplate };
};

export const updatePackageType = (parcelTypeIndex) => {
  return { type: UPDATE_PARCEL_TYPE, payload: parcelTypeIndex };
};

export const updateParcelWeight = (weight) => {
  return {
    type: UPDATE_PARCEL_WEIGHT,
    payload: weight,
  };
};

export const updateParcelDimension = (dimension) => {
  return {
    type: UPDATE_PARCEL_DIMENSION,
    payload: dimension,
  };
};

export const updateParcelUnits = (units) => {
  return {
    type: UPDATE_PARCEL_WEIGHT_UNITS,
    payload: units,
  };
};

export const updateDeclaration = (declaration) => {
  return {
    type: UPDATE_PARCEL_DECLARATION,
    payload: declaration,
  };
};

export const updateExtras = (prop, value) => {
  return { type: UPDATE_SHIPMENT_EXTRA, payload: { prop, value } };
};

export const clearParcelForm = () => {
  return {
    type: CLEAR_PARCEL_ITEM,
  };
};

export const editParcelItem = (item) => {
  return {
    type: EDIT_PARCEL_ITEM,
    payload: item,
  };
};

export const deleteParcelItem = (idx) => {
  return {
    type: DELETE_PARCEL_ITEM,
    payload: idx,
  };
};

export const addParcelItem = (item) => async (dispatch) => {
  return await dispatch({
    type: ADD_PARCEL_ITEM,
    payload: item,
  });
};

export const selectParcelItem = (idx) => {
  return {
    type: SELECT_PARCEL_ITEM,
    payload: idx,
  };
};
