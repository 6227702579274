import {
  CLEAR_CHECKOUT_CONFIG,
  CLEAR_CHECKOUT_CONFIG_ERRORS,
  CLEAR_PRODCUT_IMAGES,
  CLEAR_SELECTED_PRODUCT,
  CREATE_CHECKOUTS,
  CREATE_CHECKOUTS_FAILURE,
  CREATE_CHECKOUTS_SUCCESS,
  CREATE_PRODUCTS_FAILURE,
  CREATE_PRODUCTS_SUCCESS,
  GET_CHECKOUTS,
  GET_CHECKOUTS_FAILURE,
  GET_CHECKOUTS_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_SELECTED_CHECKOUTS,
  GET_SELECTED_CHECKOUTS_FAILURE,
  GET_SELECTED_CHECKOUTS_SUCCESS,
  LOGOUT_SUCCESS,
  SELECT_CHECKOUT_CONFIG,
  SELECT_PRODUCT,
  SELECT_PRODUCT_IMAGES,
  SESSION_AUTH_LOGOUT,
  SET_CREATE_CONFIG_DATA,
  SET_CREATE_PRODUCT_DATA,
  UPLOAD_PRODCUT_IMAGES,
  UPLOAD_PRODCUT_IMAGES_FAILURE,
  UPLOAD_PRODCUT_IMAGES_SUCCESS,
} from "../../constants/types";
import { CHECKOUTS_PRODUCTS } from "../../constants/routes";

const INITIAL_STATE = {
  errors: null,
  productErrors: null,
  loading: false,
  loadingProducts: false,
  cartConfigs: [],
  selectedConfig: {
    margin_multiplier: 1,
    name_to_show_customers: "",
    shop_logo: "",
    default_address_from: {},
    currency: "KES",
    url: "",
    buyer_pays_delivery_fee: true,
  },
  products: [],
  selectedProduct: {
    title: "",
    description: "",
    price: "",
    weight: "",
    weight_unit: "kg",
    length: "",
    width: "",
    height: "",
    length_unit: "cm",
  },
  uploading: false,
  progress: 0,
  images: [],
};

export function checkoutsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CHECKOUTS:
      return { ...state, loading: true, errors: null };
    case GET_CHECKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        cartConfigs: action.payload,
      };
    case GET_CHECKOUTS_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case GET_SELECTED_CHECKOUTS:
      return { ...state, loading: true, errors: null };
    case GET_SELECTED_CHECKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        selectedConfig: action.payload,
      };
    case GET_SELECTED_CHECKOUTS_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case CREATE_CHECKOUTS:
      return { ...state, loading: true, errors: null };
    case CREATE_CHECKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        selectedConfig: action.payload,
      };
    case CREATE_CHECKOUTS_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case SET_CREATE_CONFIG_DATA:
      return {
        ...state,
        selectedConfig: {
          ...state.selectedConfig,
          [action.payload.field]: action.payload.value,
        },
      };
    case SELECT_CHECKOUT_CONFIG:
      return { ...state, selectedConfig: action.payload };
    case CLEAR_CHECKOUT_CONFIG:
      return {
        ...state,
        selectedConfig: INITIAL_STATE.selectedConfig,
        products: INITIAL_STATE.products,
        selectedProduct: INITIAL_STATE.selectedProduct,
        errors: null,
      };
    case CLEAR_CHECKOUT_CONFIG_ERRORS:
      return { ...state, errors: null };
    case GET_PRODUCTS:
      return { ...state, loadingProducts: true, productErrors: null };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        productErrors: null,
        products: action.payload,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        productErrors: action.payload,
      };
    case SELECT_PRODUCT:
      const item = {
        ...action.payload,
        price: action.payload.variations
          ? action.payload.variations[0]?.price
          : "",
      };
      return { ...state, selectedProduct: item };
    case SET_CREATE_PRODUCT_DATA:
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          [action.payload.field]: action.payload.value,
        },
      };
    case CLEAR_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: INITIAL_STATE.selectedProduct,
        images: INITIAL_STATE.images,
        productErrors: null,
      };
    case CHECKOUTS_PRODUCTS:
      return { ...state, loadingProducts: true, productErrors: null };
    case CREATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        productErrors: null,
        selectedProduct: action.payload,
      };
    case CREATE_PRODUCTS_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        productErrors: action.payload,
      };
    case SELECT_PRODUCT_IMAGES:
      return { ...state, images: action.payload };
    case UPLOAD_PRODCUT_IMAGES:
      return {
        ...state,
        loadingProducts: true,
        uploading: true,
        progress: 0,
        productErrors: null,
      };
    case UPLOAD_PRODCUT_IMAGES_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        progress: 1,
        productErrors: null,
      };
    case UPLOAD_PRODCUT_IMAGES_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        uploading: false,
        productErrors: action.payload,
      };
    case CLEAR_PRODCUT_IMAGES:
      return { ...state, images: INITIAL_STATE.images, productErrors: null };
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
