import moment from "moment";
import Popup from "./Popup";
import React, { useState } from "react";

export const DateRange = ({ dateTo, dateFrom, setDateTo, setDateFrom }) => {
  const [showDate, setShowDate] = useState(false);
  const [dateElement, setDateElement] = useState(null);

  const getDateFilter = (() => {
    let date = "";
    if (dateFrom) {
      date += moment(new Date(dateFrom)).format("MMM DD");
      date += " - ";

      if (dateTo) date += moment(new Date(dateTo)).format("MMM DD");
      else date += moment(new Date()).format("MMM DD");
    }
    return date;
  })();

  return (
    <div className={"date-range-filter"}>
      <div className={"dropdown"}>
        <button
          className={`btn btn-primary dropdown-toggle ${!getDateFilter ? "placeholder" : ""}`}
          onClick={(e) => {
            setDateElement(e.target);
            setShowDate(!showDate);
          }}
        >
          {getDateFilter || "Date Range"}
        </button>
      </div>

      <Popup
        element={dateElement}
        show={showDate}
        close={() => {
          setShowDate(false);
          setDateElement(null);
        }}
        className={`date-filter-selection flex-row align-items-center`}
      >
        <input
          type={"date"}
          value={dateFrom}
          onChange={(e) => {
            setDateFrom(e.target.value);
          }}
          max={moment(dateTo ? dateTo : new Date()).format("YYYY-MM-DD")}
        />
        <input
          type={"date"}
          value={dateTo}
          onChange={(e) => {
            if (!dateFrom || moment(e.target.value).isBefore(dateFrom)) {
              setDateFrom(e.target.value);
            }
            setDateTo(e.target.value);
          }}
          max={moment(new Date()).format("YYYY-MM-DD")}
        />
      </Popup>
    </div>
  );
};
