import React, { Component } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CARD_LIST, CARD_PAY } from "../../../constants/types";
import {
  removeUserCard,
  getUserDetails,
  setDefaultCard,
  selectCard,
} from "../../actions";
import VISA from "../../../assets/visa_card.svg";
import MASTERCARD from "../../../assets/mastercard.svg";
import AMEX from "../../../assets/amex_card.svg";
import DISCOVER from "../../../assets/discover_card.svg";
import { FiCreditCard, FiTrash2 } from "react-icons/fi";

const StripeCardListItem = (props) => {
  const formatDate = (month, year) => {
    let mon = "";
    if (month < 10) {
      mon = "0" + month;
    } else {
      mon = month.toString();
    }
    let yr = year.toString();
    yr = yr.substr(yr.length - 2, yr.length);

    return mon + "/" + yr;
  };

  const formatCardNo = (cardNo) => {
    return "**** " + cardNo;
  };

  const formatBrand = (brand) => {
    return brand.toUpperCase();
  };

  const getCardIcon = (brand) => {
    switch (brand) {
      case "visa":
        return <img src={VISA} alt="visa" />;
      case "mastercard":
        return <img src={MASTERCARD} alt="mastercard" />;
      case "amex":
        return <img src={AMEX} alt="american express" />;
      case "discover":
        return <img src={DISCOVER} alt="discover" />;
      default:
        return <img src={VISA} alt="visa" />;
    }
  };

  const { details: card, id } = props.card;
  if (card) {
    return (
      <div
        className={`card-list-item card-section ${props.select ? "selectable" : ""}`}
        key={id}
        onClick={props.select ? () => props.selectCard(props.card) : null}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div
            className={`${!props.select ? "col-7" : "col-12"} p-0 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-start align-items-md-center`}
          >
            <div className="col-12 col-md-6 p-0 d-flex flex-row justify-content-start align-items-center">
              <div className="card-icon mr-3 mb-4">
                {Object.keys(card)?.length > 0 ? (
                  getCardIcon(card.brand)
                ) : (
                  <FiCreditCard />
                )}
              </div>
              <div>
                <p className="d-none d-md-flex">Card Number</p>
                <p className="info">
                  {Object.keys(card)?.length > 0
                    ? formatCardNo(card.last4)
                    : "****"}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 p-0 d-flex flex-row justify-content-start align-items-center">
              <div>
                <p>Exp Date</p>
                <p className="info">
                  {Object.keys(card)?.length > 0
                    ? formatDate(card.exp_month, card.exp_year)
                    : "mm/yy"}
                </p>
              </div>
            </div>
          </div>
          {props.cardActions()}
        </div>
      </div>
    );
  } else return <></>;
};

const mapStateToProps = ({ userDetails }) => ({
  selectedCard: userDetails.selectedCard,
  loading: userDetails.defaultLoading,
});

export default connect(mapStateToProps, {})(StripeCardListItem);
