import { analytics } from "../../services/firebase";
import {
  CLEAR_CUSTOMS,
  CREATE_CUSTOMS_DECLARATION,
  CREATE_CUSTOMS_DECLARATION_FAILURE,
  CREATE_CUSTOMS_DECLARATION_SUCCESS,
  DELETE_CUSTOMS_ITEMS,
  EDIT_CUSTOMS_ITEMS,
  SELECT_CUSTOMS_ITEM,
  UPDATE_CUSTOMS_CERTIFIED,
  UPDATE_CUSTOMS_CONTENT_EXPLANATION,
  UPDATE_CUSTOMS_CONTENT_TYPE,
  UPDATE_CUSTOMS_INCOTERM,
  UPDATE_CUSTOMS_ITEMS,
  UPDATE_CUSTOMS_NONDELIVERY,
  UPDATE_CUSTOMS_SIGNER,
} from "../../constants/types";
import instance from "../../services/axiosConfig";

export const createCustomsDeclaration = (customs, onSuccess) => (dispatch) => {
  const {
    contentType,
    contentsExplanation,
    certified,
    customsSigner,
    nonDeliveryOption,
    items,
    incoterm,
  } = customs;
  const requestParams = {
    contents_type: contentType,
    non_delivery_option: nonDeliveryOption,
    certify: certified,
    certify_signer: customsSigner,
    contents_explanation: contentsExplanation,
    incoterm: incoterm,
    items,
  };

  dispatch({ type: CREATE_CUSTOMS_DECLARATION });
  if (requestParams.items.length < 1) {
    dispatch({
      type: CREATE_CUSTOMS_DECLARATION_FAILURE,
      payload: "Add at least one complete customs item information.",
    });
    return;
  }
  if (!requestParams.certify || !requestParams.certify_signer) {
    dispatch({
      type: CREATE_CUSTOMS_DECLARATION_FAILURE,
      payload:
        "You must enter your name and agree that the information provided is true.",
    });
    return;
  }

  instance
    .post("/customs_declaration", requestParams)
    .then((response) => {
      analytics().logEvent(CREATE_CUSTOMS_DECLARATION_SUCCESS);
      declarationSuccess(response.data, onSuccess, dispatch);
    })
    .catch((httpsError) => {
      analytics().logEvent(CREATE_CUSTOMS_DECLARATION_FAILURE);
      declarationFailure(httpsError, dispatch);
    });
};

const declarationSuccess = ({ data }, onSuccess, dispatch) => {
  if (data.success) {
    // update the page to show the status
    dispatch({ type: CREATE_CUSTOMS_DECLARATION_SUCCESS, payload: data });
    onSuccess();
  } else {
    const errors = "errors" in data ? data.errors : "";
    console.log("got an error while creating customs items: ", errors);
    // display error
    dispatch({ type: CREATE_CUSTOMS_DECLARATION_FAILURE, payload: errors });
  }
};

const declarationFailure = (httpsError, dispatch) => {
  console.log("got an error while creating customs items: ", httpsError);
  // display error
  dispatch({
    type: CREATE_CUSTOMS_DECLARATION_FAILURE,
    payload: "Having connection problems - try again shortly",
  });
};

export const updateContentType = (type) => {
  return {
    type: UPDATE_CUSTOMS_CONTENT_TYPE,
    payload: type,
  };
};

export const updateContentExplanation = (explanation) => {
  return {
    type: UPDATE_CUSTOMS_CONTENT_EXPLANATION,
    payload: explanation,
  };
};

export const updateItems = (items) => {
  return {
    type: UPDATE_CUSTOMS_ITEMS,
    payload: items,
  };
};

export const updateSigner = (signer) => {
  return {
    type: UPDATE_CUSTOMS_SIGNER,
    payload: signer,
  };
};

export const updateCertified = (certified) => {
  return {
    type: UPDATE_CUSTOMS_CERTIFIED,
    payload: certified,
  };
};

export const updateIncoterm = (incoterm) => {
  return {
    type: UPDATE_CUSTOMS_INCOTERM,
    payload: incoterm,
  };
};

export const updateNonDelivery = (nondelivery) => {
  return {
    type: UPDATE_CUSTOMS_NONDELIVERY,
    payload: nondelivery,
  };
};

export const clearCustoms = () => {
  return { type: CLEAR_CUSTOMS };
};

export const editCustomsItems = (item, itemIdx) => {
  return {
    type: EDIT_CUSTOMS_ITEMS,
    payload: {
      item,
      itemIdx,
    },
  };
};
export const deleteCustomsItems = (itemIdx) => {
  return {
    type: DELETE_CUSTOMS_ITEMS,
    payload: itemIdx,
  };
};

export const selectCustomsItem = (itemIdx) => {
  return {
    type: SELECT_CUSTOMS_ITEM,
    payload: itemIdx,
  };
};
