import { functions } from "../../services/firebase";
import {
  CLEAR_LABEL_CREATION_ERRORS,
  CLEAR_SEARCH_LABEL,
  GET_ALL_LABEL_SUCCESS,
  GET_GRAPH_DATA,
  GET_GRAPH_DATA_FAILED,
  GET_GRAPH_DATA_SUCCESS,
  GET_LABEL,
  GET_LABEL_ERROR,
  GET_LABEL_SUCCESS,
  SEARCH_LABEL_TEXT_ENTERED,
  SET_LABELS_PAGE_SIZE,
} from "../../constants/types";
import axios from "axios";
import instance from "../../services/axiosConfig";
import { paginationHandler, parseError } from "./ActionHelpers";
//TODO: Fix unused imports
// import * as ROUTES from '../../constants/routes';
// import history from "../../History";

export const getLabel = (objectId) => async (dispatch) => {
  clearLabelSearch();
  dispatch({ type: GET_LABEL });
  const getLabelRequest = { label_id: objectId };
  instance
    .post("v1/shipping-labels/?search=", getLabelRequest)
    .then((response) => {
      getLabelSuccess(dispatch, response);
    })
    .catch((httpsError) => {
      getLabelFailed(dispatch, httpsError);
    });
};

const getLabelFailed = (dispatch, error) => {
  console.log("got an error while getting label");
  console.log(error);
  const errMsg = parseError(error);
  dispatch({
    type: GET_LABEL_ERROR,
    payload: { errors: errMsg },
  });
};

const getLabelSuccess = (dispatch, { data }) => {
  if (data.success) {
    dispatch({ type: GET_LABEL_SUCCESS, payload: data.label });
  } else {
    const errors = "errors" in data ? data.errors : "";
    dispatch({
      type: GET_LABEL_ERROR,
      payload: { errors },
    });
  }
};

export const selectLabel = (searchText) => (dispatch) => {
  dispatch({
    type: SEARCH_LABEL_TEXT_ENTERED,
    payload: searchText,
  });
  //TODO: Navigate to label view screen
  // history.push(ROUTES.HOME + ROUTES.LABEL + '/specificLabel');
};

export const searchLabels = (searchText) => async (dispatch) => {
  dispatch({
    type: SEARCH_LABEL_TEXT_ENTERED,
    payload: searchText,
  });
};

export const clearLabelSearch = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_LABEL,
  });
};

export const getLabelsForChart = (startDate) => (dispatch) => {
  dispatch({ type: GET_GRAPH_DATA });
  instance
    .get("labels/summary")
    .then((response) => {
      dispatch({
        type: GET_GRAPH_DATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const errMsg = parseError(err);
      dispatch({
        type: GET_GRAPH_DATA_FAILED,
        payload: { errors: errMsg },
      });
    });
};

const getChartDataSucces = (dispatch, { data }) => {
  if (data.success) {
    dispatch({
      type: GET_GRAPH_DATA_SUCCESS,
      payload: data.labels_by_day,
    });
  } else {
    dispatch({
      type: GET_GRAPH_DATA_FAILED,
      payload: { errors: "Having connection problems - try again shortly" },
    });
  }
};

const getChartDataFailed = (dispatch, error) => {
  dispatch({
    type: GET_GRAPH_DATA_FAILED,
    payload: { errors: error },
  });
};

const CancelToken = axios.CancelToken;
let cancel;

// * Get All Labels
export const getAllLabels =
  (pageSize = 10, search = "") =>
  (dispatch) => {
    dispatch({ type: GET_LABEL });
    let searchURL = "";
    if (search !== "") {
      searchURL = "&search=" + search;
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(`v1/shipping-labels/?page_size=${pageSize}${searchURL}`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_LABEL_SUCCESS,
          payload: paginationHandler(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: GET_LABEL_ERROR,
          payload: { errors: errMsg },
        });
      });
  };
export const getLabelsByPage =
  (onSuccess, pageNo, pageSize = 10, search = "") =>
  (dispatch) => {
    dispatch({ type: GET_LABEL });

    let searchURL = "";
    if (search !== "") {
      searchURL = "&search=" + search;
    }

    instance
      .get(
        `v1/shipping-labels/?page_size=${pageSize}&page=${pageNo}${searchURL}`,
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_LABEL_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        const errMsg = parseError(err);
        dispatch({
          type: GET_LABEL_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

const getLabelListFailed = (dispatch, error) => {
  console.log("got an error while getting all the labels");
  console.log(error);
  dispatch({
    type: GET_LABEL_ERROR,
    payload: { errors: "Having connection problems - try again shortly" },
  });
};

const getLabelListSuccess = (dispatch, { data }) => {
  if (data.success) {
    dispatch({ type: GET_ALL_LABEL_SUCCESS, payload: data.labels });
  } else {
    const errors = "errors" in data ? data.errors : "";
    dispatch({
      type: GET_LABEL_ERROR,
      payload: { errors },
    });
  }
};

export const setLabelsPageSize = (size) => (dispatch) => {
  dispatch({
    type: SET_LABELS_PAGE_SIZE,
    payload: size,
  });
};

export const clearLabelCreationErrors = () => {
  return {
    type: CLEAR_LABEL_CREATION_ERRORS,
  };
};
