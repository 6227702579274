import {
  CHECK_TEMPLATE_INTRO,
  CLEAR_LABEL_TEMPLATE,
  CLEAR_NEW_LABEL_TEMPLATE,
  CLEAR_SELECTED_LABEL_TEMPLATE,
  CREATE_LABEL_TEMPLATE,
  DELETE_TEMPLATE,
  EDIT_LABEL_TEMPLATE,
  SAVE_EDITABLE_LABEL_TEMPLATE,
  SELECT_LABEL_TEMPLATE,
  UPDATE_LABEL_TEMPLATE_CERTIFIED,
  UPDATE_LABEL_TEMPLATE_CONTENT,
  UPDATE_LABEL_TEMPLATE_DECLATRATION,
  UPDATE_LABEL_TEMPLATE_EXTRAS,
  UPDATE_LABEL_TEMPLATE_INCOTERM,
  UPDATE_LABEL_TEMPLATE_ITEMS,
  UPDATE_LABEL_TEMPLATE_NAME,
  UPDATE_LABEL_TEMPLATE_NON_DELIVERY,
  UPDATE_LABEL_TEMPLATE_PACKAGE_TYPE,
  UPDATE_LABEL_TEMPLATE_PARCEL,
  UPDATE_LABEL_TEMPLATE_SIGNER,
  UPDATE_LABEL_TEMPLATE_WEIGHT,
  UPDATE_NEW_LABEL_TEMPLATE,
} from "../../constants/types";

export const updateNewTemplate = (template) => {
  return {
    type: UPDATE_NEW_LABEL_TEMPLATE,
    payload: template,
  };
};

export const updateTemplateName = (value) => {
  return {
    type: UPDATE_LABEL_TEMPLATE_NAME,
    payload: value,
  };
};

export const createTemplate = (template, onSuccess) => (dispatch) => {
  dispatch({
    type: CREATE_LABEL_TEMPLATE,
    payload: template,
  });
  if (onSuccess) onSuccess();
};

export const selectTemplate = (template, onSuccess) => (dispatch) => {
  const temp = { ...template };
  dispatch({
    type: SELECT_LABEL_TEMPLATE,
    payload: temp,
  });
  if (onSuccess) onSuccess();
};

export const clearNewTemplate = () => {
  return {
    type: CLEAR_NEW_LABEL_TEMPLATE,
  };
};
export const clearSelectedTemplate = () => {
  return {
    type: CLEAR_SELECTED_LABEL_TEMPLATE,
  };
};

export const deleteTemplate = (id, onSuccess) => (dispatch) => {
  dispatch({
    type: DELETE_TEMPLATE,
    payload: id,
  });
  if (onSuccess) onSuccess();
};

export const checkTemplateIntro = () => {
  return {
    type: CHECK_TEMPLATE_INTRO,
  };
};

export const editTemplate = (template, onSuccess) => (dispatch) => {
  dispatch({
    type: EDIT_LABEL_TEMPLATE,
    payload: template,
  });
  if (onSuccess) onSuccess();
};

export const saveEditableTemplate = (template, onSuccess) => (dispatch) => {
  dispatch({
    type: SAVE_EDITABLE_LABEL_TEMPLATE,
    payload: template,
  });

  if (onSuccess) onSuccess();
};
