import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import DOCS from "../../../assets/declaration-types.json";
import INCOTERM from "../../../assets/incoterm-options.json";
import NONDELIVERY from "../../../assets/nondelivery-options.json";
import {
  updateContentExplanation,
  updateContentType,
  updateCertified,
  updateIncoterm,
  updateNonDelivery,
  updateSigner,
  clearCustoms,
  createCustomsDeclaration,
  editCustomsItems,
  deleteCustomsItems,
  selectCustomsItem,
} from "../../actions";

import { ReactComponent as Check } from "../../../assets/check.svg";
import { FiAlertCircle, FiInfo } from "react-icons/fi";
import CustomsItemFormItem from "./CustomsItemFormItem";

class CustomsInfo extends Component {
  state = {
    errors: { valid: true },
    customsReason: "",
  };

  componentDidMount() {
    this.setState({ errors: { ...this.props.errors } });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({ errors: this.props.errors });
    }
  }

  renderIncoTerm = () => {
    const list = Object.keys(INCOTERM).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => this.props.updateIncoterm(item)}
        >
          {INCOTERM[item].name}
        </Dropdown.Item>
      );
    });
    return (
      <div>
        <label>Incoterm Option</label>
        <Dropdown>
          <Dropdown.Toggle className="col-12 p-0 px-3 d-flex flex-row justify-content-between align-items-center">
            {INCOTERM[this.props.customs.incoterm].name}
          </Dropdown.Toggle>
          <Dropdown.Menu>{list}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  renderNonDelivery = () => {
    const list = Object.keys(NONDELIVERY).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => this.props.updateNonDelivery(item)}
        >
          {NONDELIVERY[item].name}
        </Dropdown.Item>
      );
    });
    return (
      <div>
        <label> Non-Delivery Option</label>
        <Dropdown>
          <Dropdown.Toggle className="col-12 p-0 px-3 d-flex flex-row justify-content-between align-items-center">
            {NONDELIVERY[this.props.customs.nonDeliveryOption].name}
          </Dropdown.Toggle>
          <Dropdown.Menu>{list}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderDocument = () => {
    const list = Object.keys(DOCS).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => {
            this.props.updateContentType(item);
            if (item !== "OTHER") {
              this.props.updateContentExplanation("");
            }
          }}
        >
          {DOCS[item].name}
        </Dropdown.Item>
      );
    });

    return (
      <div className="col-12 p-0">
        <label>Content</label>
        <div className="col-12 p-0 d-flex flex-row justify-content-between ">
          <Dropdown className="col-12 p-0">
            <Dropdown.Toggle className=" d-flex flex-row justify-content-between align-items-center">
              {DOCS[this.props.customs.contentType].name}
            </Dropdown.Toggle>
            <Dropdown.Menu>{list}</Dropdown.Menu>
          </Dropdown>
        </div>
        {this.props.customs.contentType === "OTHER" && (
          <textarea
            className="col-12"
            placeholder="Explanation"
            value={this.props.customs.contentsExplanation}
            onChange={(e) =>
              this.props.updateContentExplanation(e.target.value)
            }
          />
        )}
      </div>
    );
  };

  isMilitaryAddress(address) {
    if (address.country === "US" || address.country === "USA") {
      const state = address.state.toUpperCase().trim();
      if (["AA", "AE", "AP"].includes(state)) {
        return true;
      }
      const city = address.city.toUpperCase().trim();
      if (["APO", "FPO", "DPO"].includes(city)) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { country: fromCountry } = this.props.sender;
    const { country: toCountry } = this.props.receiver;
    const isMilitaryShipment = this.isMilitaryAddress(this.props.receiver);
    let text;
    if (
      this.props.sender.country !== this.props.receiver.country ||
      isMilitaryShipment
    ) {
      if (isMilitaryShipment) {
        text =
          "This is a military shipment 🇺🇸." +
          " A customs declaration information is required.";
      } else {
        text =
          `This is an international package from ${fromCountry} to ${toCountry}.` +
          " A customs declaration information is required.";
      }
    }

    if (this.props.show || this.props.template) {
      return (
        <div className="customs-info ">
          {!this.props.template && (
            <div
              className={
                "info d-flex flex-row justify-content-start align-items-center"
              }
            >
              <FiInfo size={20} />
              <p className={"mb-0"}>
                Custom Declaration form depends on your package destination
              </p>
            </div>
          )}
          {!this.props.template && (
            <>
              <h3>Customs Declaration</h3>
              {<p className={"customs-reasons"}>{text}</p>}
            </>
          )}
          <div
            className={`template-fields ${this.state.errors?.contents_explanation ? "show-error" : ""}`}
          >
            {this.props.template && (
              <div
                className={`check-template ${this.props.isContentChecked ? "active" : ""}`}
              >
                <label>&nbsp;</label>
                <div
                  className="template-checkbox d-flex justify-content-center align-items-center"
                  onClick={() => this.props.checkContent(this.props.customs)}
                >
                  <Check />
                </div>
              </div>
            )}
            <div className="d-flex flex-row">{this.renderDocument()}</div>
          </div>

          <div className="template-fields">
            {this.props.template && (
              <div
                className={`check-template ${this.props.isIncoTermChecked ? "active" : ""}`}
              >
                <label>&nbsp;</label>
                <div
                  className="template-checkbox d-flex justify-content-center align-items-center"
                  onClick={() => this.props.checkIncoTerm(this.props.customs)}
                >
                  <Check />
                </div>
              </div>
            )}
            {this.renderIncoTerm()}
          </div>
          <div className="template-fields">
            {this.props.template && (
              <div
                className={`check-template ${this.props.isNonDeliverableChecked ? "active" : ""}`}
              >
                <label>&nbsp;</label>
                <div
                  className="template-checkbox d-flex justify-content-start align-items-start"
                  onClick={() =>
                    this.props.checkNonDeliverable(this.props.customs)
                  }
                >
                  <Check />
                </div>
              </div>
            )}
            {this.renderNonDelivery()}
          </div>
          <div
            className={`template-fields ${this.state.errors?.signer ? "show-error" : ""}`}
          >
            {this.props.template && (
              <div
                className={`check-template ${this.props.isSignerChecked ? "active" : ""}`}
              >
                <label>&nbsp;</label>
                <div
                  className="template-checkbox d-flex justify-content-center align-items-center"
                  onClick={() => this.props.checkSigner(this.props.customs)}
                >
                  <Check />
                </div>
              </div>
            )}
            <label className="d-flex flex-column">
              <label>Signer</label>
              <input
                value={this.props.customs.customsSigner}
                onChange={(e) => this.props.updateSigner(e.target.value)}
              />
            </label>
            <p className={"text-danger"}>
              {
                "You must enter your name and agree that the information provided is true."
              }
            </p>
          </div>
          <div
            className={`template-fields customs-items-list ${this.state.errors.items ? "show-error" : ""}`}
          >
            {this.props.template && (
              <div
                className={`check-template ${this.props.isItemsChecked ? "active" : ""}`}
              >
                <label>&nbsp;</label>
                <div
                  className="template-checkbox d-flex justify-content-center align-items-center"
                  onClick={() => this.props.checkItems(this.props.customs)}
                >
                  <Check />
                </div>
              </div>
            )}
            <div>
              <label>Item Details</label>
              {this.props.customs.items?.length > 0 && (
                <div>
                  {this.props.customs.items.map((item, idx) => {
                    return (
                      <CustomsItemFormItem
                        key={idx}
                        item={item}
                        itemIdx={idx}
                        editItem={() => {
                          this.props.selectCustomsItem(idx);
                          this.props.toggleForm();
                        }}
                        removeItem={() => this.props.deleteCustomsItems(idx)}
                      />
                    );
                  })}
                </div>
              )}
              <button
                className="customs-item d-flex justify-content-center align-items-center"
                onClick={() => this.props.toggleForm()}
              >
                Add Customs Item
              </button>
              <p className={"text-danger"}>
                {"Add at least one complete customs item information."}
              </p>
            </div>
          </div>
          <div
            className={`template-fields ${this.state.errors?.certified ? "show-error" : ""}`}
          >
            {this.props.template && (
              <div
                className={`check-template ${this.props.isCertifyChecked ? "active" : ""}`}
              >
                <div
                  className="template-checkbox d-flex justify-content-center align-items-center"
                  onClick={() => this.props.checkCertify(this.props.customs)}
                >
                  <Check />
                </div>
              </div>
            )}
            <label
              className={`form-checkbox d-flex flex-row align-items-center `}
              onClick={() =>
                this.props.updateCertified(!this.props.customs.certified)
              }
              onKeyDown={() =>
                this.props.updateCertified(!this.props.customs.certified)
              }
            >
              <div
                className={`check mr-2 ${this.props.customs.certified ? "active" : ""}`}
              >
                <Check />
              </div>
              <p className="terms-text mb-0">
                I certify that the information provided is accurate and true
              </p>
            </label>
            <p className={"text-danger"}>
              {
                "You must enter your name and agree that the information provided is true."
              }
            </p>
          </div>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = ({
  customs,
  parcels,
  senderAddress,
  receiverAddress,
}) => ({
  customs,
  parcels,
  sender: senderAddress,
  receiver: receiverAddress,
});

export default connect(mapStateToProps, {
  updateContentExplanation,
  updateContentType,
  updateCertified,
  updateIncoterm,
  updateNonDelivery,
  updateSigner,
  clearCustoms,
  createCustomsDeclaration,
  editCustomsItems,
  deleteCustomsItems,
  selectCustomsItem,
})(CustomsInfo);
