import {
  DISCONNECT_STORE,
  DISCONNECT_STORE_FAILURE,
  DISCONNECT_STORE_SUCCESS,
  GET_CONNECTED_STORES,
  GET_CONNECTED_STORES_FAILURE,
  GET_CONNECTED_STORES_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  LOGOUT_SUCCESS,
  SEARCH_LABEL_TEXT_ENTERED,
  SEARCH_ORDER_TEXT_ENTERED,
  SELECT_ORDER,
  SET_ORDERS_PAGE_SIZE,
  UPDATE_STORE_NAME,
} from "../../constants/types";

const INITIAL_STATE = {
  stores: [],
  orders: [],
  ordersResponse: {},
  selectedOrder: null,
  name: "",
  loading: false,
  loadingOrders: false,
  errors: "",
  searchText: "",
  currentPage: 1,
  pageSize: 10,
};

export const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_STORE_NAME:
      return { ...state, name: action.payload };
    case GET_CONNECTED_STORES:
      return { ...state, loading: true, errors: "" };
    case GET_CONNECTED_STORES_SUCCESS:
      return { ...state, loading: false, errors: "", stores: action.payload };
    case GET_CONNECTED_STORES_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case GET_ORDERS:
      return { ...state, loadingOrders: true, errors: "" };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        loadingOrders: false,
        errors: "",
        orders: action.payload.list,
        ordersResponse: action.payload.response,
        currentPage: action.payload.currentPage,
      };
    case GET_ORDERS_FAILURE:
      return { ...state, loadingOrders: false, errors: action.payload };
    case SELECT_ORDER:
      return { ...state, selectedOrder: action.payload };
    case DISCONNECT_STORE:
      return { ...state, loading: true, errors: "" };
    case DISCONNECT_STORE_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case DISCONNECT_STORE_SUCCESS:
      return { ...state, loading: false, errors: "" };
    case SEARCH_ORDER_TEXT_ENTERED:
      if (action.payload.trim() === "") {
        return { ...state, searchText: "" };
      }
      return { ...state, searchText: action.payload };
    case SET_ORDERS_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
