import React from "react";
import { connect } from "react-redux";
import "../../../scss/template.scss";
import { FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import { CUSTOM_PACKAGE_I } from "../../../constants/types";
import DOCS from "../../../assets/declaration-types.json";
import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";
import UNITS from "../../../assets/mass-units.json";
import {
  clearSelectedTemplate,
  deleteTemplate,
  editTemplate,
  selectTemplate,
} from "../../actions";
import { ReactComponent as EmptyList } from "../../../assets/empty-template-list.svg";
import { useCookies } from "react-cookie";
import { getTotalWeightInUnit } from "../common/helpers";

const TemplateListItem = (props) => {
  const getNumberOfPackages = () => {
    let count = 0;
    props.item?.parcels.forEach((parcel) => {
      count += parseInt(parcel.quantity) || 1;
    });
    return count;
  };

  return (
    <>
      <div className={"template-item card-section"}>
        <div
          className={
            "title d-flex flex-row justify-content-between align-items-center"
          }
        >
          <p className={"template-name"}>{props.item.name}</p>
          <div className={"template-actions"}>
            <FiEdit2 size={35} onClick={() => props.edit()} />
            <FiTrash2 size={35} onClick={() => props.delete()} />
          </div>
        </div>
        <div className={"template-details"}>
          {"packageType" in props.item ? (
            <div>
              <p className={"info"}>Package Details</p>
              {
                <p>
                  {props.item.parcels?.length > 0
                    ? `${getNumberOfPackages()} package detail saved`
                    : `No package details saved`}
                </p>
              }
              {props.item.parcels?.length > 0 && (
                <p>{`${getTotalWeightInUnit(props.item, props.item.parcels[0].weight_unit)} ${UNITS[props.item.parcels[0].weight_unit]}`}</p>
              )}
            </div>
          ) : (
            <p className={"info"}>No Type Selected</p>
          )}

          <div>
            {props.item.declaration ? (
              <>
                <p className={"info"}>Custom Declared</p>
                {props.item.declaration.contentType && (
                  <p>{`${DOCS[props.item.declaration.contentType].name}`}</p>
                )}
                {props.item.declaration.items ? (
                  <p>{`${props.item.declaration.items.length} items`}</p>
                ) : (
                  <p>No Customs Items</p>
                )}
              </>
            ) : (
              <p className={"info"}>No Customs Declared</p>
            )}
          </div>
          <div className={"template-item-actions"}>
            {props.selected ? (
              <p className={"selected"}>Template in use</p>
            ) : (
              <a onClick={() => props.select()}>Use Template</a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const TemplateList = (props) => {
  const deleteTemplate = (item) => {
    if (item) {
      if (item?.id === props.selected?.id) props.clearSelectedTemplate();
      props.deleteTemplate(item.id, () => {});
    }
  };

  const [cookies, setCookie, removeCookie] = useCookies();

  return (
    <div className="modal d-flex">
      <div
        className="backdrop"
        onClick={() => {
          props.toggleList();
        }}
      ></div>
      <div
        className={`modal-content template-list settings-card ${cookies.is_test ? "test-mode" : "live-mode"}`}
      >
        <div
          className={
            "card-section head d-flex flex-row justify-content-between align-items-center"
          }
        >
          <h3>Template List</h3>
          <div>
            <a onClick={() => props.toggleCreate()}>Add New Template</a>
            <a onClick={() => props.toggleList()}>
              <FiX size={55} />
            </a>
          </div>
        </div>
        <div className={"list-content"}>
          {props.list?.length ? (
            props.list.map((item, idx) => {
              return (
                <TemplateListItem
                  key={idx}
                  item={item}
                  select={() =>
                    props.selectTemplate(item, () => {
                      props.toggleList();
                    })
                  }
                  delete={() => deleteTemplate(item)}
                  edit={() =>
                    props.editTemplate(item, () => {
                      props.toggleCreate();
                    })
                  }
                  selected={
                    props.selected && item.id === props.selected.id
                      ? true
                      : false
                  }
                />
              );
            })
          ) : (
            <div
              className={
                "empty-list d-flex flex-column align-items-center justify-content-around"
              }
            >
              <div
                className={
                  "d-flex flex-column align-items-center justify-content-around"
                }
              >
                <EmptyList />
                <p>No Templates</p>
              </div>
              <button onClick={() => props.toggleCreate()}>
                Add Template Added
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ labelTemplate, parcels }) => ({
  list: labelTemplate.templates,
  selected: labelTemplate.selectedTemplate,
  parcels,
});

export default connect(mapStateToProps, {
  selectTemplate,
  deleteTemplate,
  clearSelectedTemplate,
  editTemplate,
})(TemplateList);
