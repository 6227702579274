import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import masthead from "../../assets/shopify-masthead.jpg";
import ETHIO from "../../assets/ethioair-icon.svg";
import DHL from "../../assets/dhl-icon.svg";
import USPS from "../../assets/usps-icon.svg";
import UPS from "../../assets/ups-icon.svg";
import appStore from "../../assets/appstore-badge.png";
import playStore from "../../assets/google-play-badge.png";
import shop_signup from "../../assets/shop-signup.png";
import signup_mobile_0 from "../../assets/signup-mobile-0.png";
import shopify_step_1 from "../../assets/shopify-step-1.png";
import shopify_step_2 from "../../assets/shopify-step-2.png";
import signup_mobile_3 from "../../assets/signup-mobile-2.png";
import signup_mobile_4 from "../../assets/signup-mobile-4.png";
import shopify_section_3 from "../../assets/shopify-section-3.png";
import shopify_section_4 from "../../assets/shopify-section-4.png";
import shopify_section_5 from "../../assets/shopify-section-5.svg";
import shopify_section_6 from "../../assets/shopify-section-6.jpg";
import { ReactComponent as LabelIcon } from "../../assets/tag-icon.svg";

const Shopify = (props) => {
  const [step, setSlide] = useState(0);

  // useEffect(()=>{
  //   window.scrollTo(0,0)
  // },[])

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((step + 1) % 5);
    }, 3000);

    return () => clearInterval(interval);
  }, [step]);

  return (
    <div
      className="landing shopify integrations scrollspy-landing"
      data-bs-spy="scroll"
      data-bs-target="#navbar-landing"
      data-bs-smooth-scroll="true"
      data-offset="50"
      tabIndex="0"
    >
      <section className="masthead" id="masthead">
        <div className="col-12 p-0 d-flex flex-column flex-lg-row ">
          <div className="content col-12 col-lg-5 d-flex flex-column justify-content-start justify-content-lg-center align-items-start order-1 order-lg-0 ">
            <div className="main">
              <h1>
                Fulfill Shopify orders
                <br />
                with our Integrated
                <br />
                Platform
              </h1>
              <p className="info">
                Welcome to the most robust platform for fulfulling all your shop
                orders, from domestic to International. Streamline your
                fulfullment process and save time and money by integrating your
                Shopify store with Shipshap.{" "}
              </p>
            </div>
            <div className="action d-flex flex-row align-items-center">
              <Link to={ROUTES.HOME + ROUTES.DASHBOARD}>
                <button>Try It Now</button>
              </Link>
            </div>
          </div>
          <div className="image col-12 col-lg-7 order-0 order-lg-1">
            <img src={masthead} alt="dashboard" />
          </div>
        </div>
      </section>

      {/* <section className="partners d-flex justify-content-center">
      <div className="col-12 col-lg-8 p-0 d-flex flex-row justify-content-around align-items-center">
        <img src={DHL} alt="DHL"/>
        <img src={USPS} alt="USPS"/>
        <img src={ETHIO} alt="Ethiopian Airlines Cargo"/>
      </div>
    </section> */}

      <section
        className="mobile-cta col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between"
        id="mobile-cta"
      >
        <div className="content col-12 col-lg-5">
          <h1>Start Shipping In 8 Minutes</h1>
          <div>
            <ul className="steps d-flex flex-column align-items-center align-items-lg-start">
              <li className={`step-item ${step === 0 ? "active" : ""}`}>
                <p className="title">Sign Up</p>
                <p className="desc">
                  Signing up on ShipShap is easy to use. All you need is your
                  email address, and your basic information.
                </p>
              </li>
              <li className={`step-item ${step === 1 ? "active" : ""}`}>
                <p className="title">Connect Shopify store</p>
                <p className="desc">
                  Easily connect your Shopify store, and order your shippping
                  service seamlessly.
                </p>
              </li>
              <li className={`step-item ${step === 2 ? "active" : ""}`}>
                <p className="title">Auto import orders</p>
                <p className="desc">
                  Sync all your orders from the stores, and manage them all
                  easily with no worries.
                </p>
              </li>
              <li className={`step-item ${step === 3 ? "active" : ""}`}>
                <p className="title">Select Shipping rate</p>
                <p className="desc">
                  Get discounted rates from trusted carriers, and select the
                  rate that works best for you.{" "}
                </p>
              </li>
              <li className={`step-item ${step === 4 ? "active" : ""}`}>
                <p className="title">Advanced Tracking</p>
                <p className="desc">
                  Address validation, tracking and notifications to keep you and
                  your customers up-to-date.{" "}
                </p>
              </li>
            </ul>
          </div>
          <div className="buttons d-none d-lg-flex flex-row align-items-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap"
              className=""
            >
              <img src={playStore} className="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
              className=""
            >
              <img src={appStore} className="" />
            </a>
          </div>
        </div>
        <div className="image col-12 col-lg-7 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <div className="col-12 p-0">
            <img
              src={signup_mobile_0}
              alt={"start shipping"}
              className={`${step === 0 ? "active" : ""}`}
            />
            <img
              src={shopify_step_1}
              alt={"start shipping"}
              className={`${step === 1 ? "active" : ""}`}
            />
            <img
              src={shopify_step_2}
              alt={"start shipping"}
              className={`${step === 2 ? "active" : ""}`}
            />
            <img
              src={signup_mobile_3}
              alt={"start shipping"}
              className={`${step === 3 ? "active" : ""}`}
            />
            <img
              src={signup_mobile_4}
              alt={"start shipping"}
              className={`${step === 4 ? "active" : ""}`}
            />
          </div>
          <div className="dots col-5 flex-row justify-content-between align-items-center d-flex d-lg-none">
            <div className={`${step === 0 ? "active" : ""}`}></div>
            <div className={`${step === 1 ? "active" : ""}`}></div>
            <div className={`${step === 2 ? "active" : ""}`}></div>
            <div className={`${step === 3 ? "active" : ""}`}></div>
            <div className={`${step === 4 ? "active" : ""}`}></div>
          </div>
          <div className="buttons d-flex d-lg-none flex-row align-items-center justify-content-between">
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap"
              className=""
            >
              <img src={playStore} className="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
              className=""
            >
              <img src={appStore} className="" />
            </a>
          </div>
        </div>
      </section>

      <section
        className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center"
        id="shops"
      >
        <div className="col-12 col-lg-6 p-0 m-auto d-flex justify-content-center align-items-center order-lg-1">
          <img src={shopify_section_4} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start order-lg-0">
          <h1>
            One Integrated
            <br />
            Dashboard{" "}
          </h1>
          <p className="info">
            Import dimensions and package info in one easy step. Print
            professional labels straight from your device, and immediately
            recieve an overview of the package status. Shipping logistics have
            never been easier!
          </p>
        </div>
      </section>

      <section
        className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center"
        id="shops"
      >
        <div className="col-12 col-lg-6 p-0 m-auto d-flex justify-content-center align-items-center">
          <img src={shopify_section_5} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
          <h1>
            Never Lose a Package
            <br />
            Again.{" "}
          </h1>
          <p className="info">
            We’re the experts in international shipping: the first in the
            business, we offer address validation and insurance on shipments
            into and out of Africa. Feel secure about your package anywhere in
            the world.{" "}
          </p>
        </div>
      </section>

      <section
        className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center"
        id="shops"
      >
        <div className="col-12 col-lg-6 p-0 m-auto d-flex justify-content-center align-items-center order-lg-1">
          <img src={shopify_section_6} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start order-lg-0">
          <h1>
            Connect to Your
            <br /> Favourite Stores{" "}
          </h1>
          <p className="info">
            Save your time for what matters most. With a wide range of shop
            integrations with some of the biggest merchants in the market,
            you’ll be able to fulfull orders in less time than ever.
          </p>
        </div>
      </section>

      <section className="sign-up col-12 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-start align-items-start order-lg-1">
          <img src={shop_signup} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 p-0 d-flex flex-column justify-content-center align-items-start order-lg-0">
          <h1>Sign up Today!</h1>
          <Link to={ROUTES.HOME + ROUTES.DASHBOARD} className="button">
            Start Shipping Now
          </Link>
          <div className="buttons col-12 col-lg-8 p-0 d-flex flex-row align-items-center justify-content-between">
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap"
              className=""
            >
              <img src={playStore} className="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
              className=""
            >
              <img src={appStore} className="" />
            </a>
          </div>
        </div>
      </section>

      {/* {renderScrollTop()} */}
    </div>
  );
};

export default Shopify;
