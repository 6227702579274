import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import "../scss/_footer.scss";
import { ReactComponent as FbIco } from "../assets/facebook.svg";
import { ReactComponent as IgIco } from "../assets/instagram.svg";
import { ReactComponent as GmIco } from "../assets/mail.svg";
import { ReactComponent as LiIco } from "../assets/linkedin.svg";
import { ReactComponent as TtIco } from "../assets/tiktok.svg";
import * as ROUTES from "../constants/routes";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo.svg";

const Footer = () => (
  <Switch>
    <Route
      path={[
        ROUTES.HOME,
        ROUTES.ACCOUNT_FORMS,
        ROUTES.SIGN_IN,
        ROUTES.SIGN_UP,
        ROUTES.VERIFY_EMAIL,
        ROUTES.PASSWORD_FORGET,
        ROUTES.UPDATE_PASSWORD,
        ROUTES.ADMIN_SIGNIN,
        ROUTES.INTRO,
      ]}
      component={null}
    />
    <Route
      render={() => {
        return (
          <div className="footer">
            <div className="content d-flex flex-column flex-lg-row justify-content-between align-items-start">
              <div className="main-content p-0">
                <Link
                  className="header d-flex flex-row align-items-center"
                  to={ROUTES.LANDING}
                >
                  <div>
                    <BoxLogo />
                  </div>
                  <h2 className="mb-0">ShipShap</h2>
                </Link>
                <p>
                  ShipShap gives you the infrastructure to effectively manage
                  all your fulfillment and shipping needs.
                </p>

                <Link
                  to={ROUTES.CONTACT_US}
                  className=" button d-none d-lg-flex"
                >
                  Contact Us
                </Link>
              </div>
              {/* <div>
						<h5>Our Partners</h5>
						<ul>
							<li><a>DHL</a></li>
							<li><a>UPS</a></li>
						</ul>
					</div> */}
              <div>
                <h5>APIs and Integrations</h5>
                <ul>
                  <li>
                    <Link to={ROUTES.MORE_API}>API Integrations</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.MORE_SHOP}>Shop Integrations</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.MORE_SHOPIFY}>Shopify</Link>
                  </li>
                  <li>
                    Etsy <span className="soon">Coming Soon</span>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Resources</h5>
                <ul>
                  <li>
                    <a
                      href="https://shipshap.freshdesk.com/support/solutions"
                      target={"_blank"}
                    >
                      FAQs
                    </a>
                  </li>
                  {/* <li><a>How-to-Videos</a></li>  */}
                  {/* <li><a>Blogs</a></li>  */}
                  {/* <li><a>Help Center</a></li>  */}
                  {/* <li><a>Refer a Friend</a></li>  */}
                  <li>
                    <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom-banner col-12 d-flex flex-lg-row justify-content-between">
              <p className="p-0">
                &copy; ShipShap {new Date().getFullYear()}. All Rights Reserved.
              </p>
              <div className="links d-flex flex-row">
                <Link to={ROUTES.TERMS}>Terms of Service</Link> |
                <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
              </div>
              <div className="socials flex-row d-flex ">
                <a href="https://www.facebook.com/shipshapApp">
                  <FbIco />
                </a>
                <a href="https://www.instagram.com/shipshapApp/">
                  <IgIco />
                </a>
                <a href="mailto:hello@shipshap.co">
                  <GmIco />
                </a>
                <a href="https://www.tiktok.com/@shipshapapp">
                  <TtIco />
                </a>
                <a href="https://www.linkedin.com/company/shipshap">
                  <LiIco />
                </a>
              </div>
            </div>
          </div>
        );
      }}
    />
  </Switch>
);
export default Footer;
