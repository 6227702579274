import React, { useCallback, useEffect, useState } from "react";
import * as ROUTES from "../../constants/routes";
import {
  Link,
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logoutSession, logOutUser } from "../actions/AuthActions";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import AddressList from "./Address/AddressList";
import AddressForm from "./Address/AddressForm";
import "../../scss/account.scss";
import { FIREBASE_AUTH, PHONEBOOK } from "../../constants/types";
import CardList from "./UserDetails/CardList";
import AccountDetails from "./UserDetails/AccountDetails";
import API from "./Integration/API";
import { useCookies } from "react-cookie";
import Media from "react-media";
import { FiInfo } from "react-icons/fi";
import { getAccountInfo, getAddresses, searchAddresses } from "../actions";
import { getBusinessDetails } from "../actions/BusinessActions";
import { getUserCountry } from "../actions/ApplicationContextActions";
import { missingDetails } from "./common/helpers";
import { MenuContainer } from "./common/MenuContainer";

const Account = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [showForm, toggleForm] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const [cookies] = useCookies();

  useEffect(() => {
    props.getAccountInfo();
    props.getBusinessDetails();
    props.getUserCountry();
  }, []);

  const logOut = () => {
    if (props.context.auth === FIREBASE_AUTH) props.logOutUser();
    else props.logoutSession();
  };

  const match = (path) => {
    return matchPath(pathname, { path, exact: true });
  };

  const AddressListWithProps = useCallback(
    () => (
      <AddressList
        type={PHONEBOOK}
        toggleForm={() => {
          toggleForm(true);
        }}
      />
    ),
    [],
  );
  const AddressFormWithProps = useCallback(
    () => (
      <AddressForm
        type={PHONEBOOK}
        show={showForm}
        toggleForm={() => toggleForm(!showForm)}
        onSuccess={() => {
          props.searchAddresses("");
          props.getAddresses(props.addressSearch);
          toggleForm();
        }}
      />
    ),
    [showForm],
  );

  const CardListWithProps = useCallback(() => {
    return <CardList />;
  }, []);

  const AccountDetailsWithProps = useCallback(
    () => (
      <AccountDetails missingDetails={() => missingDetails(props.details)} />
    ),
    [],
  );

  return (
    <div
      className={`account d-flex flex-column flex-lg-row ${cookies.is_test ? "test-mode" : "live-mode"}`}
    >
      <MenuContainer>
        <div className="d-flex flex-column">
          <h4>Account &amp; Billing</h4>
          <Link
            to={ROUTES.ACCOUNT_DETAILS}
            className={`${match(ROUTES.ACCOUNT_DETAILS) ? "active" : ""}`}
          >
            Account Details
            {missingDetails(props.details) && (
              <OverlayTrigger
                key={"business-details-missing"}
                placement="right"
                overlay={
                  <Tooltip
                    id="business-details-missing-tooltip"
                    className={"business-details-missing-tooltip"}
                  >
                    You are seeing this because you have missing information for
                    your business details
                  </Tooltip>
                }
                delayShow={300}
                delayHide={150}
              >
                <span>
                  <FiInfo className={"warning"} />
                </span>
              </OverlayTrigger>
            )}
          </Link>
          <Link
            to={ROUTES.CARD_LIST}
            className={`${match(ROUTES.CARD_LIST) ? "active" : ""}`}
          >
            Billing
          </Link>
          <Link
            to={ROUTES.ADDRESS_LIST}
            className={`${match(ROUTES.ADDRESS_LIST) ? "active" : ""}`}
          >
            Address Book
          </Link>
        </div>

        <div className="d-flex flex-column">
          <h4>Integrations</h4>
          {/* <Link>Carrier</Link> */}
          <Link
            to={ROUTES.API}
            className={`${match(ROUTES.API) ? "active" : ""}`}
          >
            API
          </Link>
        </div>
      </MenuContainer>
      <div className="accountMain p-0 ml-auto d-flex">
        <Switch>
          <Route
            path={ROUTES.ADDRESS_LIST}
            exact
            component={AddressListWithProps}
          />
          <Route path={ROUTES.CARD_LIST} exact component={CardListWithProps} />
          <Route path={ROUTES.API} exact component={API} />
          <Route
            path={ROUTES.ACCOUNT_DETAILS}
            exact
            component={AccountDetailsWithProps}
          />
          <Route
            path={ROUTES.HOME + ROUTES.ACCOUNT}
            exact
            render={() => {
              return (
                <Media queries={{ small: { maxWidth: 992 } }}>
                  {(matches) =>
                    matches.small ? (
                      <div className="menu-container d-flex d-lg-none flex-column">
                        <div className="d-flex flex-column">
                          <h4>Account &amp; Billing</h4>
                          <Link
                            to={ROUTES.ACCOUNT_DETAILS}
                            className={`${match(ROUTES.ACCOUNT_DETAILS) ? "active" : ""}`}
                          >
                            Account Details
                            {missingDetails(props.details) && (
                              <OverlayTrigger
                                key={"business-details-missing"}
                                placement="left"
                                overlay={
                                  <Tooltip
                                    id="business-details-missing-tooltip"
                                    className={
                                      "business-details-missing-tooltip"
                                    }
                                  >
                                    You are seeing this because you have missing
                                    information for your business details
                                  </Tooltip>
                                }
                                delayShow={300}
                                delayHide={150}
                              >
                                <span>
                                  <FiInfo className={"warning"} />
                                </span>
                              </OverlayTrigger>
                            )}
                          </Link>
                          <Link
                            to={ROUTES.CARD_LIST}
                            className={`${match(ROUTES.CARD_LIST) ? "active" : ""}`}
                          >
                            Billing
                          </Link>
                          <Link
                            to={ROUTES.ADDRESS_LIST}
                            className={`${match(ROUTES.ADDRESS_LIST) ? "active" : ""}`}
                          >
                            Address Book
                          </Link>
                        </div>
                        <div className="d-flex flex-column">
                          <h4>Integrations</h4>
                          {/* <Link>Carrier</Link> */}
                          <Link
                            to={ROUTES.API}
                            className={`${match(ROUTES.API) ? "active" : ""}`}
                          >
                            API
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <Redirect to={ROUTES.ACCOUNT_DETAILS} />
                    )
                  }
                </Media>
              );
            }}
          />
          <Route render={() => <Redirect to={ROUTES.ACCOUNT_DETAILS} />} />
        </Switch>
      </div>
      {showForm && <AddressFormWithProps />}
    </div>
  );
};

const mapStateToProps = ({
  auth,
  businessDetails,
  addressList,
  applicationContext,
}) => ({
  auth,
  details: businessDetails.details,
  addressSearch: addressList.searchText,
  context: applicationContext.context,
});

export default connect(mapStateToProps, {
  logOutUser,
  getAccountInfo,
  getBusinessDetails,
  getUserCountry,
  getAddresses,
  searchAddresses,
  logoutSession,
})(Account);
