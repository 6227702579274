import React, { useState } from "react";
import {
  Link,
  Switch,
  Route,
  withRouter,
  matchPath,
  useLocation,
  useHistory,
} from "react-router-dom";
import * as ROUTES from "../constants/routes";
import "../scss/header.scss";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Navbar,
  Nav,
  Row,
  NavDropdown,
} from "react-bootstrap";
import TEXT_LOGO from "../assets/logo.svg";
import LOGO_GREEN from "../assets/logoGreen.svg";
import { ReactComponent as Menu } from "../assets/menu.svg";
import { DownloadAppLink } from "./DownloadAppButton";
import Header from "./SpecialMarginals";
import SecretSanta from "./SecretSanta";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo.svg";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import Popup from "./Home/common/Popup";
import Dashboard from "./Home/Dashboard";
import { FiCheck } from "react-icons/fi";
import { logoutSession, logOutUser } from "./actions";
import { useCookies } from "react-cookie";
import { FIREBASE_AUTH } from "../constants/types";

const Navigation = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies();

  const match = (path) => matchPath(pathname, { path: `/#${path}` });

  const { user } = props;

  const initals = () => {
    if (user) {
      if (user.displayName) {
        let accName = user.displayName;
        return (
          accName.substring(0, 1) +
          accName.substring(accName.indexOf(" ") + 1, accName.indexOf(" ") + 2)
        );
      } else if (user.email) return user.email.substring(0, 2).toUpperCase();
      else return "AN";
    }
  };

  return (
    <Switch>
      <Route
        path={[
          ROUTES.SIGN_IN,
          ROUTES.SIGN_UP,
          ROUTES.VERIFY_EMAIL,
          ROUTES.ACCOUNT_FORMS,
          ROUTES.PASSWORD_FORGET,
          ROUTES.UPDATE_PASSWORD,
          ROUTES.ADMIN_SIGNIN,
          ROUTES.INTRO,
        ]}
        render={() => {
          return null;
        }}
      />
      <Route
        path={[ROUTES.SECRET_SANTA, ROUTES.TEAM_GIFTS]}
        component={Header}
      />
      <Route
        path={ROUTES.HOME}
        render={() => {
          return (
            <Navbar className="header home shadow d-none m-0" expand="xl">
              <Col xs={12} className="m-auto">
                <Row className="d-flex p-2 justify-content-between flex-row w-100 m-auto">
                  {/* <Col lg={4} xs={6} className="d-flex justify-content-start align-items-center"> */}
                  <Navbar.Brand
                    className="p-0 m-0 d-flex align-items-center"
                    href="#home"
                  >
                    <Link
                      to={ROUTES.LANDING}
                      style={{ textDecoration: "none" }}
                    >
                      <img src={LOGO_GREEN} className="" />
                    </Link>
                  </Navbar.Brand>
                  {/* </Col> */}

                  {/* <Col lg={3} xs={12} className="d-flex"> */}
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="w-auto"
                  />
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end col-12 col-xl-2 p-0"
                  >
                    <Nav className="col-12 d-flex flex-column flex-xl-row justify-content-end m-auto m-xl-0 ml-xl-auto text-center">
                      <Link
                        to={ROUTES.HOME + ROUTES.DASHBOARD}
                        className="nav-link nav-item d-flex justify-content-end align-items-center"
                      >
                        Dashboard
                      </Link>
                      {/*<Link to={ROUTES.HOME + ROUTES.TRACKING}  className='nav-link nav-item d-flex justify-content-end align-items-center'>Tracking</Link>*/}
                      {/*<Link to={ROUTES.HOME + ROUTES.GIFTS} className='nav-link nav-item d-flex justify-content-end align-items-center'>Gifts</Link>*/}
                      <a
                        className="logout-action nav-link nav-item d-flex justify-content-end align-items-center"
                        onClick={() => {
                          if (props.context.auth === FIREBASE_AUTH)
                            props.logOutUser();
                          else props.logoutSession();
                          removeCookie("is_test", {
                            domain:
                              document.location.hostname
                                .split(".")
                                .slice(-2)
                                .join(".") || document.location.hostname,
                            path: "/",
                          });
                          history.push(ROUTES.SIGN_IN);
                        }}
                      >
                        <p>Logout</p>
                      </a>
                      <Link
                        to={ROUTES.HOME + ROUTES.ACCOUNT}
                        className="nav-link nav-item d-flex justify-content-end align-items-center"
                      >
                        <div className="account-initials">{initals()}</div>
                      </Link>
                      {/* <Link href="#signout" className='d-flex align-items-center'>Sign Out</Link> */}
                    </Nav>
                  </Navbar.Collapse>
                  {/* </Col> */}
                </Row>
              </Col>
            </Navbar>
          );
        }}
      />
      <Route
        path={ROUTES.LANDING}
        exact
        render={() => {
          return (
            <div className="header landing col-12">
              <div
                className="navbar navbar-expand-xl flex-row d-flex justify-content-between align-items-center"
                id="navbar-landing"
              >
                <Link
                  className=" navbar-brand d-flex flex-row align-items-center"
                  to={ROUTES.LANDING}
                >
                  <div>
                    <BoxLogo />
                  </div>
                  <h2 className="mb-0">ShipShap</h2>
                </Link>
                <button
                  className="navbar-toggler w-auto"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navList"
                  aria-controls="navList"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <Menu />
                </button>
                <div
                  className="collapse navbar-collapse d-xl-flex align-items-left flex-column flex-xl-row justify-content-end"
                  id="navList"
                >
                  <ul className="col-12 p-0 nav navItems navbar-nav flex-column flex-xl-row d-flex justify-content-between text-center align-items-left">
                    <div className="d-flex flex-column flex-xl-row align-items-xl-center">
                      <a
                        className={`nav-link  ${match("mobile-cta") ? "active" : ""}`}
                        href="#mobile-cta"
                      >
                        <li className="menu nav-item ">How it Works</li>
                      </a>
                      <a
                        className={`nav-link  ${match("shops") ? "active" : ""}`}
                        href="#shops"
                      >
                        <li className="menu nav-item">Shop Integrations</li>
                      </a>
                      <a
                        className={`nav-link  ${match("apis") ? "active" : ""}`}
                        href="#apis"
                      >
                        <li className="menu nav-item">API</li>
                      </a>
                      <a className="drop nav-link" href="#">
                        <li className="menu nav-item">
                          Resources
                          <FiChevronDown
                            size={20}
                            className="d-none d-xl-flex"
                          />
                          <div className="drop-list flex-column">
                            <a
                              className={`nav-link`}
                              href="https://blog.shipshap.com/"
                              target={"_blank"}
                            >
                              <li className="menu nav-item">Blog</li>
                            </a>
                            {/*<a*/}
                            {/*  className={`nav-link`}*/}
                            {/*  href="https://shipshap.freshdesk.com/support/solutions"*/}
                            {/*  target={"_blank"}*/}
                            {/*>*/}
                            {/*  <li className="menu nav-item">FAQ</li>*/}
                            {/*</a>*/}
                            {/* <a className={`nav-link`}><li className='menu nav-item'>Blogs</li></a> */}
                          </div>
                        </li>
                      </a>
                    </div>
                    {user ? (
                      <Link to={ROUTES.HOME} className="headerButton logIn ">
                        <li className="nav-item ">Home</li>
                      </Link>
                    ) : (
                      <div>
                        <Link
                          to={ROUTES.SIGN_IN}
                          className="headerButton logIn "
                        >
                          <li className="nav-item ">Sign In</li>
                        </Link>
                        <Link
                          to={ROUTES.CONTACT_US}
                          className="headerButton sign-up "
                        >
                          <li className="nav-item ">Contact Us for Beta</li>
                        </Link>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          );
        }}
      />
      <Route
        render={() => {
          return (
            <div className="header landing col-12">
              <div
                className="navbar navbar-expand-xl flex-row d-flex justify-content-between align-items-center"
                id="navbar-landing"
              >
                <Link
                  className=" navbar-brand d-flex flex-row align-items-center"
                  to={ROUTES.LANDING}
                >
                  <div>
                    <BoxLogo />
                  </div>
                  <h2 className="mb-0">ShipShap</h2>
                </Link>
                <ul className="nav navItems navbar-nav flex-column flex-xl-row d-flex justify-content-between text-center align-items-left">
                  <DownloadAppLink styling={"headerButton download "} />
                </ul>
              </div>
            </div>
          );
        }}
      />
    </Switch>
  );
};

const mapStateToProps = ({ auth, applicationContext }) => ({
  user: auth.user,
  context: applicationContext.constructor,
});

export default connect(mapStateToProps, { logOutUser, logoutSession })(
  withRouter(Navigation),
);
