import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import { connect } from "react-redux";
import { sendResetLink } from "./actions/AuthActions";
import { ReactComponent as BoxLogo } from "../assets/box-in-logo-green.svg";
import { ReactComponent as BoxLogoWhite } from "../assets/box-in-logo.svg";
import { ReactComponent as GlobeVisual } from "../assets/auth-globe.svg";
import { Line } from "react-chartjs-2";
import history from "../History";
import { Spinner } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";

const PasswordForgetPage = (props) => {
  return (
    <div className="auth login d-flex flex-row">
      <div
        className={
          "col-12 col-lg-5 d-none d-lg-flex flex-column align-items-center"
        }
      >
        <Link
          className="header d-flex flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogo />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>

        <div className={"intro d-flex flex-column align-items-center"}>
          <div className={"visual d-flex flex-row align-items-center"}>
            <GlobeVisual />
          </div>

          <div className={"text-center"}>
            <p className={"info"}>
              We connect you with the best shipping rates and streamline the
              order fulfillment process for you, from importing your orders, to
              printing a label, to tracking: all from the convenience of your
              device. Shipping from Africa and beyond has never been easier
            </p>
          </div>
        </div>
      </div>
      <div className="patch col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-left justify-content-around">
        <Link
          className="header d-flex d-lg-none flex-row align-items-center"
          to={ROUTES.LANDING}
        >
          <div>
            <BoxLogoWhite />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </Link>
        <div className="box container col-10 col-lg-6 d-flex flex-column justify-content-center align-items-start text-left">
          <h3 className="signlabel text-center "> Password Reset</h3>
          {props.error && props.error !== "" && (
            <div className={"error-block"}>
              <FiAlertCircle />
              {props.error}
            </div>
          )}
          <PasswordForgetForm form="" frmElt="" loading={props.loading} />
          <div className={"switchForm"}>
            <Link to={ROUTES.SIGN_IN}>Sign In</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    this.setState({
      loading: true,
    });
    this.props.sendResetLink(email);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit">
          {this.props.loading ? (
            <Spinner animation={"border"} className={"m-0"} />
          ) : (
            "Reset Password"
          )}
        </button>
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <Link to={ROUTES.PASSWORD_FORGET} className="forgotLink">
    Forgot Password?
  </Link>
);

const mapStateToProps = ({ auth }) => ({
  error: auth.error,
  loading: auth.loading,
});

export default connect(mapStateToProps, null)(PasswordForgetPage);

const PasswordForgetForm = connect(null, { sendResetLink })(
  PasswordForgetFormBase,
);

export { PasswordForgetForm, PasswordForgetLink };
