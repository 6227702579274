// import stripe from 'tipsi-stripe';
import {
  BILLED_ALWAYS,
  GET_LABEL_SUCCESS,
  LABEL_CREATE_SUCCESS,
  MPESA,
  PURCHASE_LABEL,
  PURCHASE_LABEL_ERROR,
  UPDATE_FILE_TYPE,
  UPDATE_PURCHASE_EMAIL,
} from "../../constants/types";
import { functions, analytics } from "../../services/firebase";
import axios from "axios";
import axiosConfig from "../../services/axiosConfig";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";

export const updatePurchaseEmail = (email) => {
  return { type: UPDATE_PURCHASE_EMAIL, payload: email };
};

export const updateFileType = (type) => {
  return { type: UPDATE_FILE_TYPE, payload: type };
};

const checkCredits = async (payment_id) => {
  return new Promise((resolve) => {
    if (!!payment_id) {
      let status = null;
      const interval = setInterval(() => {
        if (status && status === "complete") {
          clearInterval(interval);
          resolve();
        } else if (!status || status === "pending") {
          instance
            .get(`payments/credits/${payment_id}`)
            .then((res) => {
              status = res.data?.state.toString().toLowerCase() || null;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          clearInterval(interval);
          console.log("Something went wrong while checking for payment status");
          resolve();
        }
      }, 2000);
      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 30000);
    } else {
      resolve();
    }
  });
};

export const purchaseRateCard = (
  {
    rate,
    email,
    promo,
    method,
    giftExchangeId,
    file_type,
    order_id,
    shipment,
    amount_to_be_collected,
  },
  card,
  billing_type,
  onSuccess,
) => {
  return async (dispatch) => {
    dispatch({ type: PURCHASE_LABEL });
    const code = promo ? promo.code : "";
    const rateRequest = { rate, email, file_type };
    if (order_id && order_id !== "") {
      rateRequest.order_id = order_id;
    }
    if (amount_to_be_collected && amount_to_be_collected > 0) {
      rateRequest.amount_to_be_collected = amount_to_be_collected;
    }
    console.log("attempting to purchase rate: with request: ", rateRequest);

    if (billing_type === BILLED_ALWAYS) {
      if (!card || !card.is_default) {
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: "Please make sure you have select a payment method",
        });
        return;
      }

      if (card.payment_method_type === MPESA && rate.currency !== "KES") {
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: "MPESA payments can only be made for KES currency rates",
        });
        return;
      }
    }

    instance
      .post("v1/shipping-labels/", rateRequest)
      .then((response) => {
        if (
          response.data.errors?.length > 0 &&
          response.data.labels?.length === 0
        ) {
          const error = response.data.errors.pop();
          console.log(error);
          const errMsg = parseError(error);
          dispatch({
            type: PURCHASE_LABEL_ERROR,
            payload: errMsg,
          });
        } else {
          checkCredits(response?.data?.payment_id).then((res) => {
            purchaseRateSuccess(dispatch, response, method, onSuccess);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        const errMsg = parseError(err);
        dispatch({
          type: PURCHASE_LABEL_ERROR,
          payload: errMsg,
        });
      });
  };
};

const purchaseRateFailed = (dispatch, error, method) => {
  console.log("got an error while purchasing label: ");
  console.log(error);
  //   if (method === 'apple') {
  //     stripe.cancelNativePayRequest();
  //   }

  dispatch({
    type: PURCHASE_LABEL_ERROR,
    payload:
      "Error occurred while processing your payment - please try again later",
  });

  analytics().logEvent(PURCHASE_LABEL_ERROR, { error: "uncaught exception" });
};

const purchaseRateSuccess = (dispatch, { data }, method, onSuccess) => {
  if (data != null) {
    dispatch({ type: GET_LABEL_SUCCESS, payload: data });
    dispatch({
      type: LABEL_CREATE_SUCCESS,
      payload: { promotion: data.promotion },
    });
    console.log("create label successfully: ");
    onSuccess();
    // if (method === 'apple') {
    //   stripe.completeNativePayRequest();
    // }
    //  analytics().logEvent(LABEL_CREATE_SUCCESS, { rate: data.label.rate, method });
  } else {
    // if (method === 'apple') {
    //   stripe.cancelNativePayRequest();
    // }
    const errors =
      "errors" in data
        ? data.errors
        : "Error occurred while processing your payment - please try again later";
    dispatch({
      type: PURCHASE_LABEL_ERROR,
      payload: errors,
    });
    analytics().logEvent(PURCHASE_LABEL_ERROR, { method });
  }
};
