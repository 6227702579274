import React, { useEffect } from "react";
import api_integrations from "../../assets/apis.png";
import postman from "../../assets/shipshap-postman.svg";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import shop_signup from "../../assets/shop-signup.png";
import appStore from "../../assets/appstore-badge.png";
import playStore from "../../assets/google-play-badge.png";

const API = (props) => {
  useEffect(() => {
    // window.scrollTo(0,0)
  }, []);

  return (
    <div
      className="landing api integrations scrollspy-landing"
      data-bs-spy="scroll"
      data-bs-target="#navbar-landing"
      data-bs-smooth-scroll="true"
      data-offset="50"
      tabIndex="0"
    >
      <section className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div className="col-12 col-lg-7 p-0 m-auto d-flex justify-content-center align-items-center order-0 order-lg-1">
          <img src={api_integrations} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start order-1 order-lg-0">
          <h1>API Integrations</h1>
          <p className="info">
            We make integrating every aspect of your business simple and easy.
            Our APIs can be set up in minutes, and make your life so much
            easier, putting all your essential shipping logistic tools in one
            place.{" "}
          </p>
        </div>
      </section>

      <section className="api-steps col-12 d-flex flex-column align-items-center">
        <h1>
          We’ll Guide you Through Every Step. <br />
          Browse API Tutorials and References.
        </h1>
        <div className="steps col-12 col-lg-10 ">
          <div className="card">
            <h4>Create your First Shipment</h4>
            <p>
              Create a shipment, get the best rate for you, and quickly generate
              your first shipping label. Get started in minutes and see how easy
              it is for yourself!
            </p>
            <Link to={ROUTES.HOME + ROUTES.DASHBOARD}>Get Started</Link>
          </div>
          <div className="card">
            <h4>API Reference Library</h4>
            <p>
              All the features you need are available in the Shipshap API
              reference library, along with request and response example.
            </p>
            <a href="mailto:hello@shipshap.co">View Details</a>
          </div>
          <div className="card">
            <h4>Client Libraries</h4>
            <p>
              Swiftly integrate with Shipshap’s API through our client
              libraries. Available in Ruby, PHP, Phyton, Java, C#, NodeJS, and
              Laravel.
            </p>
            <a href="mailto:hello@shipshap.co">Learn More</a>
          </div>
          <div className="card">
            <h4>Help and Support Center</h4>
            <p>
              Never be left in the dark again. Our databases of in-depth
              resources plus our dedicated support team have got you covered.{" "}
            </p>
            <a href="mailto:hello@shipshap.co">Chat With Us</a>
          </div>
        </div>
      </section>

      <section className="shops col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <div className="col-12 col-lg-7 p-0 m-auto d-flex justify-content-center align-items-center order-0 order-lg-1">
          <img src={postman} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-start order-1 order-lg-0">
          <h1>
            Create Better API’s
            <br />
            Faster With Postman.
          </h1>
          <p className="info">
            Use the most trusted software to iterate, design and test your APIs.
            Postman simplifies the entire process and allows you to swiftly test
            API requests during development.{" "}
          </p>
          <a className="button" href="mailto:hello@shipshap.co">
            Run in Postman
          </a>
        </div>
      </section>

      <section className="sign-up col-12 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-start align-items-start ">
          <img src={shop_signup} alt="integrations" />
        </div>
        <div className="content col-12 col-lg-5 p-0 d-flex flex-column justify-content-center align-items-start ">
          <h1>Sign up Today!</h1>
          <Link to={ROUTES.HOME + ROUTES.DASHBOARD} className="button">
            Start Shipping Now
          </Link>
          <div className="buttons col-12 col-lg-8 p-0 d-flex flex-row align-items-center justify-content-between">
            <a
              href="https://play.google.com/store/apps/details?id=com.shipshap"
              className=""
            >
              <img src={playStore} className="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1"
              className=""
            >
              <img src={appStore} className="" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default API;
