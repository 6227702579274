import React from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import "../scss/marginals.scss";
import fbIco from "../assets/facebook (1).svg";
import igIco from "../assets/instagram (1).svg";
import twIco from "../assets/twitter (1).svg";
import gmIco from "../assets/mail (1).svg";
import liIco from "../assets/linkedin (1).svg";
import * as ROUTES from "../constants/routes";
import ttIco from "../assets/tiktok (1).svg";
import { DownloadAppLink } from "./DownloadAppButton";
import { connect } from "react-redux";
import TEXT_LOGO from "../assets/logoBlack.svg";
import MENU from "../assets/menu-black.svg";

const Header = ({ user }) => {
  return (
    <div className="marginals head gifts container-fluid pt-3 pt-xl-4">
      <div
        className="col-11 col-xl-10 p-0 m-auto navbar navbar-expand-xl flex-row d-flex justify-content-between"
        id="navbar-landing"
      >
        <Link to={ROUTES.LANDING}>
          <img src={TEXT_LOGO} className="navbar-brand col-6 col-lg-2 p-0" />
        </Link>
        <button
          className="navbar-toggler w-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navList"
          aria-controls="navList"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={MENU} />
        </button>
        <div
          className="collapse navbar-collapse col-12 col-xl-7 d-xl-flex align-items-left flex-column flex-xl-row justify-content-end m-0"
          id="navList"
        >
          <ul className="nav navItems navbar-nav flex-column flex-xl-row d-flex justify-content-between col-10 w-100 p-0 m-auto m-xl-0 ml-xl-auto h-100 text-center align-items-left">
            <Link to={ROUTES.SECRET_SANTA[7]} className="nav-item">
              <Switch>
                <Route
                  path={ROUTES.SECRET_SANTA}
                  render={() => {
                    return (
                      <li className="nav-item font-weight-bold">INDIVIDUAL</li>
                    );
                  }}
                />
                <Route
                  path={ROUTES.TEAM_GIFTS}
                  render={() => {
                    return <li className="nav-item ">INDIVIDUAL</li>;
                  }}
                />
              </Switch>
            </Link>
            <Link to={ROUTES.TEAM_GIFTS[0]} className="nav-item">
              <Switch>
                <Route
                  path={ROUTES.SECRET_SANTA}
                  render={() => {
                    return <li className="nav-item">CORPORATE</li>;
                  }}
                />
                <Route
                  path={ROUTES.TEAM_GIFTS}
                  render={() => {
                    return (
                      <li className="nav-item font-weight-bold">CORPORATE</li>
                    );
                  }}
                />
              </Switch>
            </Link>
            <li className="nav-item ">
              <DownloadAppLink styling={"headerButton download "} />
            </li>

            {user ? (
              <Link to={ROUTES.HOME} className="headerButton logIn ">
                <li className="nav-item ">Home</li>
              </Link>
            ) : (
              <Link to={ROUTES.SIGN_IN} className="headerButton logIn ">
                <li className="nav-item ">Log In</li>
              </Link>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="marginals foot container-fluid">
      <div className="row container col-12 col-md-11 col-lg-10 m-auto py-4  d-flex align-items-center">
        <div className="col-12 col-md-6 d-flex justify-content-center flex-column order-last order-md-first text-center text-md-left">
          <ul className="nav d-flex align-items-center flex-column flex-md-row">
            <li className="list-inline-item">
              <Link to="/" className="">
                About
              </Link>
            </li>

            {/*<li className="list-inline-item">&sdot;</li>*/}

            {/*<li className="list-inline-item">*/}
            {/*	<Link to="" className="">*/}
            {/*		Contact*/}
            {/*	</Link>*/}
            {/*</li>*/}

            <li className="list-inline-item">&sdot;</li>

            <li className="list-inline-item">
              <Link to="terms" className="">
                Terms of Service
              </Link>
            </li>

            <li className="list-inline-item">&sdot;</li>

            <li className="list-inline-item">
              <Link to="privacy" className="">
                Privacy Policy
              </Link>
            </li>
          </ul>
          <p className="copyrights">
            &copy; ShipShap {new Date().getFullYear()}. All Rights Reserved
          </p>
        </div>
        <div className=" socials col-12 col-md-6 d-flex justify-content-end flex-column">
          <ul className=" d-flex align-items-center flex-row justify-content-center">
            <li className="list-inline-item">
              <Link
                to="https://www.facebook.com/shipshapApp"
                target="_blank"
                className=""
              >
                <img src={fbIco} />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="https://www.instagram.com/shipshapApp/" className="">
                <img src={igIco} />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="https://www.tiktok.com/@shipshapapp" className="">
                <img src={ttIco} />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="mailto:hello@shipshap.co" className="">
                <img src={gmIco} />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="https://www.linkedin.com/company/shipshap" className="">
                <img src={liIco} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(withRouter(Header));
export { Footer };
