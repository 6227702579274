import React, { useEffect, useRef, useState } from "react";
import "../scss/business-intro.scss";
import { InlineWidget } from "react-calendly";
import { ReactComponent as IntroLogo } from "../assets/Intro-Logo.svg";
import { ReactComponent as IntroToggler } from "../assets/intro-header-toggler.svg";
import * as ROUTES from "../constants/routes";
import WorldMap from "../assets/intro-world-map.png";
import api_integration from "../assets/intro-apis.png";
import api_integration_mob from "../assets/intro-apis-mob.png";
import sandals from "../assets/intro-testimonial-sandals.png";
import uncover from "../assets/intro-testimonial-uncover.png";
import mandevu from "../assets/intro-testimonial-mandevu.png";
import shopify from "../assets/shopify-logo.svg";
import woocommerce from "../assets/woo-commerce-logo.svg";
import mpesa from "../assets/mpesa-logo.svg";
import mastercard from "../assets/mastercard.svg";
import visa from "../assets/visa_card.svg";
import { ReactComponent as IntroDeliveries } from "../assets/intro-deliveries-icon.svg";
import { ReactComponent as IntroEcommmerce } from "../assets/intro-ecommerce-icon.svg";
import { ReactComponent as IntroPayments } from "../assets/intro-payments-icon.svg";
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiX,
} from "react-icons/fi";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { Link } from "react-router-dom";

export const BusinessIntroHeader = (props) => {
  return (
    <div
      className={
        "header d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
      }
    >
      <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand" to={ROUTES.LANDING}>
          <IntroLogo />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <IntroToggler />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to={ROUTES.MORE_SHOP}>
                Shop Integration
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={ROUTES.MORE_API}>
                API
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className={"header-contact d-flex flex-row align-items-center"}>
        <div className={"d-flex flex-row align-items-center"}>
          <AiFillPhone />
          +254 729 859 289
        </div>
        <div>
          <AiFillMail />
          hello@shipshap.com
        </div>
      </div>
    </div>
  );
};

export const BusinessIntroFooterSocials = (props) => {
  return (
    <div
      className={
        "socials d-flex flex-column flex-lg-row justify-content-start align-items-start align-content-lg-center justify-content-lg-between"
      }
    >
      <a href={"https://shipshap.com"}>www.shipshap.com</a>
      <div className={"links"}>
        <a href="https://www.facebook.com/shipshapApp">
          <FiFacebook />
        </a>
        <a href="https://www.instagram.com/shipshapapp/">
          <FiInstagram />
        </a>
        <a href="mailto:hello@shipshap.co">
          <FiMail />
        </a>
        <a href="https://www.linkedin.com/company/shipshap">
          <FiLinkedin />
        </a>
      </div>
    </div>
  );
};
const BusinessIntro = (props) => {
  const [servicesSlide, setServicesSlide] = useState(-1);
  const [servicesSlideShowing, setServicesSlideShowing] = useState(0);
  const [slideIntervalCount, setIntervalCount] = useState(0);
  const [testimonialSlideShowing, setTestimonialSlideShowing] = useState(0);

  const serviceInterval = useRef();
  const testimonialTimeout = useRef();
  const servicesElement = useRef();

  useEffect(() => {
    serviceInterval.current = setInterval(() => {
      if (slideIntervalCount >= 2 && servicesSlide === -1) {
        setIntervalCount(0);
        setServicesSlideShowing((servicesSlideShowing + 1) % 3);
      } else {
        setIntervalCount(slideIntervalCount + 1);
      }
    }, 1000);

    return () => {
      clearInterval(serviceInterval.current);
    };
  }, [servicesSlideShowing, slideIntervalCount]);

  useEffect(() => {
    testimonialTimeout.current = setTimeout(() => {
      setTestimonialSlideShowing((testimonialSlideShowing + 1) % 3);
    }, 3000);
    return () => {
      clearTimeout(testimonialTimeout.current);
    };
  }, [testimonialSlideShowing]);

  return (
    <div className={"business-intro"}>
      <BusinessIntroHeader />
      <div
        className={
          "hero-section d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
        }
      >
        <div>
          <h2>
            One-Stop Solution for all e-commerce logistical and payment needs
          </h2>
          <p>
            ShipShap's easy-to-use delivery and payment platform allows you to
            run your online store flawlessly and pain-free. Our integrated
            platform helps you track, manage, and deliver orders to your
            customers seamlessly and professionally both within Kenya and
            internationally.
          </p>
          <h4>Shipshap’s platform will transform your business</h4>
          <ul>
            <li>
              Manage your online store efficiently without having to engage a
              rider or courier on every order
            </li>
            <li>Get affordable delivery rates.</li>
            <li>Save you time so your can focus on growing your business</li>
          </ul>
        </div>
        <div className={"contact-appointment d-none d-lg-block"}>
          <InlineWidget
            url={"https://calendly.com/d/2xx-g9z-6cy/sales-discovery-call"}
          />
        </div>
      </div>
      <div className={"contact-appointment d-block d-lg-none"}>
        <InlineWidget
          url={"https://calendly.com/d/2xx-g9z-6cy/sales-discovery-call"}
        />
      </div>
      <div className={"services"} ref={servicesElement}>
        <div className={"services-list"}>
          <div
            className={`service-item ${servicesSlide === 0 ? "active" : ""} ${servicesSlideShowing === 0 ? "show" : ""}`}
          >
            <IntroDeliveries />
            <h3>Seamless Deliveries</h3>
            <p>
              Automate all your delivery processes with ShipShap's platform. No
              manual work or spreadsheets necessary.
            </p>
            <a onClick={() => setServicesSlide(0)}>Read More</a>
          </div>
          <div
            className={`service-item ${servicesSlide === 1 ? "active" : ""} ${servicesSlideShowing === 1 ? "show" : ""}`}
          >
            <IntroEcommmerce />
            <h3>Integrate on e-commerce</h3>
            <p>
              The Shipshap platform will integrate with your ecommerce platform
              like woocommerce and shopify. Shoppers on the website will be able
              to access delivery rates live.
            </p>
            <a onClick={() => setServicesSlide(1)}>Read More</a>
          </div>
          <div
            className={`service-item ${servicesSlide === 2 ? "active" : ""} ${servicesSlideShowing === 2 ? "show" : ""}`}
          >
            <IntroPayments />
            <h3>Payments</h3>
            <p>
              Receive payments safely and securely through M-pesa and card
              payments such as Visa and Mastercard, both local and
              international.
            </p>
            <a onClick={() => setServicesSlide(2)}>Read More</a>
          </div>
        </div>
        <div className={"carousel-indicators d-flex d-lg-none"}>
          <div
            className={`carousel-indicator-item ${servicesSlideShowing === 0 ? "active" : ""}`}
            onClick={() => {
              clearInterval(serviceInterval.current);
              setServicesSlideShowing(0);
              setIntervalCount(0);
              if (servicesSlide !== -1) {
                setServicesSlide(0);
              }
            }}
          ></div>
          <div
            className={`carousel-indicator-item ${servicesSlideShowing === 1 ? "active" : ""}`}
            onClick={() => {
              clearInterval(serviceInterval.current);
              setServicesSlideShowing(1);
              setIntervalCount(1);
              if (servicesSlide !== -1) {
                setServicesSlide(1);
              }
            }}
          ></div>
          <div
            className={`carousel-indicator-item ${servicesSlideShowing === 2 ? "active" : ""}`}
            onClick={() => {
              clearInterval(serviceInterval.current);
              setServicesSlideShowing(2);
              setIntervalCount(2);
              if (servicesSlide !== -1) {
                setServicesSlide(2);
              }
            }}
          ></div>
        </div>
        <div className={"service-details"}>
          <div
            className={`service-detail-item ${servicesSlide === 0 ? "active" : ""}`}
          >
            <IntroDeliveries />
            <h3>Seamless Deliveries</h3>
            <p>
              Automate all your delivery processes with ShipShap's platform. No
              manual work or spreadsheets necessary. Once an order has been
              placed by your customer, you do not have to call anyone to collect
              the order for delivery. Our delivery partners will collect the
              orders and ensure fast and safe delivery of the goods to your
              customers‘ doorstep. You and your customer are both able to track
              the order. You will get notifications when the order has been
              picked up, when on transit and on delivery.
            </p>
            <a className={"close"} onClick={() => setServicesSlide(-1)}>
              <FiX />
            </a>
          </div>
          <div
            className={`service-detail-item ${servicesSlide === 1 ? "active" : ""}`}
          >
            <IntroEcommmerce />
            <h3>Integrate on e-commerce</h3>
            <p>
              The Shipshap platform will work on your ecommerce platform like
              woocommerce and shopify. Shoppers on the website will be able to
              access delivery rates options that work best for them. The cost
              will then be added to the final bill at checkout.
            </p>
            <h4>Our Integrate on e-commerce Solutions</h4>
            <div>
              <img src={woocommerce} />
              <img src={shopify} />
            </div>
            <a className={"close"} onClick={() => setServicesSlide(-1)}>
              <FiX />
            </a>
          </div>
          <div
            className={`service-detail-item ${servicesSlide === 2 ? "active" : ""}`}
          >
            <IntroPayments />
            <h3>Payments</h3>
            <p>
              Receive payments safely and securelythrough M-pesa and card
              payments such as Visa and Mastercard, both local and
              international. The Shipshap online payments platform is easy to
              set up, secure and integrates seamlessly into your ecommerce
              website or app. <br />
              Additionally, the platform allows you to manage transactions,
              orders, generate receipts for your customers to be emailed or
              printed, as well as generate reports for ease of bookkeeping.
            </p>
            <h4>Our Payment solutions</h4>
            <div>
              <img src={mpesa} />
              <img src={mastercard} />
              <img src={visa} />
            </div>
            <a className={"close"} onClick={() => setServicesSlide(-1)}>
              <FiX />
            </a>
          </div>
        </div>
      </div>
      <div className={"coverage-map"}>
        <p>Coverage</p>
        <h2>From Anywhere to Everywhere</h2>
        <p>
          We give you the peace of mind to know your package will make it there
          safely and on time, anywhere from domestic to international.
        </p>
        <img src={WorldMap} alt={"coverage-map"} />
      </div>
      <div className={"testimonials"}>
        <h2>Our Customers Are Talking</h2>
        <div className={"testimonial-list"}>
          <div
            className={`testimonial-item ${testimonialSlideShowing === 0 ? "show" : ""}`}
          >
            <img src={sandals} />
            <h3>Sandals in Nairobi</h3>
            <p>
              Using this online payment solution has made my running my business
              a lot easier. No more stressing over payments. The online
              transactions are on autopilot – smooth, secure, and fast.This
              platform gets a standing ovation from me!"{" "}
            </p>
          </div>
          <div
            className={`testimonial-item ${testimonialSlideShowing === 1 ? "show" : ""}`}
          >
            <img src={uncover} />
            <h3>Uncover</h3>
            <p>
              Uncover has grown over the last year and we are now exporting
              skincare all over the world. The shipping process from Kenya has
              been so efficient thanks to Shipshap! Our clients are happy and so
              are we.
            </p>
          </div>
          <div
            className={`testimonial-item ${testimonialSlideShowing === 2 ? "show" : ""}`}
          >
            <img src={mandevu} />
            <h3>Mandevu</h3>
            <p>
              We have been able to integrate our WooCommerce website with
              Shipshap’s platform and this has made running our ecommerce
              business so efficient in terms of receiving payments and
              scheduling deliveries. We highly recommend Shipshap to anyone
              running a business online
            </p>
          </div>
        </div>
        <div className={"carousel-indicators d-flex d-lg-none"}>
          <div
            className={`carousel-indicator-item ${testimonialSlideShowing === 0 ? "active" : ""}`}
            onClick={() => {
              clearTimeout(testimonialTimeout.current);
              setTestimonialSlideShowing(0);
            }}
          ></div>
          <div
            className={`carousel-indicator-item ${testimonialSlideShowing === 1 ? "active" : ""}`}
            onClick={() => {
              clearTimeout(testimonialTimeout.current);
              setTestimonialSlideShowing(1);
            }}
          ></div>
          <div
            className={`carousel-indicator-item ${testimonialSlideShowing === 2 ? "active" : ""}`}
            onClick={() => {
              clearTimeout(testimonialTimeout.current);
              setTestimonialSlideShowing(2);
            }}
          ></div>
        </div>
      </div>
      <div
        className={"api-cta d-flex flex-column flex-lg-row align-items-center"}
      >
        <div>
          <h1>Simple & EffectiveAPI Integration</h1>
          <p>
            Streamline your entire order fulfillment process, with our quick and
            easy API integrations that bring all your essentials into one place.
          </p>
          <Link to={ROUTES.MORE_API}>
            <button>Explore APIs</button>
          </Link>
        </div>
        <div className={"col-12 col-lg-6"}>
          <img src={api_integration} className={"d-none d-lg-flex"} />
          <img src={api_integration_mob} className={"d-flex d-lg-none"} />
        </div>
      </div>
      <div className={"footer"}>
        <div>
          <div className={"about-description"}>
            <Link className="navbar-brand" to={ROUTES.LANDING}>
              <IntroLogo />
            </Link>
            <p>
              Shipshap gives you the infrastructure to effectively manage all
              your order fulfillment and payment needs.
            </p>
          </div>
          <div className={"services-links"}>
            <h4>Services</h4>
            <a
              onClick={() => {
                if (servicesElement.current) {
                  servicesElement.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start",
                  });
                  setServicesSlide(0);
                  clearInterval(serviceInterval);
                  setServicesSlideShowing(0);
                  setIntervalCount(0);
                }
              }}
            >
              Deliveries
            </a>
            <a
              onClick={() => {
                if (servicesElement.current) {
                  servicesElement.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start",
                  });
                  setServicesSlide(1);
                  clearInterval(serviceInterval);
                  setServicesSlideShowing(1);
                  setIntervalCount(1);
                }
              }}
            >
              E-Commerce Integrations
            </a>
            <a
              onClick={() => {
                if (servicesElement.current) {
                  servicesElement.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start",
                  });
                  setServicesSlide(2);
                  clearInterval(serviceInterval);
                  setServicesSlideShowing(2);
                  setIntervalCount(2);
                }
              }}
            >
              Payments
            </a>
          </div>
          <div className={"info"}>
            <h4>Info</h4>
            <Link to={ROUTES.INTRO_FAQ}>FAQ</Link>
            <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
            <Link to={ROUTES.TERMS}>Terms & Conditions</Link>
          </div>
        </div>
        <div className={"divider"} />
        <BusinessIntroFooterSocials />
      </div>
    </div>
  );
};

export default BusinessIntro;
