import { connect } from "react-redux";
import React from "react";
import { ReactComponent as Check } from "../../../assets/check.svg";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";
import UNITS from "../../../assets/mass-units.json";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";

const PackageDetailItem = (props) => {
  const renderDimension = (item) => {
    if (item.template) {
      return (
        <p>{`${localParcels[item.template]?.name} - ${localParcels[item.template]?.dimensions}`}</p>
      );
    }

    return (
      <p>{`${item.length} x ${item.width} x ${item.height} ${item.length_unit}`}</p>
    );
  };

  const renderWeight = (item) => {
    return <p>{`${item.weight} ${UNITS[item.weight_unit]}`}</p>;
  };

  const renderError = () => {
    if (props.error && Object.keys(props.error).length > 0) {
      return <MessageBlock type={"error"} message={parseError(props.error)} />;
    } else return <></>;
  };

  return (
    <div className={"template-fields"}>
      {props.template && (
        <div
          className={`check-template ${props.isPackageChecked ? "active" : ""}`}
        >
          <div
            className="template-checkbox d-flex justify-content-center align-items-center"
            onClick={() => props.checkPackages()}
          >
            <Check />
          </div>
        </div>
      )}
      <div
        className={`parcel-item d-flex flex-row align-items-center justify-content-between ${props.selected ? "active" : ""}`}
      >
        <div className={"d-flex flex-column"}>
          {renderError()}
          <div
            className={
              "d-flex flex-row justify-content-between align-items-start"
            }
          >
            <div className={""}>
              {parseInt(props.item.quantity) > 1 && (
                <div className={"multi-piece-quantity"}>
                  {`Quantity ${props.item.quantity}`}
                </div>
              )}
              {props.item?.template ||
                (["length", "width", "height"].every(
                  (dimension) => props.item[dimension] > 0,
                ) &&
                  renderDimension(props.item))}
              {renderWeight(props.item)}
            </div>
            <div
              className={
                "d-flex flex-row align-items-center justify-content-end"
              }
            >
              <FiEdit2 onClick={() => props.editParcel()} />
              <FiTrash2
                className={"delete-action"}
                onClick={() => props.deleteParcel()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ parcels }) => ({
  parcels,
});

export default connect(mapStateToProps, {})(PackageDetailItem);
