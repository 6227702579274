import {
  CLEAR_APPLICATION_CONTEXT,
  CLOSE_PROFILE_COMPLETION,
  SET_APPLICATION_CONTEXT,
  SET_CONTEXT_ACTION_TYPE,
  SET_CONTEXT_RETURN_URL,
  SET_USER_COUNTRY,
} from "../../constants/types";
import axios from "axios";

export const setContextReturnURL = (url, onSuccess) => (dispatch) => {
  dispatch({
    type: SET_CONTEXT_RETURN_URL,
    payload: url,
  });
  if (onSuccess) onSuccess();
};

export const setContextActionType = (actionType, onSuccess) => (dispatch) => {
  dispatch({
    type: SET_CONTEXT_ACTION_TYPE,
    payload: actionType,
  });
  if (onSuccess) onSuccess();
};

export const setApplicationContext = (context, onSuccess) => (dispatch) => {
  dispatch({
    type: SET_APPLICATION_CONTEXT,
    payload: context,
  });
  if (onSuccess) onSuccess();
};

export const clearApplicationContext = (onSuccess) => (dispatch) => {
  dispatch({
    type: CLEAR_APPLICATION_CONTEXT,
  });
  if (onSuccess) onSuccess();
};

export const closeProfileCompletion = () => (dispatch) => {
  dispatch({
    type: CLOSE_PROFILE_COMPLETION,
  });
};

export const getUserCountry = () => (dispatch) => {
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      dispatch({
        type: SET_USER_COUNTRY,
        payload: data.country_code,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
