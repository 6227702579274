import React from "react";

const ErrorPage = () => (
  <div className="App">
    <section className="features-icons bg-light text-center">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <h1 className="mb-5">
              Opps! This doesn't seem to be a correct page.{" "}
            </h1>
          </div>
        </div>
      </div>
    </section>
  </div>
);
export default ErrorPage;
