import { functions, analytics } from "../../services/firebase";
import {
  CHECK_RATES,
  CLEAR_RATES,
  CLEAR_SELECTED_RATE,
  GET_RATES_FAILED,
  GET_RATES_SUCCESS,
  SELECT_RATE,
} from "../../constants/types";
import axios from "axios";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";

export const getRates =
  (
    { address_to, address_from, parcels, extras, declaration, useTemplate },
    onSuccess,
  ) =>
  (dispatch) => {
    dispatch({ type: CHECK_RATES });
    const extractParcels = [];
    parcels.forEach((parcel) => {
      const { quantity, ...parcelWithoutQuantity } = parcel;
      if (!parseInt(quantity)) {
        extractParcels.push(parcelWithoutQuantity);
      } else {
        extractParcels.push(
          ...Array.from({ length: quantity }, () => parcelWithoutQuantity),
        );
      }
    });
    const rateRequest = { address_from, address_to, parcels: extractParcels };

    rateRequest.parcels = [...rateRequest.parcels];

    if (declaration) {
      rateRequest.customs_declaration = declaration;
    }
    if (extras) {
      rateRequest.extras = extras;
    }
    const params = { ...rateRequest, ...rateRequest.parcel };
    analytics().logEvent(CHECK_RATES, params);
    // debugger

    instance
      .post("v1/shipments/", rateRequest)
      .then((response) => {
        getRatesSuccess(dispatch, response, onSuccess);
      })
      .catch((httpsError) => {
        console.log("got an error while checking rates: ", httpsError);
        if (httpsError.response?.status === 400)
          getRatesFailed(dispatch, httpsError.response.data, null);
        else getRatesFailed(dispatch, httpsError, null);
      });
  };

const getRatesFailed = (dispatch, errors, warnings) => {
  dispatch({
    type: GET_RATES_FAILED,
    payload: { errors, warnings },
  });
  analytics().logEvent(GET_RATES_FAILED, { rateError: parseError(errors) });
};

const getRatesSuccess = (dispatch, { data }, onSuccess) => {
  const warnings =
    data.messages?.find(
      (item) => item.level.toString().toLowerCase() === "warning",
    ) || null;
  console.log("Warnings for rate generation: ", warnings);
  if (data.rates.length === 0) {
    const message = data.messages?.find(
      (item) => item.level.toString().toLowerCase() === "error",
    );

    const errors = {
      message: message
        ? message.description
        : "No available rates were obtained for this shipment. Please make sure the addresses provided are valid.",
    };
    console.log("Errors for rate generation: ", errors);
    getRatesFailed(dispatch, errors, warnings);
  } else {
    dispatch({
      type: GET_RATES_SUCCESS,
      payload: { rates: data.rates, warnings: warnings },
    });
  }

  if (onSuccess) {
    onSuccess();
  }
};

export const selectRate =
  ({ rate }) =>
  (dispatch) => {
    dispatch({ type: SELECT_RATE, payload: rate });
  };

export const clearRate = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_RATE });
};
export const clearRates = () => {
  return {
    type: CLEAR_RATES,
  };
};
