import { auth } from "../../services/firebase";
import {
  EMAIL_CHANGED,
  FIREBASE_AUTH,
  LOGIN_FAILED,
  LOGIN_RETRY,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_SUCCESS,
  PASSWORD_CHANGED,
  PASSWORD_RESET_LINK_SENT,
  SEND_RESET_LINK,
  SEND_RESET_LINK_FAILED,
  SESSION_AUTH_FAILED,
  SESSION_AUTH_LOGOUT,
  SESSION_AUTH_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
} from "../../constants/types";
import * as ROUTES from "../../constants/routes";
import history from "../../History";
import { setApplicationContext } from "./ApplicationContextActions";
import axios from "axios";
import instance, { sessionInstance } from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";
import { getAccountInfo } from "./UserActions";
import { store } from "../../App";

export const emailChanged = (text) => {
  return {
    type: EMAIL_CHANGED,
    payload: text,
  };
};

export const passwordResetSent = (dispatch) => {
  dispatch({ type: PASSWORD_RESET_LINK_SENT, payload: true });
  history.replace(ROUTES.SIGN_IN);
};

//TODO: Maybe replace this to make it look more like the react-native side.
export const sendResetLink = (email) => (dispatch) => {
  dispatch({ type: SEND_RESET_LINK });
  auth()
    .sendPasswordResetEmail(email)
    .then(() => passwordResetSent(dispatch))
    .catch((error) => {
      const message =
        error || "Error occurred while sending reset link - try again later";
      console.log(message);
      dispatch({
        type: SEND_RESET_LINK_FAILED,
        payload: message,
      });
    });
};

export const clearPasswordReset =
  (success = true) =>
  (dispatch) => {
    dispatch({
      type: PASSWORD_RESET_LINK_SENT,
      payload: success,
    });
  };

export const passwordChanged = (text) => {
  return {
    type: PASSWORD_CHANGED,
    payload: text,
  };
};

export const retryLogin = () => {
  //switching to create account or retry login - clear errors
  return {
    type: LOGIN_RETRY,
  };
};

export const loginUser =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({ type: LOGIN_USER });

    auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => loginUserSuccess(dispatch, user))
      .catch((errorM) => loginUserFail(dispatch, errorM));
  };

export const createUser =
  ({ email, password }) =>
  (dispatch) => {
    dispatch({ type: LOGIN_USER });
    auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => loginUserSuccess(dispatch, user))
      .catch((error) => loginUserFail(dispatch, error));
  };

export const googleLogin = () => async (dispatch) => {
  dispatch({ type: LOGIN_USER });
  auth()
    .signInWithPopup(new auth.GoogleAuthProvider())
    .then((user) => {
      loginUserSuccess(dispatch, user);
    })
    .catch((error) => loginUserFail(dispatch, error));
};

export const checkAuthentication = () => (dispatch) => {
  auth().onAuthStateChanged((user) => {
    dispatch({ type: LOGIN_SUCCESS, payload: user });
  });
};

const loginUserSuccess = (dispatch, user, type = "default") => {
  dispatch({ type: LOGIN_SUCCESS, payload: user });

  if (!user.user.emailVerified) {
    user.user
      .sendEmailVerification()
      .then((res) => {
        dispatch(logOutUser());
        history.replace(ROUTES.VERIFY_EMAIL);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LOGIN_FAILED,
          payload:
            err.message ||
            "Unable to send verification email, try again later.",
        });
      });
    return;
  }

  if (user && !user.isAnonymous) {
    instance
      .post("/ui/firebase-auth")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Setting auth cookies failed: ", err);
      });
    if (type === "default")
      dispatch(
        setApplicationContext({ actionType: "signin" }, () => {
          history.replace(ROUTES.HOME);
        }),
      );

    return;
  }
};

const loginUserFail = (dispatch, error) => {
  console.log(error);
  dispatch({ type: LOGIN_FAILED, payload: error });
};

export const logOutUser =
  (type = "default") =>
  (dispatch) => {
    auth()
      .signOut()
      .then(() => {
        if (type === "default") {
          dispatch({
            type: LOGOUT_SUCCESS,
          });
          history.replace(ROUTES.SIGN_IN);
          return;
        }

        dispatch({
          type: LOGOUT_SUCCESS,
          payload: type,
        });
      })
      .catch((err) => console.log("Failed to log out user with error: ", err));
  };

const reauthenticateUser = (currentPassword) => {
  let user = auth()?.currentUser;
  let cred = auth.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
};

export const updatePassword =
  (currentPassword, newPassword, onSuccess) => (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD,
    });

    reauthenticateUser(currentPassword)
      .then((res) => {
        let user = auth()?.currentUser;
        user
          .updatePassword(newPassword)
          .then(() => {
            console.log("Password updated!");
            dispatch({
              type: UPDATE_PASSWORD_SUCCESS,
            });
            if (onSuccess) onSuccess();
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: UPDATE_PASSWORD_FAILED,
              payload: err.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: UPDATE_PASSWORD_FAILED,
          payload: err.message,
        });
      });
  };

export const logoutSession = (onSuccess) => (dispatch) => {
  const state = store.getState();
  const currentUser = state.userDetails?.info;
  let method = "post";
  let url = "/see-as-user/release/";
  if (!currentUser?.is_impersonated) {
    method = "get";
    url = "/logout/";
  }
  instance({
    method,
    url,
  })
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });

      auth().onAuthStateChanged((user) => {
        if (user) {
          if (!currentUser.is_impersonated) {
            dispatch(logOutUser());
          } else {
            window.location.href = res.config.baseURL + "/admin";
            loginUserSuccess(dispatch, { user }, "switch");
          }
        }
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log("Logout failed: ", err);
    });
};

export const getCSRFToken = () => {
  instance
    .get("/set-csrf-cookie/")
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const testSessionAuth = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    const states = store?.getState();
    const loggedUser = states?.auth?.user;
    const authType = states?.applicationContext?.context?.auth;
    instance
      .get("/check-auth/")
      .then((res) => {
        let useFirebaseAuth = !res.data.is_impersonated && !res.data.is_staff;
        if (useFirebaseAuth) {
          throw Error("User cannot use session auth");
        }
        if (
          !!loggedUser &&
          (authType === FIREBASE_AUTH ||
            JSON.stringify(loggedUser) !== JSON.stringify(res.data))
        ) {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: "switch",
          });
        }
        dispatch({
          type: SESSION_AUTH_SUCCESS,
          payload: res.data,
        });
        getCSRFToken();
        resolve(res.data);
      })
      .catch(async (err) => {
        console.log(err);
        dispatch({
          type: SESSION_AUTH_FAILED,
        });

        await auth().onAuthStateChanged((user) => {
          if (
            !user ||
            (Object.keys(user).length === 0 && user.constructor === Object)
          ) {
            history.replace(ROUTES.SIGN_IN);
            dispatch(logOutUser());
            reject(err.response);
            return;
          }

          if (user && !user.emailVerified) {
            user.sendEmailVerification();
            dispatch(logOutUser());
            history.replace(ROUTES.VERIFY_EMAIL);
            reject("Email not verified");
            return;
          }
          loginUserSuccess(dispatch, { user }, "switch");
          resolve(user);
        });
      });
  });
};
