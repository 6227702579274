import React from "react";
import firebase from "firebase/app";
import "firebase/functions";
import { Spinner } from "react-bootstrap";

const ERROR_STATE = "ERROR";

class TrackingPage extends React.Component {
  state = {
    trackingNumber: "",
    trackingStatus: "",
    trackingHistory: "",
  };

  trackingError = (error) => {
    // Getting the Error details.
    console.log("Error from api", error.message);
    this.setState({ trackingStatus: ERROR_STATE });
  };
  trackingSuccess = ({ data }) => {
    // Read result of the Cloud Function.
    if (data.success) {
      this.setState({
        trackingStatus: data,
        trackingHistory: data.trackingHistory,
      });
    } else {
      this.setState({ trackingStatus: "" });
    }
  };

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.setState({ trackingNumber: params.trackingNumber });

    let shipshapTracking = firebase
      .functions()
      .httpsCallable("shipshap_tracking");
    shipshapTracking({
      tracking_no: params.trackingNumber,
      carrier: params.provider,
    })
      .then((result) => {
        if (result.data.trackingHistory) this.trackingSuccess(result);
      })
      .catch((err) => this.trackingError(err));
  };

  renderStatus() {
    if (this.state.trackingStatus.success) {
      console.log(this.state.trackingHistory);
      const { tracking_history, tracking_status } = this.state.trackingHistory;

      function dateConvert(date) {
        let eta = new Date(date);
        return eta.toDateString();
      }

      let history = tracking_history.reverse();

      return (
        <div>
          <div>
            <h3>{dateConvert(tracking_status.status_date)}</h3>
            <h4>
              {tracking_status.status_details} <br />
            </h4>
            <p className="text-left">Tracking History</p>
          </div>
          <div>
            <ul className="tracking-list">
              {history.map((point, index) => {
                return (
                  <div>
                    <div className="vl vl1 float-left d-flex"></div>
                    <li>
                      <h6>{dateConvert(point.status_date)}</h6>
                      <em>
                        {point.status_details} <br />
                        {point
                          ? point.location
                            ? point.location.city
                            : null
                          : null}
                        {point
                          ? point.location
                            ? " " + point.location.state
                            : null
                          : null}
                        {point
                          ? point.location
                            ? ", " + point.location.zip
                            : null
                          : null}
                        <br /> <br />
                      </em>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      );
    } else if (
      this.state.trackingStatus === ERROR_STATE &&
      !this.state.trackingStatus.success
    ) {
      console.log(this.state.trackingStatus);
      return (
        <div>
          <h2>Opps! Something went wrong</h2>
          <p>Check your tracking number and try again.</p>
        </div>
      );
    } else if (!this.state.trackingStatus.success) {
      return <Spinner animation="border" role="status" />;
    }
  }

  render() {
    return (
      <div>
        <header className="d-flex justify-content-center">
          <div className="container-fluid p-5">
            <div className="row">
              <div className="text-center container-fluid col-lg-5 col-sm-12 col-md-7 mx-auto d-flex justify-content-center align-items-center">
                {this.renderStatus()}
              </div>
              <div className="col-lg-7 col-xl-pull-6 col-lg-pull-5 col-sm-pull-4 col-sm-12 col-md-5">
                <img
                  src="/img/undraw_on_the_way_ldaq.svg"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default TrackingPage;
