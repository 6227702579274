import React from "react";
import Button from "react-bootstrap/Button";

const CarePackagePage = () => (
  <div className="App">
    {/*// <!-- Masthead -->*/}
    <header className="masthead text-left">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="text-center col-lg-5 col-sm-12 col-md-7 mx-auto">
            <h2>
              Share with those you love, <br />
            </h2>
            <h1> 50% off the first package on us.</h1>
            <p>
              In these unpresidented times, we want to help you stay intouch
              with those you care about and send a care package from the comfort
              of your home.
            </p>
            {/*<p> Download the ShipShap app and create a shipping label to easily send packages to family and friends near and far. </p>*/}

            <a href="https://play.google.com/store/apps/details?id=com.shipshap">
              <img src="/img/google-store-icon.png" class="img-fluid" />
            </a>
            <a href="https://apps.apple.com/us/app/shipshap/id1483246532?ls=1">
              <img src="/img/apple-store-icon.png" class="img-fluid" />
            </a>
          </div>
          <div className="col-lg-7 col-xl-pull-6 col-lg-pull-5 col-sm-pull-4 col-sm-12 col-md-5">
            <img src="/img/care-package.jpg" className="img-fluid" />
          </div>
        </div>
      </div>
    </header>
  </div>
);
export default CarePackagePage;
