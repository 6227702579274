import {
  ADD_TOKEN,
  CLEAR_NEW_TOKEN,
  GENERATE_TOKEN,
  GENERATE_TOKEN_ADD_ERROR,
  GENERATE_TOKEN_FAILURE,
  GENERATE_TOKEN_SUCCESS,
  GET_TOKENS,
  GET_TOKENS_FAILURE,
  GET_TOKENS_SUCCESS,
  LOGOUT_SUCCESS,
  REMOVE_TOKEN,
  REMOVE_TOKEN_FAILURE,
  REMOVE_TOKEN_SUCCESS,
  SELECT_TOKEN,
  UPDATE_API_NAME,
} from "../../constants/types";

const INITIAL_API_STATE = {
  name: "",
  token: null,
  errors: "",
  generateError: "",
  loading: false,
  token_list: [],
  selected_token: null,
};

export function APIReducer(state = INITIAL_API_STATE, action) {
  let list;
  switch (action.type) {
    case UPDATE_API_NAME:
      return { ...state, name: action.payload };
    case GENERATE_TOKEN:
      return { ...state, loading: true, generateError: "" };
    case GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
        name: "",
        generateError: "",
      };
    case GENERATE_TOKEN_FAILURE:
      return { ...state, loading: false, generateError: action.payload };
    case CLEAR_NEW_TOKEN:
      return { ...state, loading: false, token: null, name: "" };
    case GET_TOKENS:
      return { ...state, loading: true, errors: "" };
    case GET_TOKENS_SUCCESS:
      return { ...state, loading: false, token_list: action.payload };
    case GET_TOKENS_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case ADD_TOKEN:
      list = [...state.token_list];
      list.push(action.payload);
      return { ...state, token_list: list };
    case REMOVE_TOKEN:
      return { ...state, loading: true, errors: "" };
    case REMOVE_TOKEN_SUCCESS:
      return { ...state, loading: false, errors: "" };
    case REMOVE_TOKEN_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case SELECT_TOKEN:
      return { ...state, selected_token: action.payload };
    case LOGOUT_SUCCESS:
      return INITIAL_API_STATE;
    default:
      return state;
  }
}
