import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  searchLabels,
  getAllLabels,
  getLabelsByPage,
  setLabelsPageSize,
} from "../actions/LabelActions";
import { Modal, Button, Spinner, Dropdown } from "react-bootstrap";
import { Link, Route, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import LabelList from "./Label/LabelList";
import { clearLabelSearch } from "../actions";
import Switch from "react-bootstrap/Switch";
import { useCookies } from "react-cookie";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import history from "../../History";
import { setApplicationContext } from "../actions/ApplicationContextActions";
import Pagination from "./common/Pagination";

const Label = (props) => {
  const [labelDays, setLabelDays] = useState(7);
  const [labelsTitle, setLabelsTitle] = useState("Last 7 Days");

  const { pathname, search } = useLocation();
  const [cookies] = useCookies();

  const searchParams = new URLSearchParams(search).get("search") || "";

  useEffect(() => {
    setLabelDays(7);
    setLabelsTitle("Last 7 Days");
    props.clearLabelSearch();
    props.searchLabels(searchParams);
    props.getAllLabels(props.pageSize);
  }, []);

  useEffect(() => {
    props.getAllLabels(props.pageSize, props.searchText);
  }, [props.searchText, props.pageSize]);

  const handleLabelsToggle = (num) => {
    if (num === 7) {
      setLabelsTitle("Last 7 Days");
    } else if (num === 15) {
      setLabelsTitle("Last 2 Weeks");
    } else if (num === 30) {
      setLabelsTitle("Last Month");
    } else if (num === 90) {
      setLabelsTitle("Last 3 Months");
    }
  };

  const getDaysBehind = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().slice(0, 10);
  };

  const { labels, searchText, loading } = props;
  return (
    <div
      className={`labels col-12 m-auto ${cookies.is_test ? "test-mode" : "live-mode"}`}
    >
      <div className="settings-card">
        <div className="card-section head d-flex flex-row justify-content-between">
          <div className=" d-flex flex-row">
            <h3
              className={
                "d-flex flex-column flex-md-row align-items-center p-0 m-0"
              }
            >
              All Labels
            </h3>
          </div>
          <Link
            to={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
            className="ship-label-button d-flex"
            onClick={() => {
              props.setApplicationContext({ returnURL: pathname });
            }}
          >
            Create Label
          </Link>
        </div>
        <LabelList
          list={labels}
          errors={props.errors}
          loading={props.loading}
          search={searchText}
          pageSize={props.pageSize}
          pagination={
            <Pagination
              current={props.current}
              count={props.count}
              pageSize={props.pageSize}
              getListByPage={(pageNo) =>
                props.getLabelsByPage(
                  null,
                  pageNo,
                  props.pageSize,
                  props.searchText,
                )
              }
              setPageSize={(size) => props.setLabelsPageSize(size)}
            />
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ labels }) => ({
  labels: labels.labelsList,
  errors: labels.errors,
  count: labels.labelResponse?.count,
  current: labels.currentPage,
  loading: labels.loading,
  searchText: labels.searchText,
  pageSize: labels.pageSize,
});

export default connect(mapStateToProps, {
  getAllLabels,
  searchLabels,
  clearLabelSearch,
  getLabelsByPage,
  setLabelsPageSize,
  setApplicationContext,
})(Label);
