import {
  DOWNLOAD_BUSINESS_DOC,
  DOWNLOAD_BUSINESS_DOC_FAILED,
  DOWNLOAD_BUSINESS_DOC_SUCCESS,
  GET_BUSINESS_INFO,
  GET_BUSINESS_INFO_FAILED,
  GET_BUSINESS_INFO_SUCCESS,
  LOGOUT_SUCCESS,
  SAVE_BUSINESS_INFO,
  SAVE_BUSINESS_INFO_FAILED,
  SAVE_BUSINESS_INFO_SUCCESS,
  START_DOCUMENT_UPLOAD,
  UPDATE_BUSINESS_INFO_FIELD,
  UPDATE_DOCUMENT_UPLOAD_PROGRESS,
  UPLOAD_BUSINESS_DOC,
  UPLOAD_BUSINESS_DOC_FAILED,
  UPLOAD_BUSINESS_DOC_SUCCESS,
} from "../../constants/types";

const INITIAL_STATE = {
  loading: false,
  errors: "",
  downloadError: "",
  details: null,
};

export function businessDetailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_BUSINESS_INFO_SUCCESS:
      return { ...state, details: action.payload };
    case GET_BUSINESS_INFO:
      return { ...state, loading: true, errors: "" };
    case GET_BUSINESS_INFO_SUCCESS:
      return { ...state, loading: false, details: action.payload };
    case GET_BUSINESS_INFO_FAILED:
      return { ...state, loading: false, errors: action.payload };
    case DOWNLOAD_BUSINESS_DOC:
      return { ...state, loading: true, downloadError: "" };
    case DOWNLOAD_BUSINESS_DOC_SUCCESS:
      return { ...state, loading: false, downloadError: "" };
    case DOWNLOAD_BUSINESS_DOC_FAILED:
      return { ...state, loading: false, downloadError: action.payload };
    case LOGOUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const INITIAL_FORM_STATE = {
  country: "",
  bank_name: "",
  account_number: "",
  account_name: "",
  document_type: "Business License",
  document_name: "",
  document_size: "",
  tax_number: "",
  loading: false,
  uploading: false,
  progress: 0,
  errors: "",
};

export function businessDetailsFormReducer(state = INITIAL_FORM_STATE, action) {
  switch (action.type) {
    case UPDATE_BUSINESS_INFO_FIELD:
      return { ...state, errors: "", ...action.payload };
    case SAVE_BUSINESS_INFO:
      return { ...state, loading: true, errors: "" };
    case SAVE_BUSINESS_INFO_FAILED:
      return { ...state, loading: false, errors: action.payload };
    case SAVE_BUSINESS_INFO_SUCCESS:
      return INITIAL_FORM_STATE;
    case UPLOAD_BUSINESS_DOC:
      return {
        ...state,
        loading: true,
        uploading: true,
        progress: 0,
        errors: "",
      };
    case UPLOAD_BUSINESS_DOC_FAILED:
      return {
        ...state,
        loading: false,
        uploading: false,
        errors: action.payload,
      };
    case UPLOAD_BUSINESS_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: 1,
        errors: "",
        document_name: action.payload.name,
        document_size: action.payload.size,
      };
    case UPDATE_DOCUMENT_UPLOAD_PROGRESS:
      return { ...state, progress: action.payload };
    case LOGOUT_SUCCESS:
      return INITIAL_FORM_STATE;
    default:
      return state;
  }
}
