import * as ROUTES from "../../constants/routes";
import {
  CLEAR_APPLICATION_CONTEXT,
  CLOSE_PROFILE_COMPLETION,
  FIREBASE_AUTH,
  LOGOUT_SUCCESS,
  SESSION_AUTH,
  SESSION_AUTH_FAILED,
  SESSION_AUTH_LOGOUT,
  SESSION_AUTH_SUCCESS,
  SET_APPLICATION_CONTEXT,
  SET_CONTEXT_ACTION_TYPE,
  SET_CONTEXT_RETURN_URL,
  SET_USER_COUNTRY,
} from "../../constants/types";

const INITIAL_STATE = {
  context: {
    returnURL: ROUTES.HOME,
    actionType: "",
    showProfileCompletion: true,
    country: "US",
    auth: FIREBASE_AUTH,
    showMultiPieceWarning: true,
  },
  preferences: null,
};

export function applicationContextReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CONTEXT_RETURN_URL:
      return {
        ...state,
        context: { ...state.context, returnURL: action.payload },
      };
    case SET_CONTEXT_ACTION_TYPE:
      return {
        ...state,
        context: { ...state.context, actionType: action.payload },
      };
    case SET_APPLICATION_CONTEXT:
      return { ...state, context: { ...state.context, ...action.payload } };
    case CLEAR_APPLICATION_CONTEXT:
      return {
        ...state,
        context: {
          ...state.context,
          returnURL: INITIAL_STATE.context.returnURL,
          actionType: INITIAL_STATE.context.actionType,
        },
      };
    case CLOSE_PROFILE_COMPLETION:
      return {
        ...state,
        context: { ...state.context, showProfileCompletion: false },
      };
    case SET_USER_COUNTRY:
      return {
        ...state,
        context: { ...state.context, country: action.payload },
      };
    case SESSION_AUTH_SUCCESS:
      return { ...state, context: { ...state.context, auth: SESSION_AUTH } };
    case SESSION_AUTH_FAILED:
      return { ...state, context: { ...state.context, auth: FIREBASE_AUTH } };
    case LOGOUT_SUCCESS:
      if (action.payload && action.payload === "switch")
        return {
          ...state,
          context: { ...INITIAL_STATE.context, auth: state.context.auth },
        };
      return { ...state, context: { ...INITIAL_STATE.context } };
    default:
      return state;
  }
}
