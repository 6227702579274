import {
  PURCHASE_LABEL_ERROR,
  PURCHASE_LABEL_SUCCESS,
  LABEL_CREATE_SUCCESS,
  PURCHASE_LABEL,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  UPDATE_PURCHASE_EMAIL,
  CARD_PAY,
  UPDATE_FILE_TYPE,
  CLEAR_LABEL_CREATION_ERRORS,
  SESSION_AUTH_SUCCESS,
} from "../../constants/types";
// import labelData from './label.json';  < -- for testing

const INITIAL_RATES_STATE = {
  loading: false,
  errors: "",
  label: {},
  userEmail: null,
  fileType: "pdf_a4",
};

export function purchaseLabelReducer(state = INITIAL_RATES_STATE, action) {
  switch (action.type) {
    case PURCHASE_LABEL:
      return { ...state, loading: true, errors: "" };
    case LOGIN_SUCCESS:
    case SESSION_AUTH_SUCCESS:
      let email = state.userEmail;
      if (!!action.payload?.email && action.payload.email !== "") {
        email = action.payload.email;
      }
      return { ...state, userEmail: email };
    case UPDATE_PURCHASE_EMAIL:
      return { ...state, userEmail: action.payload };
    case PURCHASE_LABEL_SUCCESS:
      return { ...state, label: action.payload, loading: false, errors: "" };
    case PURCHASE_LABEL_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case LABEL_CREATE_SUCCESS:
      // clear the address when the label is created
      return INITIAL_RATES_STATE;
    case LOGOUT_SUCCESS:
      return INITIAL_RATES_STATE;
    case UPDATE_FILE_TYPE:
      return { ...state, fileType: action.payload };
    case CLEAR_LABEL_CREATION_ERRORS:
      return { ...state, errors: "" };
    case CARD_PAY:
      return { ...state, loading: false, errors: "" };
    default:
      return state;
  }
}
