import {
  CLEAR_CSV_CONFIG,
  CLEAR_CSV_ERRORS,
  CREATE_CSV_STORE,
  CREATE_CSV_STORE_FAILED,
  CREATE_CSV_STORE_SUCCESS,
  GET_DOWNLOAD_ORDER_CSV,
  GET_DOWNLOAD_ORDER_CSV_FAILED,
  GET_DOWNLOAD_ORDER_CSV_SUCCESS,
  SAVE_CSV_CONFIG,
  SELECT_CSV_STORE,
  UNSELECT_SHOP_CSV,
  UPLOAD_CSV_FILE,
  UPLOAD_CSV_FILE_FAILED,
  UPLOAD_CSV_FILE_SUCCESS,
} from "../../constants/types";

const INITIAL_STATE = {
  selectedStore: null,
  createStore: false,
  loading: false,
  errors: "",
  created: false,
  csvConfig: null,
  csvErrors: [],
  downloaded: false, // ? Is the CSV Downloaded for the user?
};

export const csvImportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_CSV_STORE:
      return {
        ...state,
        selectedStore: action.payload,
        errors: "",
        csvErrors: [],
        loading: false,
        created: false,
      };
    case CREATE_CSV_STORE:
      return { ...state, errors: "", loading: true };
    case CREATE_CSV_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        selectedStore: action.payload,
      };
    case CREATE_CSV_STORE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload[0].description,
        csvErrors: action.payload,
      };
    case UNSELECT_SHOP_CSV:
      return {
        ...state,
        selectedStore: null,
        errors: "",
        loading: false,
        created: false,
        csvErrors: [],
      };
    case UPLOAD_CSV_FILE:
      return { ...state, loading: true, errors: "", csvErrors: [] };
    case UPLOAD_CSV_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        csvErrors: [],
        created: true,
      };
    case UPLOAD_CSV_FILE_FAILED:
      return {
        ...state,
        loading: false,
        csvErrors: action.payload,
        errors: action.payload[0]?.description || "",
        created: false,
      };
    case SAVE_CSV_CONFIG:
      return { ...state, csvConfig: action.payload, loading: true, errors: "" };
    case CLEAR_CSV_ERRORS:
      return { ...state, errors: "", csvErrors: [] };
    case GET_DOWNLOAD_ORDER_CSV:
      return {
        ...state,
        loading: true,
        downloaded: true,
        errors: "",
        csvErrors: [],
      };
    case GET_DOWNLOAD_ORDER_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        csvErrors: [],
        downloaded: true,
        csvConfig: null,
      };
    case GET_DOWNLOAD_ORDER_CSV_FAILED:
      return {
        ...state,
        loading: false,
        errors: "",
        csvErrors: [],
        downloaded: false,
      };
    case CLEAR_CSV_CONFIG:
      return INITIAL_STATE;
    default:
      return state;
  }
};
