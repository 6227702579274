import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { tableDateFormatter, dateFilter } from "../common/helpers";
import "../../../scss/manifests.scss";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { formatStatus, formatLinkName } from "./helpers";
import { FiTrash2 } from "react-icons/fi";

const ManifestList = ({
  manifests,
  pagination,
  deleteManifest,
  closeManifest,
}) => {
  const manifestActionFormatter = (cell, row) => {
    const key = "manifest-action-" + row.id;
    return (
      <div
        key={key}
        className={
          "manifest-actions d-flex flex-row align-items-center justify-content-end"
        }
      >
        {["failed", "partially_delivered"].includes(cell) && (
          <button
            onClick={() => {
              closeManifest(row.id);
            }}
          >
            Close Manifest
          </button>
        )}
        {/*<button onClick={()=>{*/}
        {/*  deleteManifest(row.id)*/}
        {/*}}><FiTrash2/></button>*/}
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: formatLinkName,
    },
    { dataField: "driver_name", text: "Driver" },
    {
      dataField: "created_at",
      text: "Created at",
      formatter: tableDateFormatter,
      filterValue: dateFilter,
    },
    {
      dataField: "scanned_at",
      text: "Picked-up at",
      formatter: tableDateFormatter,
      filterValue: dateFilter,
    },
    {
      dataField: "delivered_at",
      text: "Completed at",
      formatter: tableDateFormatter,
      filterValue: dateFilter,
    },
    { dataField: "status", text: "Status", formatter: formatStatus },
    { dataField: "status", text: "Action", formatter: manifestActionFormatter },
  ];

  if (manifests?.length > 0) {
    return (
      <ToolkitProvider
        keyField="id"
        data={manifests}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
      >
        {(props) => (
          <div>
            <BootstrapTable {...props.baseProps} />
            {pagination}
          </div>
        )}
      </ToolkitProvider>
    );
  } else {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center text-center p-5">
        <p className="empty-manifests">
          No manifests created yet. <br></br>
          Start creating your first manifest by clicking the button
        </p>
        <Link
          to={ROUTES.HOME + ROUTES.MANIFESTS + ROUTES.CREATE}
          className="ship-label-button d-flex m-3"
        >
          Create Manifest
        </Link>
      </div>
    );
  }
};

export default ManifestList;
