import React from "react";
import { FiX } from "react-icons/fi";
import "../../../scss/message-block.scss";

const MessageBlock = (props) => {
  const getTextType = (message) => {
    switch (props.type) {
      case "error":
        return <p className={"text-danger m-0"}>{message}</p>;
      case "warning":
        return <p className={"text-warning m-0"}>{message}</p>;
      default:
        return <p>{message}</p>;
    }
  };

  const getClassName = () => {
    switch (props.type) {
      case "error":
        return "error-block";
      case "warning":
        return "warning-block";
      case "success":
        return "success-block";
      default:
        return "message-block";
    }
  };
  return (
    <div className={`${getClassName()} text-newline-wrap`}>
      <div className="m-0">
        {props.icon}
        {getTextType(props.message)}
      </div>
      {props.close && (
        <FiX
          className={"close-message-action align-self-start"}
          onClick={() => props.close()}
        />
      )}
    </div>
  );
};

export default MessageBlock;
