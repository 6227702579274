import { FiCheck } from "react-icons/fi";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

const AccountFormHeader = (props) => {
  const [type, setType] = useState("signin");
  const [width, setWidth] = useState(100);
  const [isSingup, setSignup] = useState(false);
  const [isProfileDetails, setProfileDetails] = useState(true);
  const [isBusinessDetails, setBusinessDetails] = useState(false);
  const [isSingupDone, setSignupDone] = useState(false);
  const [isProfileDetailsDone, setProfileDetailsDone] = useState(true);
  const [isBusinessDetailsDone, setBusinessDetailsDone] = useState(false);

  const { pathname } = useLocation();

  const match = (path) => matchPath(pathname, { path });

  useLayoutEffect(() => {
    if (props.context.actionType === "edit") {
      setType("edit");
    }

    if (match(ROUTES.SIGN_IN) || match(ROUTES.SIGN_UP)) setSignup(true);
    else setSignup(false);
    if (match(ROUTES.UPDATE_ACCOUNT_DETAILS)) setProfileDetails(true);
    else setProfileDetails(false);
    if (
      match(ROUTES.BANK_ACCOUNT_DETAILS) ||
      match(ROUTES.BUSINESS_REGISTRATION) ||
      match(ROUTES.TAX_INFO)
    )
      setBusinessDetails(true);
    else setBusinessDetails(false);

    if (props.context.actionType === "edit") {
      if (isBusinessDetails) {
        if (match(ROUTES.BANK_ACCOUNT_DETAILS)) {
          setWidth(100 / 3);
        } else if (match(ROUTES.BUSINESS_REGISTRATION)) {
          setWidth(200 / 3);
        } else if (match(ROUTES.TAX_INFO)) {
          setWidth(300 / 3);
        } else setWidth(50);
      } else {
        setWidth(50);
      }
    } else {
      if (isBusinessDetails) {
        if (match(ROUTES.BANK_ACCOUNT_DETAILS)) {
          setWidth(200 / 3 + 80 / 9);
        } else if (match(ROUTES.BUSINESS_REGISTRATION)) {
          setWidth(200 / 3 + 160 / 9);
        } else if (match(ROUTES.TAX_INFO)) {
          setWidth(200 / 3 + 240 / 9);
        } else setWidth(200 / 3);
      } else if (isProfileDetails) {
        setWidth(150 / 3);
      } else setWidth(100 / 3);
    }
  }, [pathname, isProfileDetails, isBusinessDetails]);

  return (
    <div
      className={`account-form-header ${props.context.actionType === "edit" ? "edit" : ""} d-flex flex-row justify-content-center align-items-center`}
    >
      <div
        className={`progress-item ${isSingup ? "active" : ""} ${isProfileDetails || isBusinessDetails ? "done" : ""} flex-row align-items-center justify-content-between justify-content-lg-center`}
      >
        <div className={"d-flex flex-row align-items-center"}>
          {<FiCheck size={25} />}
          <div className={"progress-circle"}></div>
          <p className={"mb-0 progress-title"}>Sign up</p>
        </div>
        <p className={"d-flex d-lg-none mb-0 steps"}>Step 1/3</p>
      </div>
      <div
        className={`progress-item ${isProfileDetails ? "active" : ""} ${isBusinessDetails ? "done" : ""} flex-row align-items-center justify-content-between justify-content-lg-center`}
      >
        <div className={"d-flex flex-row align-items-center"}>
          {<FiCheck size={25} />}
          <div className={"progress-circle"}></div>
          <p className={"mb-0 progress-title"}>Create an Account</p>
        </div>
        <p className={"d-flex d-lg-none mb-0 steps"}>Step 2/3</p>
      </div>
      <div
        className={`progress-item ${isBusinessDetails ? "active" : ""}  flex-row align-items-center justify-content-between justify-content-lg-center`}
      >
        <div className={"d-flex flex-row align-items-center"}>
          {<FiCheck size={25} />}
          <div className={"progress-circle"}></div>
          <p className={"mb-0 progress-title"}>Business Verification</p>
        </div>
        <p className={"d-flex d-lg-none mb-0 steps"}>Step 3/3</p>
      </div>
      <div className={"progress-bar"} style={{ width: `${width}%` }}></div>
    </div>
  );
};

const mapStateToProps = ({ applicationContext }) => ({
  context: applicationContext.context,
});

export default connect(mapStateToProps, {})(AccountFormHeader);
