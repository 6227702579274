import React from "react";

export const DownloadAppButton = (props) => {
  const checkPlatform = (OS) => {
    if (OS === "Android") {
      return (
        <a href="https://play.google.com/store/apps/details?id=com.shipshap&hl=en&gl=US">
          Download App
        </a>
      );
    } else {
      // default to iOS
      return (
        <a href="https://apps.apple.com/us/app/shipshap/id1483246532">
          Download App
        </a>
      );
    }
  };
  if (props.link) {
    if (props.className) {
      return (
        <a href={props.link}>
          <button className={props.className}>Download App</button>
        </a>
      );
    }
    return (
      <a href={props.link}>
        <button>Download App</button>
      </a>
    );
  }
  return checkPlatform(navigator.platform);
};

export const DownloadAppLink = ({ styling }) => {
  const OS = navigator.platform;
  if (OS === "Anrdoid" || OS === "iPhone" || OS === "iPad") {
    return (
      <a href="https://shipshap.page.link/20OFF" className={styling}>
        Download App
      </a>
    );
  } else {
    return (
      <a
        href="https://apps.apple.com/us/app/shipshap/id1483246532"
        className={styling}
      >
        Download App
      </a>
    );
  }
};
