import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import {
  FiAlertCircle,
  FiChevronLeft,
  FiDownloadCloud,
  FiFileText,
} from "react-icons/fi";
import { LuFileCheck } from "react-icons/lu";
import "../../../../scss/csv-import.scss";
import { connect } from "react-redux";
import { Button, Form, Overlay, Spinner } from "react-bootstrap";
import { FormField } from "../../common/FormField";
import {
  clearCSVConfig,
  clearCSVErrors,
  createShopCSV,
  getDownloadOrderCSV,
  selectStore,
  unselectShopCSV,
  uploadCSVFile,
} from "../../../actions/CSVImportActions";
import { getConnectedStores } from "../../../actions";
import FileUpload from "../../common/FileUpload";
import MessageBlock from "../../common/MessageBlock";
import { HOME, ORDERS } from "../../../../constants/routes";
import { setApplicationContext } from "../../../actions/ApplicationContextActions";
import { MPESA } from "../../../../constants/types";
import history from "../../../../History";

const SelectStore = (props) => {
  const { stores } = props;
  const [validated, setValidated] = useState(false);
  const [newStore, setNewStore] = useState(false);
  const [newStoreName, setNewStoreName] = useState("");
  const history = useHistory();

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();

        const form = event.currentTarget;
        let isFormValid = form.checkValidity();
        if (isFormValid) {
          const data = {
            url: newStoreName,
          };
          //TODO: Create cart  config
          props.createCSVShop(data);
        }
      }}
    >
      <div
        className={
          "field-group d-flex flex-column flex-lg-row align-items-lg-center justify-content-between"
        }
      >
        <Form.Group controlId="csv_import.store_select" className={"col-3"}>
          <Form.Label className={"d-flex flex-row align-items-start"}>
            Select a store <p className={"text-danger m-0"}>*</p>
          </Form.Label>
          <Form.Select
            className={"m-0"}
            // disabled
            // value={selectedStore}
            onChange={(e) => {
              // ! This logic will call an action on change (if it's not "+ Add store")
              if (e.target.value === "+ Add store") {
                // ! Do routing
                // * REDIRECT OT CHECKOUT CREATE
                props.setApplicationContext({
                  returnURL: ROUTES.CSV_IMPORT_PAGE,
                });
                history.replace(ROUTES.CHECKOUTS_CREATE);
                return;
              }
              setNewStore(false);
              props.selectStore(
                stores.find((store) => store.id === e.target.value),
              );
            }}
          >
            <option disabled selected>
              Select store
            </option>
            {stores
              .filter((store) => store.platform === "MANUAL")
              .map((store) => (
                <option value={store.id} key={store.id}>
                  {store.shop_name}
                </option>
              ))}
            <option>+ Add store</option>
          </Form.Select>
          {props.loading && <Spinner />}
          <Form.Control.Feedback type="invalid">
            Please select a store
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      {newStore && (
        <FormField
          controlId={"csv_import.store_name"}
          className={"col-9"}
          label={"Enter new store name"}
          isRequired
          // value={store_name}
          type={"string"}
          setter={(e) => {
            setNewStoreName(e);
          }}
          placeholder={"Store Name"}
          // errorMessage={name_errors}
        />
      )}
      {newStore && (
        <button type={"submit"}>
          {props.loading ? <Spinner animation={"border"} /> : "Next"}
        </button>
      )}
    </Form>
  );
};

const UploadCSV = (props) => {
  const { selectedStore, unselectShopCSV, loading } = props;
  const [files, setFiles] = useState(null);

  return (
    <div className="upload-csv-container">
      <div className="store-details">
        <p className={"info"}>Store</p>
        <div className="row">
          <div className="col store-name">
            <h4 className="d-inline">{selectedStore.shop_name}</h4>
            <a
              className="ml-3"
              onClick={() => {
                unselectShopCSV();
              }}
            >
              Change
            </a>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h5>Upload CSV</h5>
        <div></div>
      </div>
      <div>
        <FileUpload
          fileType={"text/csv"}
          onChange={(files) => {
            setFiles(files);
          }}
        />
        {props.created && (
          <h6 className={"text-success"}>Successfully uploaded</h6>
        )}
        <h6 style={{ color: "grey" }}>Supported format: CSV</h6>
      </div>
      <div
        className={
          "submit-cancel-buttons d-flex flex-row mt-5 justify-content-around"
        }
      >
        <button
          className="mr-2 no-bg"
          onClick={() => {
            unselectShopCSV();
          }}
        >
          Back to previous step
        </button>
        <button
          className="ml-2"
          onClick={() => {
            if (files === null || files.length === 0) {
              alert("Please upload a file first");
              return;
            }
            if (!loading) {
              files.forEach((file) => {
                props.uploadCSVFile(selectedStore.id, file, () => {
                  props.showDownloadModal();
                });
              });
            } else {
              // * It's loading. do nothing
            }
          }}
        >
          {loading ? <Spinner animation={"border"} /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

const CSVImport = (props) => {
  const {
    selectedStore,
    stores,
    selectStore,
    getConnectedStores,
    createShopCSV,
    loading,
    loadingStores,
    unselectShopCSV,
    uploadCSVFile,
    created,
    errors,
    downloaded,
    getDownloadOrderCSV,
    csvConfig,
  } = props;

  const location = useLocation();
  const shop = new URLSearchParams(location.search).get("shop_id");
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    getConnectedStores((stores) => {
      if (shop && !selectedStore)
        selectStore(stores.find((store) => store.id === shop));
    });
  }, []);

  function renderErrors() {
    if (errors !== "") {
      let errorMessage = "";
      props.csvErrors.forEach((e, i) => {
        if (i === 0) {
          errorMessage += `${e.description}\n`;
        } else {
          errorMessage += `    ${i}. ${e.description}\n`;
        }
      });
      errorMessage += "Please address these issues and try again.";
      return (
        <div className={"pt-3"}>
          <MessageBlock
            type={"error"}
            message={errorMessage}
            close={() => {
              props.clearCSVErrors();
            }}
          />
        </div>
      );
    }
    return <></>;
  }

  const renderStepComponent = (selectedStore) => {
    if (selectedStore) {
      return (
        <div className={"menu-list"}>
          <LuFileCheck size={35} color="grey" />
          <p></p>
          <b>Step 2</b>
          <p></p>
          <p>
            Select the CSV file you'd like to import. Make sure the CSV file has
            all your order information in it.
          </p>
        </div>
      );
    }
    return (
      <div className={"menu-list"}>
        <FiFileText size={35} color="grey" />
        <p></p>
        <b>Step 1</b>
        <p></p>
        <p>
          Streamline your shop’s order management effortlessly by importing
          orders from CSV files. First we need to select the store your orders
          will be attached to.
        </p>
      </div>
    );
  };

  const renderDownloadModal = () => {
    if (showDownloadModal) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => setShowDownloadModal(false)}
          ></div>
          <div className="modal-content confirmation">
            <FiDownloadCloud />
            <div>
              <p>Your orders have been successfully uploaded.</p>
              <p>
                Do you want to download the Order CSV with payment requests
                attached? (if applicable)
              </p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button
                onClick={() => {
                  setShowDownloadModal(false);
                  history.replace(HOME + ORDERS);
                  props.clearCSVConfig();
                }}
                className="delete-button"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  getDownloadOrderCSV(csvConfig.id, () => {
                    history.replace(HOME + ORDERS);
                    props.clearCSVConfig();
                  });
                }}
              >
                {loading ? (
                  <div className="spinner-border m-auto" role="status"></div>
                ) : (
                  "Download CSV"
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="csv-detail-container">
      <Link
        to={ROUTES.HOME + ROUTES.ORDERS}
        className={
          "back-action d-inline-flex flex-grow align-items-center mb-3"
        }
      >
        <FiChevronLeft /> Back to orders
      </Link>

      <div className={"csv-imports"}>
        <div
          className="menu-container d-lg-flex flex-column collapse"
          id={"menuContainer"}
        >
          {renderStepComponent(props.selectedStore)}
          <div className={"menu-list"}>
            <b>CSV Template</b>
            <p></p>
            <p>
              For a smooth upload experience, kindly ensure that your CSV
              columns adhere to our provided template.
            </p>
            <a
              className="download-action"
              href="https://storage.googleapis.com/shipshap-prod-static/static/CSV_statics/ShipShap%20CSV%20guidelines.pdf"
              target="_blank"
            >
              Download format guidelines
            </a>
            <a
              className="download-action"
              href="https://storage.googleapis.com/shipshap-prod-static/static/CSV_statics/CSV_template.csv"
              target="_blank"
            >
              Download CSV template
            </a>
          </div>
        </div>
        <div className={"csv-import-content settings-card"}>
          <div className={"card-section head"}>
            <h3>Import orders from CSV file</h3>
          </div>
          <div className={"card-section last"}>
            {selectedStore == null ? (
              <SelectStore
                stores={stores}
                selectStore={selectStore}
                createCSVShop={createShopCSV}
                loading={loading || loadingStores}
                setApplicationContext={props.setApplicationContext}
              />
            ) : (
              <UploadCSV
                selectedStore={selectedStore}
                unselectShopCSV={unselectShopCSV}
                uploadCSVFile={uploadCSVFile}
                showDownloadModal={() => setShowDownloadModal(true)}
                created={created}
                loading={loading}
              />
            )}
            <div>{renderErrors()}</div>
          </div>
        </div>
      </div>
      {renderDownloadModal()}
    </div>
  );
};

const mapStateToProps = ({ stores, applicationContext, csvImport }) => ({
  selectedStore: csvImport.selectedStore,
  stores: stores.stores,
  loadingStores: stores.loading,
  loading: csvImport.loading,
  created: csvImport.created,
  errors: csvImport.errors,
  csvErrors: csvImport.csvErrors,
  downloaded: csvImport.downloaded,
  csvConfig: csvImport.csvConfig,
});

export default connect(mapStateToProps, {
  selectStore,
  createShopCSV,
  getConnectedStores,
  unselectShopCSV,
  uploadCSVFile,
  clearCSVErrors,
  getDownloadOrderCSV,
  setApplicationContext,
  clearCSVConfig,
})(CSVImport);
