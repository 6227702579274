import React from "react";
import { CHECKOUT, RECEIVER } from "../../../constants/types";
import { FiAlertCircle, FiInfo } from "react-icons/fi";
import AddressListItem from "./AddressListItem";
import MessageBlock from "../common/MessageBlock";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddressSelectItem = ({
  title,
  error,
  formType,
  address,
  toggleList,
  clearAddress,
  toggleForm,
  showForm,
  tooltip,
}) => {
  return (
    <div className={`address-select-item ${error ? "show-error" : ""}`}>
      <div className="address-header col-12 p-0 d-flex justify-content-between flex-row">
        <h3 className="d-flex flex-row align-items-center">
          {title}
          {!!tooltip && (
            <OverlayTrigger
              key={"checkout-address-info"}
              placement="auto"
              overlay={
                <Tooltip
                  id="checkout-address-info"
                  className={"checkout-address-info"}
                >
                  {tooltip}
                </Tooltip>
              }
              delayShow={300}
              delayHide={150}
            >
              <span className={"checkout-address-info"}>
                <FiInfo className={"warning"} />
              </span>
            </OverlayTrigger>
          )}
        </h3>
        <div className="address-actions p-0 d-flex flex-row justify-content-end align-items-center mb-3">
          <a onClick={() => toggleList()}>Add From List</a>
          <p>or</p>
          <a
            onClick={() => {
              clearAddress();
              toggleForm();
            }}
          >{`Add New ${title}`}</a>
        </div>
      </div>
      {error && (
        <MessageBlock type={"error"} icon={<FiAlertCircle />} message={error} />
      )}
      {!!address?.id ? (
        //! this has to go to the usage place
        !showForm && (
          <AddressListItem
            type={formType}
            item={address}
            onFinish={() => toggleForm()}
            toggleForm={() => toggleForm()}
            toggleAddrList={() => toggleList()}
            errors={error}
          />
        )
      ) : (
        <MessageBlock
          icon={<FiInfo />}
          message={`Select or Create address for ${formType === CHECKOUT ? "STORE" : formType.toString().toUpperCase()}`}
        />
      )}
    </div>
  );
};

export default AddressSelectItem;
