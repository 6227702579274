const StatusItem = ({ message, className }) => {
  return (
    <div
      className={`status-item d-flex flex-row align-items-center justify-content-start ${className}`}
    >
      <div className={"circle"}></div>
      <p>{message}</p>
    </div>
  );
};

export default StatusItem;
