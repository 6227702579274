import {
  DOWNLOAD_BUSINESS_DOC,
  DOWNLOAD_BUSINESS_DOC_FAILED,
  DOWNLOAD_BUSINESS_DOC_SUCCESS,
  GET_BUSINESS_INFO,
  GET_BUSINESS_INFO_FAILED,
  GET_BUSINESS_INFO_SUCCESS,
  SAVE_BUSINESS_INFO,
  SAVE_BUSINESS_INFO_FAILED,
  SAVE_BUSINESS_INFO_SUCCESS,
  UPDATE_BUSINESS_INFO_FIELD,
  UPDATE_DOCUMENT_UPLOAD_PROGRESS,
  UPLOAD_BUSINESS_DOC,
  UPLOAD_BUSINESS_DOC_FAILED,
  UPLOAD_BUSINESS_DOC_SUCCESS,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import axios from "axios";
import { parseError } from "./ActionHelpers";

export const updateBusinessForm = (data) => {
  return {
    type: UPDATE_BUSINESS_INFO_FIELD,
    payload: data,
  };
};

export const getBusinessDetails = (onSuccess, onFinish) => (dispatch) => {
  dispatch({
    type: GET_BUSINESS_INFO,
  });

  instance
    .get("business_details/")
    .then(async (res) => {
      dispatch({
        type: GET_BUSINESS_INFO_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) await onSuccess();
      if (onFinish) onFinish();
    })
    .catch((err) => {
      dispatch({
        type: GET_BUSINESS_INFO_FAILED,
        payload: err.data,
      });
      if (onFinish) onFinish();
    });
};

export const saveBusinessDetails = (data, onSuccess) => (dispatch) => {
  dispatch({
    type: SAVE_BUSINESS_INFO,
  });

  instance
    .patch("business_details/", data)
    .then((res) => {
      dispatch({
        type: SAVE_BUSINESS_INFO_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        dispatch({
          type: SAVE_BUSINESS_INFO_FAILED,
          payload: parseError(err.response.data),
        });
      } else {
        const errMsg = err.response?.data
          ? parseError(err.response.data)
          : parseError(err);
        dispatch({
          type: SAVE_BUSINESS_INFO_FAILED,
          payload: errMsg,
        });
      }
    });
};

const getPutSignedUrl = (file, onURLSuccess, dispatch) => {
  const fileInfo = {
    name: file.name,
    size: file.size,
  };

  instance
    .post("document/upload", fileInfo)
    .then((res) => {
      onURLSuccess(res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPLOAD_BUSINESS_DOC_FAILED,
        payload: parseError(err),
      });
    });
};

export const uploadBusinessDocument = (file, onSuccess) => (dispatch) => {
  dispatch({
    type: UPLOAD_BUSINESS_DOC,
    payload: file,
  });

  getPutSignedUrl(
    file,
    (res) => {
      try {
        const formData = new FormData();
        formData.append("business_document", file);
        axios
          .put(res.data, file, {
            headers: {
              "Content-Type": file.type.toString(),
            },
            onUploadProgress: (progressEvent) => {
              dispatch({
                type: UPDATE_DOCUMENT_UPLOAD_PROGRESS,
                payload: progressEvent.progress.toFixed(1),
              });
            },
          })
          .then((res) => {
            instance
              .patch("business_details/", {
                document_name: file.name,
                document_size: file.size,
              })
              .then((res) => {
                dispatch({
                  type: UPLOAD_BUSINESS_DOC_SUCCESS,
                  payload: file,
                });
              });
            if (onSuccess) onSuccess();
          })
          .catch((err) => {
            console.log(err);

            dispatch({
              type: UPLOAD_BUSINESS_DOC_FAILED,
              payload: parseError(err),
            });
          });
      } catch (error) {
        console.log(error);
        dispatch({
          type: UPLOAD_BUSINESS_DOC_FAILED,
          payload: parseError(error),
        });
      }
    },
    dispatch,
  );
};

export const downloadBusinessDocument = (name, onSuccess) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_BUSINESS_DOC,
  });
  instance
    .get("document/download")
    .then((res) => {
      console.log(res);
      dispatch({
        type: DOWNLOAD_BUSINESS_DOC_SUCCESS,
      });
      const link = document.createElement("a");
      link.download = name;
      link.href = res.data;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: DOWNLOAD_BUSINESS_DOC_FAILED,
        payload: parseError(err),
      });
    });
};
