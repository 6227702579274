import {
  CHECK_TEMPLATE_INTRO,
  CLEAR_NEW_LABEL_TEMPLATE,
  CLEAR_SELECTED_LABEL_TEMPLATE,
  CLEAR_TEMPLATE_VALUE,
  CREATE_LABEL_TEMPLATE,
  DELETE_TEMPLATE,
  EDIT_LABEL_TEMPLATE,
  SELECT_LABEL_TEMPLATE,
  UPDATE_LABEL_TEMPLATE_CERTIFIED,
  UPDATE_LABEL_TEMPLATE_CONTENT,
  UPDATE_LABEL_TEMPLATE_DECLATRATION,
  UPDATE_LABEL_TEMPLATE_EXTRAS,
  UPDATE_LABEL_TEMPLATE_INCOTERM,
  UPDATE_LABEL_TEMPLATE_ITEMS,
  UPDATE_LABEL_TEMPLATE_NAME,
  UPDATE_LABEL_TEMPLATE_NON_DELIVERY,
  UPDATE_LABEL_TEMPLATE_PACKAGE_TYPE,
  UPDATE_LABEL_TEMPLATE_PARCEL,
  UPDATE_LABEL_TEMPLATE_SIGNER,
  UPDATE_LABEL_TEMPLATE_WEIGHT,
  UPDATE_NEW_LABEL_TEMPLATE,
  SAVE_EDITABLE_LABEL_TEMPLATE,
  LOGOUT_SUCCESS,
  GET_ACCOUNT_INFO_SUCCESS,
  SESSION_AUTH_LOGOUT,
} from "../../constants/types";

const INITIAL_TEMPLATE = {
  loading: false,
  errors: "",
  templates: [],
  selectedTemplate: null,
  editableTemplate: null,
  selectType: "label",
  intro: true,
};
export function templateReducer(state = INITIAL_TEMPLATE, action) {
  const list = [...state.templates];
  let selected = { ...state.selectedTemplate };
  switch (action.type) {
    case CREATE_LABEL_TEMPLATE:
      const templates = [...state.templates];
      const template = { ...action.payload };
      template.id = templates.length;
      templates.push(template);
      return { ...state, templates: templates };
    case SELECT_LABEL_TEMPLATE:
      return { ...state, selectedTemplate: action.payload };
    case EDIT_LABEL_TEMPLATE:
      if (!action.payload) {
        return { ...state, editTemplate: action.payload, selectType: "create" };
      }
      return { ...state, editableTemplate: action.payload, selectType: "edit" };
    case DELETE_TEMPLATE:
      if (selected && action.payload === selected.id) {
        selected = null;
      }
      const idx = list.findIndex((item) => item.id === action.payload);
      if (idx > -1) {
        list.splice(idx, 1);
      }
      return { ...state, templates: list, selectedTemplate: selected };
    case CHECK_TEMPLATE_INTRO:
      return { ...state, intro: true };
    case CLEAR_SELECTED_LABEL_TEMPLATE:
      return { ...state, selectedTemplate: null };
    case SAVE_EDITABLE_LABEL_TEMPLATE:
      const newTemplate = {
        id: state.editableTemplate.id,
        ...action.payload,
      };
      let editIdx = state.templates.findIndex(
        (item) => item.id === state.editableTemplate.id,
      );
      list[editIdx] = newTemplate;
      return { ...state, templates: list };
    case GET_ACCOUNT_INFO_SUCCESS:
      if (
        Object.keys(action.payload)?.length > 0 &&
        (!action.payload.onboarded_at || action.payload.onboarded_at === "")
      ) {
        return { ...state, intro: false };
      } else return { ...state, intro: true };
    case LOGOUT_SUCCESS:
      return INITIAL_TEMPLATE;
    default:
      return state;
  }
}

const INITAL_NEW_TEMPLATE = {
  name: "",
};

export function newTemplateReducer(state = INITAL_NEW_TEMPLATE, action) {
  const value = { ...state };
  switch (action.type) {
    case UPDATE_NEW_LABEL_TEMPLATE:
      if (action.payload === CLEAR_TEMPLATE_VALUE) return INITAL_NEW_TEMPLATE;
      return { ...state, ...action.payload };
    case UPDATE_LABEL_TEMPLATE_NAME:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return { ...state, name: INITAL_NEW_TEMPLATE.name };
      return { ...state, name: action.payload };
    case UPDATE_LABEL_TEMPLATE_PACKAGE_TYPE:
      if (action.payload === CLEAR_TEMPLATE_VALUE) {
        delete value.useTemplate;
        delete value.packageType;
        return { ...value };
      }
      return {
        ...state,
        useTemplate: action.payload.useTemplate,
        packageType: action.payload.packageType,
      };
    case UPDATE_LABEL_TEMPLATE_PARCEL:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return { ...state, selectedParcel: INITAL_NEW_TEMPLATE.selectedParcel };
      return { ...state, selectedParcel: action.payload };
    case UPDATE_LABEL_TEMPLATE_WEIGHT:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return { ...state, selectedWeight: INITAL_NEW_TEMPLATE.selectedWeight };
      return { ...state, selectedWeight: action.payload };
    case UPDATE_LABEL_TEMPLATE_EXTRAS:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return { ...state, selectedExtras: INITAL_NEW_TEMPLATE.selectedExtras };
      return { ...state, selectedExtras: action.payload };
    case UPDATE_LABEL_TEMPLATE_CERTIFIED:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            certified: INITAL_NEW_TEMPLATE.declaration.certified,
          },
        };
      return {
        ...state,
        declaration: { ...state.declaration, certified: action.payload },
      };
    case UPDATE_LABEL_TEMPLATE_CONTENT:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            contentType: INITAL_NEW_TEMPLATE.contentType,
            contentsExplanation: INITAL_NEW_TEMPLATE.contentsExplanation,
          },
        };
      return {
        ...state,
        declaration: {
          ...state.declaration,
          contentType: action.payload.contentType,
          contentsExplanation: action.payload.contentsExplanation,
        },
      };
    case UPDATE_LABEL_TEMPLATE_SIGNER:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            customsSigner: INITAL_NEW_TEMPLATE.customsSigner,
          },
        };
      return {
        ...state,
        declaration: { ...state.declaration, customsSigner: action.payload },
      };
    case UPDATE_LABEL_TEMPLATE_NON_DELIVERY:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            nonDeliveryOption: INITAL_NEW_TEMPLATE.nonDeliveryOption,
          },
        };
      return {
        ...state,
        declaration: {
          ...state.declaration,
          nonDeliveryOption: action.payload,
        },
      };
    case UPDATE_LABEL_TEMPLATE_ITEMS:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            items: INITAL_NEW_TEMPLATE.items,
          },
        };
      return {
        ...state,
        declaration: { ...state.declaration, items: action.payload },
      };
    case UPDATE_LABEL_TEMPLATE_INCOTERM:
      if (action.payload === CLEAR_TEMPLATE_VALUE)
        return {
          ...state,
          declaration: {
            ...state.declaration,
            incoterm: INITAL_NEW_TEMPLATE.incoterm,
          },
        };
      return {
        ...state,
        declaration: { ...state.declaration, incoterm: action.payload },
      };
    case UPDATE_LABEL_TEMPLATE_DECLATRATION:
      return { ...state, declaration: action.payload };
    case CREATE_LABEL_TEMPLATE:
    case CLEAR_NEW_LABEL_TEMPLATE:
    case CLEAR_SELECTED_LABEL_TEMPLATE:
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITAL_NEW_TEMPLATE;
    default:
      return state;
  }
}
