import React from "react";

import COUNTRYLIST from "../../../assets/country-list.json";
import UNITS from "../../../assets/mass-units.json";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { ReactComponent as Description } from "../../../assets/customs-item-description.svg";
import { ReactComponent as Value } from "../../../assets/customs-item-value.svg";
import { ReactComponent as Weight } from "../../../assets/customs-item-weight.svg";
import CURRENCY_UNITS from "../../../assets/currency-units.json";

const CustomsItemFormItem = (props) => {
  const CURRENCY = CURRENCY_UNITS;

  const { item, itemIdx } = props;

  return (
    <div className={"customs-list-item"}>
      <div
        className={"d-flex flex-row justify-content-between align-items-center"}
      >
        <h4>{`Items ${itemIdx + 1}`}</h4>
        <div className={"customs-item-actions"}>
          <FiEdit2 onClick={() => props.editItem()} />
          <FiTrash onClick={() => props.removeItem()} />
        </div>
      </div>
      <div className={"d-flex flex-row justify-content-between col-12 p-0"}>
        {item.image && (
          <img
            className={"item-image"}
            src={item.image}
            alt={item.description}
          />
        )}
        <div className={"item-details-container d-flex flex-column "}>
          <div
            className={
              "item-details d-flex flex-column flex-lg-row justify-content-start align-items-start align-items-lg-center"
            }
          >
            <div
              className={"item-description d-flex flex-row align-items-center "}
            >
              <Description />
              <p
                className={"ml-2 mb-0"}
              >{`${item.quantity}x ${item.description}`}</p>
            </div>
            <div
              className={"item-description d-flex flex-row align-items-center "}
            >
              <Weight />
              <p
                className={"ml-2 mb-0"}
              >{`${item.net_weight} ${UNITS[item.weight_unit]}`}</p>
            </div>
            <div
              className={"item-description d-flex flex-row align-items-center "}
            >
              <Value />
              <p
                className={"ml-2 mb-0"}
              >{`${item.value_currency ? CURRENCY[item.value_currency].symbol : ""}${item.value_amount}`}</p>
            </div>
          </div>
          <p className={"mb-0"}>
            {item.origin_country
              ? `Origin: ${COUNTRYLIST[item.origin_country]?.name}`
              : ""}
          </p>
          <p className={"mb-0"}>
            {item.tariff_number ? `Tariff No: ${item.tariff_number}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomsItemFormItem;
