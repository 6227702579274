import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import ReduxThunk from "redux-thunk";
import { PersistGate } from "redux-persist/integration/react";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import reducers from "./components/reducers";
import { analytics } from "./services/firebase";
import "./scss/page.scss";
import "./scss/_global.scss";
import "./scss/landing-page.scss";
import "./scss/more-integrations.scss";
import RouterComponent from "./Router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CookiesProvider, withCookies } from "react-cookie";
import {
  Provider as RollBarProvider,
  LEVEL_ERROR,
  ErrorBoundary,
} from "@rollbar/react";
import Rollbar from "rollbar";

const persistConfig = {
  key: "root",
  stateReconciler: autoMergeLevel2,
  storage: storage,
  blacklist: [
    "labels",
    "rateChoices",
    "contactForm",
    "mailingList",
    "accountForm",
    "csvImport",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(ReduxThunk),
);
const persistor = persistStore(store);
const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class App extends Component {
  state = {
    is_test: false,
  };

  componentDidMount() {
    // analytics.setAnalyticsCollectionEnabled(true);
    analytics().setAnalyticsCollectionEnabled(true);
  }

  render() {
    return (
      <RollBarProvider
        config={{
          accessToken: process.env.REACT_APP_ROLLBAR_KEY,
          payload: {
            environment: `web-${process.env.NODE_ENV.toString()}`,
          },
        }}
      >
        <ErrorBoundary
          fallbackUI={(props) => {
            return (
              <div>
                <p>Something went wrong</p>
              </div>
            );
          }}
          extra={(error, errorInfo) => {
            return {
              states: store.getState(),
            };
          }}
          level={LEVEL_ERROR}
        >
          <CookiesProvider>
            <Elements stripe={stripe}>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <RouterComponent />
                </PersistGate>
              </Provider>
            </Elements>
          </CookiesProvider>
        </ErrorBoundary>
      </RollBarProvider>
    );
  }
}

export default withCookies(App);
