import {
  CLEAR_SEARCH_LABEL,
  GET_ALL_LABEL_SUCCESS,
  GET_LABEL,
  GET_LABEL_SUCCESS,
  GET_LABEL_ERROR,
  LOGOUT_SUCCESS,
  SEARCH_LABEL_TEXT_ENTERED,
  GET_GRAPH_DATA_SUCCESS,
  GET_GRAPH_DATA_FAILED,
  GET_GRAPH_DATA,
  SET_LABELS_PAGE_SIZE,
} from "../../constants/types";
import { act } from "react-dom/test-utils";

const INITIAL_RATES_STATE = {
  loading: false,
  gLoading: false,
  errors: null,
  currentLabel: {},
  labelsList: [],
  labelResponse: {},
  graphLabels: {},
  searchText: "",
  currentPage: 1,
  pageSize: 10,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_RATES_STATE, action) => {
  switch (action.type) {
    case GET_LABEL:
      return { ...state, loading: true, errors: null };
    case GET_LABEL_SUCCESS:
      return {
        ...state,
        currentLabel: action.payload,
        loading: false,
        errors: null,
      };
    case GET_LABEL_ERROR:
      return { ...state, loading: false, errors: action.payload.errors };
    case GET_ALL_LABEL_SUCCESS:
      return {
        ...state,
        labelResponse: action.payload.response,
        labelsList: action.payload.list,
        currentPage: action.payload.currentPage,
        errors: null,
        loading: false,
      };
    case SEARCH_LABEL_TEXT_ENTERED:
      if (action.payload.trim() === "") {
        return { ...state, searchText: "" };
      }
      return { ...state, searchText: action.payload };
    case CLEAR_SEARCH_LABEL:
      return { ...state, searchText: "" };
    case GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphLabels: { ...action.payload },
        gLoading: false,
        errors: null,
      };
    case GET_GRAPH_DATA_FAILED:
      return { ...state, errors: action.payload.errors, gLoading: false };
    case GET_GRAPH_DATA:
      return { ...state, gLoading: true, errors: null };
    case SET_LABELS_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case LOGOUT_SUCCESS:
      return INITIAL_RATES_STATE;
    default:
      return state;
  }
};
