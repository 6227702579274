import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiInfo } from "react-icons/fi";
import React from "react";

const ToolTip = ({ key, message, placement, className }) => {
  return (
    <OverlayTrigger
      key={key}
      placement={placement}
      overlay={
        <Tooltip id={key} className={key}>
          {message}
        </Tooltip>
      }
      delayShow={300}
      delayHide={150}
    >
      <span className={`tooltip-icon ${className}`}>
        <FiInfo className={"warning"} />
      </span>
    </OverlayTrigger>
  );
};

export default ToolTip;
