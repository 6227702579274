import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Link,
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { FiChevronLeft, FiInfo } from "react-icons/fi";
import { ReactComponent as BoxLogo } from "../../assets/box-in-logo.svg";
import AccountInfoForm from "./UserDetails/AccountInfoForm";
import BankAccountForm from "./UserDetails/BankAccountForm";
import BusinessRegistrationForm from "./UserDetails/BusinessRegistrationForm";
import TaxInfoForm from "./UserDetails/TaxInfoForm";
import "../../scss/account-forms.scss";
import AccountFormHeader from "./UserDetails/AccountFormHeaders";
import { ReactComponent as BusinessRegistrationPromptIcon } from "../../assets/business-registration-prompt.svg";
import { ReactComponent as BusinessRegistrationCompleteIcon } from "../../assets/business-registration-complete.svg";
import history from "../../History";
import {
  clearApplicationContext,
  getUserCountry,
} from "../actions/ApplicationContextActions";
import { missingDetails } from "./common/helpers";

const AccountForms = (props) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [completeForms, setCompleteForms] = useState(false);

  const { pathname } = useLocation();
  const match = (path) => matchPath(pathname, { path });

  useEffect(() => {
    setShowPrompt(true);
    props.getUserCountry();
  }, []);

  const TaxInfoFormWithProps = useCallback(
    () => <TaxInfoForm complete={() => setCompleteForms(true)} />,
    [],
  );

  return (
    <>
      <AccountFormHeader />
      <div
        className={"account-form col-12 p-0 "}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
        }}
      >
        <div
          className={
            "col-12 d-none d-lg-flex flex-column justify-content-between"
          }
        >
          <Link
            className="header d-flex flex-row align-items-center"
            to={ROUTES.LANDING}
          >
            <div>
              <BoxLogo />
            </div>
            <h2 className="mb-0">ShipShap</h2>
          </Link>
          <Switch>
            <Route
              path={ROUTES.UPDATE_ACCOUNT_DETAILS}
              render={() => (
                <>
                  <div>
                    <h3>
                      Welcome to the Shipshap community! Let’s get your account
                      set up by completing your profile details.
                    </h3>
                    <p className={"info"}>
                      Follow the next three simple steps to fully register and
                      verify your business account, it’ll only take a minute.
                    </p>
                  </div>

                  {/*<div className={'why d-flex flex-row align-items-start'}>*/}
                  {/*    <FiInfo size={25} className={'mr-3'}/>*/}
                  {/*    <p>Why we need this Why we need this Why we need this Why we need this</p>*/}

                  {/*</div>*/}
                </>
              )}
            />
            <Route
              path={ROUTES.BANK_ACCOUNT_DETAILS}
              render={() => (
                <>
                  <h3>
                    Sometimes we’ll need to send you money, which we can only
                    deposit into your verified bank account. Don’t worry, you
                    can use other accounts and payment methods for purchasing
                    shipping labels.
                  </h3>
                </>
              )}
            />
            <Route
              path={ROUTES.BUSINESS_REGISTRATION}
              render={() => (
                <>
                  <div>
                    <div>
                      <h2>Document must contain</h2>
                      <ul>
                        <li>Business Name</li>
                        <li>Director(s) Names</li>
                        <li>Business Address</li>
                      </ul>
                    </div>
                    <div>
                      <h2>File requirements</h2>
                      <ul>
                        <li>File size max 40MB</li>
                        <li>PDF or PNG, JPG</li>
                        <li>Contain your name</li>
                      </ul>
                    </div>
                  </div>

                  <div className={"why d-flex flex-row align-items-start"}>
                    <FiInfo
                      className={
                        "d-flex justify-content-start align-items-start "
                      }
                    />
                    <p>
                      You business details allow us to verify that you are a
                      legitimate business. All details and documents are only
                      used for verification and settlement purposes only.
                    </p>
                  </div>
                </>
              )}
            />
            <Route
              path={ROUTES.TAX_INFO}
              render={() => (
                <>
                  <h3>
                    Last step. Enter your tax number and we’ll be able to finish
                    verifying your business.
                  </h3>
                </>
              )}
            />
          </Switch>
        </div>
        <div className={"forms-content"}>
          {((!match(ROUTES.UPDATE_ACCOUNT_DETAILS) &&
            !match(ROUTES.BANK_ACCOUNT_DETAILS)) ||
            props.context.actionType === "edit") && (
            <a onClick={() => history.goBack()} className={"back-button"}>
              <FiChevronLeft />
              Back
            </a>
          )}
          <div
            className={
              "col-12 col-lg-7 m-auto d-flex flex-column align-items-center text-center justify-content-center"
            }
          >
            <Switch>
              <Route
                path={ROUTES.UPDATE_ACCOUNT_DETAILS}
                component={AccountInfoForm}
              />
              <Route
                path={ROUTES.BANK_ACCOUNT_DETAILS}
                component={BankAccountForm}
              />
              <Route
                path={ROUTES.BUSINESS_REGISTRATION}
                component={BusinessRegistrationForm}
              />
              <Route path={ROUTES.TAX_INFO} component={TaxInfoFormWithProps} />
              <Route render={() => <Redirect to={ROUTES.HOME} />} />
            </Switch>
          </div>
        </div>
        {props.context.actionType !== "edit" &&
          match(ROUTES.BANK_ACCOUNT_DETAILS) &&
          showPrompt && (
            <div className={"next-step-prompt text-center"}>
              <div className={"prompt-content col-12 col-lg-8"}>
                <BusinessRegistrationPromptIcon />
                <div>
                  <h3>
                    Thank you. Your account is now active!
                    <br />
                    Next step: Complete your profile by verify your business
                    this will unlock the entire Shipshap experience.
                  </h3>
                  <p>
                    It’ll only take a few minutes to input your registered
                    business information, we need this to verify you’re a legal
                    business. You will need to have your business information
                    handy. If you don't have this information on you now you can
                    always upload it later. <br />
                    You will be able to send up to one package prior to verify
                    your business.
                  </p>
                </div>
                <div>
                  <button onClick={() => setShowPrompt(false)}>
                    Continue to Business Verification
                  </button>
                  <button
                    onClick={() => {
                      history.replace(ROUTES.HOME);
                      props.clearApplicationContext();
                    }}
                  >
                    Skip, I'll do this later
                  </button>
                </div>
              </div>
            </div>
          )}
        {props.context.actionType !== "edit" &&
          !missingDetails(props.details) &&
          completeForms && (
            <div className={"business-prompt-complete text-center"}>
              <div className={"prompt-content col-12 col-lg-8"}>
                <BusinessRegistrationCompleteIcon />
                <div>
                  <h3>Thank you for submitting your documents</h3>
                  <p>
                    Please allow 24-48 hours for us to process your information.
                    <br />
                    If you have any questions{" "}
                    <Link to={ROUTES.CONTACT_US}>Contact Us.</Link>
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => {
                      history.replace(-2);
                      history.replace(props.context.returnURL);
                      props.clearApplicationContext();
                    }}
                  >
                    Go to Dashboard
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

const mapStateToProps = ({ applicationContext, businessDetails }) => ({
  context: applicationContext.context,
  details: businessDetails.details,
});

export default connect(mapStateToProps, {
  clearApplicationContext,
  getUserCountry,
})(AccountForms);
