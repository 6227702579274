import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useCookies } from "react-cookie";
import {
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiMoreVertical,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import TRACKING_STATUS from "../../../assets/tracking-status.json";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";
import UNITS from "../../../assets/mass-units.json";
import getCountryFlag from "country-flag-icons/unicode";
import { tableDateFormatter } from "../common/helpers";
import Popup from "../common/Popup";
import { auth } from "../../../services/firebase";
import { getWebToken } from "../../actions/ActionHelpers";

const LabelList = (props) => {
  const [cookies] = useCookies();
  const [actionItem, setActionItem] = useState(null);
  const [actionElement, setActionElement] = useState(null);
  const [showAction, setShowAction] = useState(false);
  const [webToken, setWebToken] = useState(null);

  const labelsContainer = useRef(null);

  // TODO: Switch this to env variable domain
  const currentUrl = window.location.hostname;
  const newDashboardURL = `https://dashboard.${currentUrl}`;

  useEffect(async () => {
    const token = await getWebToken();
    setWebToken(token);
  }, []);

  const getLabelError = (row) => {
    if (!row || !row.errors || row.errors?.length === 0) {
      return "Please contact support for more information";
    }

    return row.errors[0].description;
  };

  const toggleActionMenu = (item, element) => {
    setActionItem((prevItem) => {
      if (prevItem && prevItem.id === item.id) {
        setActionElement(null);
        setShowAction(false);
        return null;
      }

      setActionElement(element);
      setShowAction(true);
      return item;
    });
  };

  const trackingFormatter = (cell, row) => {
    const key = "tracking-column-value";
    let status;
    if (!cell?.length) {
      return <div></div>;
    }
    const label = cell[cell.length - 1];
    const eventType = label?.tracking_status?.event_type;
    const eventTypes = TRACKING_STATUS;
    status = eventTypes[eventType] || "Pre Transit";

    return !label ? (
      <div key={key}></div>
    ) : (
      <div key={key}>
        {label.tracking_url_provider && label.tracking_url_provider !== "" ? (
          <div>
            <p className={"mb-0"}>{status.toString().toUpperCase()}</p>
            <br className={"d-block d-xl-none"} />
            <a
              className="tracking-number"
              onClick={(e) => e.stopPropagation()}
              href={label.tracking_url_provider}
              target="_blank"
            >
              {label.tracking_number}
            </a>
          </div>
        ) : (
          <div>
            <p className={"mb-0"}>{status.toString().toUpperCase()}</p>
            <br className={"d-block d-xl-none"} />
            <div
              className="tracking-number"
              onClick={(e) => e.stopPropagation()}
            >
              {label.tracking_number}
            </div>
          </div>
        )}
      </div>
    );
  };

  const fromFormatter = (cell, row) => {
    const key = "from-column-value";
    const { street1, street2, city, state, country } = row.from;
    let address = "";
    if (street1) {
      address += street1 + ",\n";
    }
    if (street2) {
      address += street2 + ",\n";
    }
    return <p key={key}>{`${address}${city}, ${state}, ${country}`}</p>;
  };

  const toFormatter = (cell, row) => {
    const key = "to-column-value";
    const { name, street1, street2, city, state, country } = row.to;
    let address = "";
    if (street1) {
      address += street1 + ",\n";
    }
    if (street2) {
      address += street2 + ",\n";
    }
    return (
      <div className={""} key={key}>
        <p
          className={"customer-name"}
        >{`${country ? getCountryFlag(country) : ""} ${name}`}</p>
        <br className={"d-block d-xl-none"} />
        <p
          className={"customer-address"}
        >{`${address} ${city}, ${state}, ${country}`}</p>
      </div>
    );
  };

  const downloadFormatter = (labels, row) => {
    const key = "downloads-column-value";
    if (
      labels?.length > 0 &&
      labels.some((item) => item.label_url && item.label_url !== "")
    ) {
      return (
        <a
          key={key}
          rel="noreferrer"
          className={`${labels.length > 1 ? "download-button" : "download-icon"}`}
          href={
            labels?.length === 1
              ? labels[0]?.label_url
              : `${newDashboardURL}/api/${row.id}/download-labels?webToken=${webToken}`
          }
          download
          target={"_blank"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {labels.length > 1 ? "Download all" : "Download Label"}
        </a>
      );
    }

    if (row.state && row.state.toString().toLowerCase() === "created") {
      return (
        <OverlayTrigger
          key={"label-list-waiting"}
          placement="left"
          overlay={
            <Tooltip
              id="label-list-waiting-tooltip"
              className={"business-details-missing-tooltip"}
            >
              {getLabelError(row)}
            </Tooltip>
          }
          delayShow={300}
          delayHide={150}
        >
          <p>Awaiting Payment</p>
        </OverlayTrigger>
      );
    }

    if (
      labels.every((item) => !item.label_url || item.label_url === "") &&
      ((row.state && row.state.toString().toLowerCase() === "complete") ||
        row.errors.length > 0)
    ) {
      return (
        <OverlayTrigger
          key={"label-list-error"}
          placement="left"
          overlay={
            <Tooltip
              id="label-list-error-tooltip"
              className={"business-details-missing-tooltip"}
            >
              {getLabelError(row)}
            </Tooltip>
          }
          delayShow={300}
          delayHide={150}
        >
          <p className={"error"}>Something went wrong</p>
        </OverlayTrigger>
      );
    }

    return (
      <OverlayTrigger
        key={"label-list-waiting"}
        placement="left"
        overlay={
          <Tooltip
            id="label-list-waiting-tooltip"
            className={"business-details-missing-tooltip"}
          >
            {getLabelError(row)}
          </Tooltip>
        }
        delayShow={300}
        delayHide={150}
      >
        <p>Awaiting Label Creation</p>
      </OverlayTrigger>
    );
  };

  const dateFilter = (cell, row) => {
    return !cell ? null : `${moment(new Date(cell)).format("MMM D, YYYY")}`;
  };

  const toFilter = (cell, row) => {
    return !cell ? null : `${row.to.street1}, ${row.to.city}`;
  };

  const fromFilter = (cell, row) => {
    return !cell
      ? null
      : `${row.from.street1}, ${row.shipment.address_from.city}, ${row.from.state}`;
  };

  const trackingFilter = (cell, row) => {
    return !!cell.length
      ? `${cell[0]?.tracking_status} ${cell[0]?.tracking_number}`
      : null;
  };

  const actionsFormatter = (cell, row) => {
    const key = "actions-column-value-" + row.id;
    const labels = row.labels;

    const hasLabels =
      labels?.length > 0 &&
      labels.some((item) => item.label_url && item.label_url !== "");
    let showActions = hasLabels || !!cell;

    return showActions ? (
      <div
        key={key}
        className={"table-actions d-flex flex-row align-items-center"}
      >
        <FiMoreVertical
          size={35}
          onClick={(e) => {
            toggleActionMenu(row, e.target);
            e.stopPropagation();
          }}
          className={"d-none d-xl-inline"}
        />
      </div>
    ) : (
      <div key={key}></div>
    );
  };

  const expandedRow = {
    renderer: (row) => {
      const labels = row.labels;

      if (labels?.length > 0) {
        const list = row.labels.map((label, idx) => {
          const {
            length,
            width,
            height,
            weight,
            weight_unit,
            length_unit,
            template,
          } = label.parcel;
          const label_url = !!label.label_url ? label.label_url : null;

          return (
            <div
              className={
                "expanded-row-details-item d-flex flex-row justify-content-between align-items-center"
              }
              key={label.id}
            >
              <div
                className={
                  "flex-row justify-content-between align-items-center"
                }
              >
                <p>
                  {template ? localParcels[template]?.name : "Custom Package"}
                </p>
                {length && width && height && (
                  <p>{`L ${length}x W ${width}x H ${height} ${length_unit}`}</p>
                )}
                <p>{`${weight} ${UNITS[weight_unit]}`}</p>
              </div>
              {label_url ? (
                <a
                  className={"download-icon"}
                  href={label_url}
                  target={"_blank"}
                  download
                >
                  <FiDownload size={25} />
                </a>
              ) : (
                <a className={"download-icon"}></a>
              )}
            </div>
          );
        });
        return <div className={"expanded-row-details"}>{list.reverse()}</div>;
      } else return <></>;
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandColumnPosition: "left",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <FiChevronUp />;
      }
      return <FiChevronDown />;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <a>
            <FiChevronUp />
          </a>
        ); // Custom expand column when the row is expanded
      }
      return (
        <a>
          <FiChevronDown />
        </a>
      ); // Custom expand column when the row is not expanded
    },
    className: "table-expanded-row",
    parentClassName: "table-expanded-row-container",
  };

  const listData = props.list.map((item) => ({
    id: item.id,
    tracking: item.labels,
    receiver: item.shipment?.address_to?.name,
    created_at: item.created_at,
    from: item.shipment.address_from,
    to: item.shipment.address_to,
    metadata: item.metadata,
    labels: item.labels,
    invoice: item.commercial_invoice_url,
    errors: item.errors,
    state: item.state,
  }));

  const columns = [
    // { dataField: "rate.service.provider", text: "Carriers" },
    {
      dataField: "tracking",
      text: "Tracking Status",
      formatter: trackingFormatter,
      filterValue: trackingFilter,
    },
    // {dataField: "rate.amount", text: "RATE", formatter: rateFormatter},
    { dataField: "receiver", text: "Recipient" },
    {
      dataField: "created_at",
      text: "Created",
      formatter: tableDateFormatter,
      filterValue: dateFilter,
    },
    {
      dataField: "from",
      text: "From",
      formatter: fromFormatter,
      filterValue: fromFilter,
    },
    {
      dataField: "to",
      text: "To",
      formatter: toFormatter,
      filterValue: toFilter,
    },
    { dataField: "metadata", text: "Notes" },
    { dataField: "labels", text: "Label", formatter: downloadFormatter },
    { dataField: "invoice", text: "Actions", formatter: actionsFormatter },
  ];

  const checkLabels = () => {
    if (!actionItem) return false;
    const labels = actionItem.labels;
    const hasLabels =
      labels?.length > 0 &&
      labels.some((item) => item.label_url && item.label_url !== "");
    return hasLabels;
  };

  const downloadLabel = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "");
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className={"card-section last"}>
      {props.errors && (
        <div className={"error-block"}>
          <p>{props.errors}</p>
        </div>
      )}
      {props.loading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <ToolkitProvider keyField="id" data={listData} columns={columns} search>
          {(toolkitProps) => {
            if (listData.length > 0) {
              return (
                <div
                  className={`labels-table d-flex flex-column m-0 p-0 ${cookies.is_test ? "test-mode" : "live-mode"}`}
                >
                  <BootstrapTable
                    keyField={"id"}
                    {...toolkitProps.baseProps}
                    expandRow={expandedRow}
                    classes={"d-none d-xl-table"}
                  />
                  <div className={"table-list d-flex d-xl-none flex-column"}>
                    {listData.map((item) => {
                      const lists = columns.map((col) => {
                        return [
                          <p
                            className={"field-name"}
                            key={`${col.dataField}-column-title`}
                          >
                            {col.text}
                          </p>,
                          col.formatter ? (
                            col.formatter(item[col.dataField], item)
                          ) : (
                            <p key={`${col.dataField}-column-value`}>
                              {item[col.dataField]}
                            </p>
                          ),
                        ];
                      });
                      return (
                        <div className={"table-item"} key={item.id}>
                          <div className={"item-fields col-12 "}>{lists}</div>
                          {/*{expandedRow.renderer(item)}*/}
                        </div>
                      );
                    })}
                  </div>
                  {!props.recent && props.pagination}
                  {props.recent ? (
                    <div className="more-button d-flex align-items-center justify-content-center m-auto">
                      <Link to={ROUTES.HOME + ROUTES.LABEL}>View All</Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            } else {
              return props.searchText && props.searchText !== "" ? (
                <div>No matching label found for search</div>
              ) : (
                <div>
                  No labels have been created yet. Create you first ShipShap
                  label!{" "}
                </div>
              );
            }
          }}
        </ToolkitProvider>
      )}
      <Popup
        show={showAction}
        element={actionElement}
        close={() => {
          setShowAction(false);
          setActionElement(null);
          setActionItem(null);
        }}
        top={(actionElement?.getBoundingClientRect().top || 0) + 30 + "px"}
        right={"135px"}
      >
        {checkLabels() ? (
          <a
            href={
              actionItem?.labels?.length === 1
                ? actionItem?.labels[0]?.label_url
                : `${newDashboardURL}/api/${actionItem?.id}/download-labels?webToken=${webToken}`
            }
            download
            target={"_blank"}
          >
            <Popup.Item
              className={
                "table-actions-item d-flex flex-row align-items-center justify-content-start"
              }
            >
              <FiDownload />
              <p>
                {actionItem?.labels.length > 1
                  ? "Download all labels"
                  : "Download label"}
              </p>
            </Popup.Item>
          </a>
        ) : null}
        {!!actionItem?.invoice && actionItem.invoice !== "" ? (
          <a
            href={actionItem.invoice}
            target={"_blank"}
            download
            onClick={() => {
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            }}
          >
            <Popup.Item
              className={
                "table-actions-item d-flex flex-row align-items-center justify-content-start"
              }
            >
              <FiDownload />
              <p>Download Invoice</p>
            </Popup.Item>
          </a>
        ) : null}
      </Popup>
    </div>
  );
};

export default LabelList;
